import { apiGet, apiPost } from "api/configApi";
import useBuscarPaciente from "hooks/useBuscarPaciente";
import { PacienteContext } from "providers/PacienteProvider";
import { useCallback, useContext, useEffect, useState } from "react";

export default function useGestionFamiliares(){
    const { paciente, user: userPaciente } = useContext(PacienteContext);
    const [familiarAgregado, setFamiliarAgregado] = useState(false);
    const [cargandoFamiliar, setCargandoFamiliar] = useState(false);
    const [errorAgregarFamiliar, setErrorAgregarFamiliar] = useState();
    const [errorBuscarGrupo, setErrorBuscarGrupo] = useState();
    const [errorBuscarGrupoIntegrado, setErrorBuscarGrupoIntegrado] = useState();
    const [cargandoGrupo, setCargandoGrupo] = useState(false);
    const [cargandoGrupoIntegrado, setCargandoGrupoIntegrado] = useState(false);
    const [gruposFamiliares, setGruposFamiliares] = useState([]);
    const [gruposIntegrados, setGruposIntegrados] = useState([]);
    const [reloadGrupo, setReloadGrupo] = useState(true);
    const [reloadGrupoIntegrado, setReloadGrupoIntegrado] = useState(true);

    const reset = () => {
        setFamiliarAgregado(false);
        setCargandoFamiliar(false);
        setFamiliarBuscado(null);
        setErrorAgregarFamiliar(false);
    }

    const {
        paciente: familiarBuscado,
        setPaciente: setFamiliarBuscado,
        handleOnSearch: handleOnSearchFamiliarBuscado,
        handleBuscando: handleBuscandoFamiliar
    } = useBuscarPaciente({
        onBuscando: reset
    });

    const handleCreatedResponse = response => {
        if(response.status === 201){
            setFamiliarAgregado(true);
            setCargandoFamiliar(false);
            setReloadGrupo(true);
            setErrorBuscarGrupo(false);
        }
    }

    const makeRequestAgregarFamiliar = () => {
        const data = {
            'dni_paciente': userPaciente.dni,
            'dni_familiar': familiarBuscado.dni
        };
        return apiPost('api/agregarFamiliar', data);
    }

    const handleError = error => {
        setErrorAgregarFamiliar(error.response.data.error);
    }

    const agregarFamiliar = () => {
        setCargandoFamiliar(true);
        setErrorAgregarFamiliar(false);
        makeRequestAgregarFamiliar()
            .then(handleCreatedResponse)
            .catch(handleError);
    }

    const handleResponseGruposFamiliares = response => {
        setGruposFamiliares(response.data);
        setCargandoGrupo(false);
    }

    const handleResponseGruposIntegrados = response => {
        setGruposIntegrados(response.data);
        setCargandoGrupoIntegrado(false);
    }

    const handleErrorsGrupos = error => setErrorBuscarGrupo(error.response.data.error);
    const handleErrorsGruposIntegrados = error => setErrorBuscarGrupoIntegrado(error.response.data.error);

    const buscarGruposFamiliares = useCallback(() => {
        setCargandoGrupo(true);
        setReloadGrupo(false);
        apiGet(`api/familiaresPaciente/${userPaciente.id}`)
            .then(handleResponseGruposFamiliares)
            .catch(handleErrorsGrupos);
    }, [userPaciente]);

    const buscarGruposIntegrados = useCallback(() => {
        setCargandoGrupoIntegrado(true);
        setReloadGrupoIntegrado(false);
        apiGet(`api/gruposIntegrados/${userPaciente.id}`)
            .then(handleResponseGruposIntegrados)
            .catch(handleErrorsGruposIntegrados);
    }, [userPaciente]);

    useEffect(() => {
        reloadGrupo && buscarGruposFamiliares();
        reloadGrupoIntegrado && buscarGruposIntegrados();
    });

    return {
        paciente,
        agregarFamiliar,
        handleOnSearchFamiliarBuscado,
        handleBuscandoFamiliar,
        familiarAgregado,
        familiarBuscado,
        cargandoFamiliar,
        errorAgregarFamiliar,
        reset,
        errorBuscarGrupo,
        errorBuscarGrupoIntegrado,
        cargandoGrupo,
        cargandoGrupoIntegrado,
        gruposFamiliares,
        gruposIntegrados,
        setGruposFamiliares,
        setGruposIntegrados
    }

}
