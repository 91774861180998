import React from 'react';
import LayoutPrincipal from 'layouts/LayoutPrincipal';
import SectionWithTitle from 'components/SectionWithTitle';
import { Container } from 'react-bootstrap';

export default function PreguntasFrecuentesPage(){
    return (
        <LayoutPrincipal>
            <Container className="mt-4">
                <SectionWithTitle title={'Preguntas Frecuentes'}>
                    <div class="list-group">
                        <a class="list-group-item list-group-item-action">
                            <div class="d-flex w-100 justify-content-between ">
                                <h5 class="mb-1">¿Olvidó su contraseña?</h5>
                            </div>
                            <p class="mb-1">En la pantalla donde ingresa sus datos de usuario y contraseña podrá encontrar un link con el siguiente texto <a href='https://portal.sanatorioboratti.com.ar/olvideContrasena'>Olvidé mi contraseña</a>. Este link lo redireccionara al formulario correspondiente .</p>
                        </a>
                        <a class="list-group-item list-group-item-action">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">Tengo un codigo provisorio</h5>
                            </div>
                            <p class="mb-1">El Codigo Provisorio no es una contraseña para acceder a su cuenta. Este codigo sirve solamente para su registro previo al portal y el acceso a este es por medio del mensaje que recibio en su telefono.</p>
                        </a>
                        <a class="list-group-item list-group-item-action">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">No funciona el link de registro</h5>
                            </div>
                            <p class="mb-1">En caso de no poder acceder al link que recibiste en el mensaje, en ciertos casos es necesario agregar el numero del remitente en tu agenda de contactos.</p>
                        </a>
                    </div>
                </SectionWithTitle>
            </Container>
        </LayoutPrincipal>
    );
}
