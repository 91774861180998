import  { apiPost, getCsrfCookie, reduceErrorMessages } from "api/configApi";
import { useCallback, useState } from "react";


export default function useSubmit(
    {onReciveData = () => {}, onTerminate = () => {}, url, initialState = {}, requiereCsrf = false}
){
    const [state, setState] = useState(initialState);
    const [procesando, setProcesando] = useState(false);
    const [failed, setFailed] = useState(false);
    const [errors, setErrors] = useState(null);

    const handleOnChange = useCallback(evt => {
        setErrors(null);
        setFailed(false);
        const input = evt.target;
        setState(state =>
            ({
                ...state,
                [input.name]: input.value
            })
        );
    }, [setErrors, setState]);


    const handleCsrfValidation = () => apiPost(url, state);

    const handleResponse = response =>  {
        onReciveData && onReciveData(response.data);
        setProcesando(false);
        onTerminate && onTerminate();
    }

    const handleErros = error => {
        setFailed(true);
        setProcesando(false);
        setErrors(reduceErrorMessages(error));
    }

    const sendRequest = () =>  {
        setProcesando(true);
        if(requiereCsrf){
            getCsrfCookie()
                .then(handleCsrfValidation)
                .then(handleResponse)
                .catch(handleErros);
        }
        else{
            apiPost(url, state)
                .then(handleResponse)
                .catch(handleErros);
        }
    }

    return {
        failed,
        procesando,
        errors,
        state,
        setErrors,
        controller: {
            handleOnChange,
            sendRequest
        }
    }
}
