import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useTraerServicioId(servicioId){
    const [servicio, setServicio] = useState(null);
    const [cargandoServicio, setCargandoServicio] = useState(true);
    const [errorServicio, setErrorServicio] = useState();
    const handleResponse = response =>{
        setServicio(response.data[0]);
        setCargandoServicio(false);
    }

    useEffect(() => {
        setCargandoServicio(true);
        apiGet(`api/turnos/traerServicioId/${servicioId}`)
            .then(handleResponse)
            .catch(error => {
                setErrorServicio("Error inesperado.");
                setCargandoServicio(false);
            })
    },[servicioId]);


    return {cargandoServicio, servicio, errorServicio};

}
