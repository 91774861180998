import React, { useContext, useEffect, useState } from 'react';
import LayoutPrincipal from 'layouts/LayoutPrincipal';
import Cargando from 'components/Cargando';
import { apiGet } from 'api/configApi';
import { UserContext } from 'providers/UserProvider';
import SeccionConAcceso from 'pages/estudios/SeccionConAcceso'
import SeccionBloqueada from 'pages/estudios/SeccionBloqueada'
import LayoutConMenuLateral from 'layouts/LayoutConMenuLateral';

export default function MisEstudiosPage(){
    const {user} = useContext(UserContext)
    const [validando, setValidando] = useState(true);
    const [hasAccess, setHasAccess] = useState(false);

    useEffect(() => {
        user && apiGet('api/validarAcceso').then(response => {
            setHasAccess(response.data.validado);
            setValidando(false);
        });
    }, [user]);

    const Layout = user.origen.id !== 1 ? LayoutPrincipal : LayoutConMenuLateral;

    if(validando){
        return (
            <Layout>
                <div className="bg-white m-4 p-2 shadow rounded">
                    <Cargando text="Cargando"/>
                </div>
            </Layout>
        );
    }

    if(hasAccess){
        return <SeccionConAcceso user={user} Layout={Layout}/>
    }
    return <SeccionBloqueada setHasAccess={setHasAccess}/>
}
