import { UserContext } from "providers/UserProvider";
import { useContext } from "react";
import useSubmit from "./useSubmit";

export default function useConfirmPassword(){
    const { user, addUser } = useContext(UserContext);

    const handleOnTerminate = () => {
        addUser({
            ...user,
            es_provisorio: "0"
        });
    };

    const {
        failed,
        procesando,
        errors,
        state,
        setErrors,
        controller
    } = useSubmit({
        url: 'api/confirmarPassword',
        onTerminate: handleOnTerminate,
    });

    return {
        failed,
        procesando,
        errors,
        state,
        setErrors,
        controller: controller
    }
}
