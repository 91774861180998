import React from 'react';
import { ReactComponent as IcoCancelar} from 'icons/cancelar.svg';
import MensajeConIcon from './MensajeConIcon';

const MensajeError = ({mensaje}) => (
    <MensajeConIcon
        mensaje={mensaje}
        Icon={IcoCancelar}
    />
);

export default MensajeError;

