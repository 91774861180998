import React, {useContext, useState, useEffect} from 'react';
import { Row, Col } from 'react-bootstrap';
import LayoutConMenuLateral from 'layouts/LayoutConMenuLateral';
import SectionWithTitle from 'components/SectionWithTitle';
import TemaContext from 'providers/Tema';
import useFormulariosCompletos from 'hooks/anestesistas/useFormulariosCompletos'
import IfRender from 'components/IfRender'
import { Accordion, Card } from 'react-bootstrap';
import { Titulo } from 'styledComponents/Titulo.style';
import useAnestesistas from 'hooks/anestesistas/useAnestesistas';
import Combo from 'components/Combo';
import TablaFormularios from 'components/anestesista/TablaFormularios';
import Cargando from 'components/Cargando';
import { Alert } from 'react-bootstrap';

export default function PanelAdministradores(){
    const { cargandoFormularios, formularios, errorFormulario } = useFormulariosCompletos('getAllAnestesistasForms');
    const [ formulariosParaMostrar, setFormulariosParaMostrar ] = useState(formularios);
    const {cargandoAnestesistas, anestesistas, errorAnestesistas} = useAnestesistas()
    const tema = useContext(TemaContext)
    const [anestesistaSeleccionado, setAnestesistaSeleccionado] = useState()

    useEffect(()=>{
        setFormulariosParaMostrar(formularios)
    },[formularios])

    const handleSelectedAnestesista = (e) => {
        const filtrados = formularios.filter(f => f.anestesista_dni === e.target.value);
        setFormulariosParaMostrar(filtrados);
        setAnestesistaSeleccionado(e.target.value);
    }

    return (
        <>
        <LayoutConMenuLateral>
            <div className="container mt-4 mb-4">
                <SectionWithTitle title="Consulta Preanestésica" tema={tema}>
                    <IfRender condicion={!errorFormulario && !errorAnestesistas}
                        no={() =>
                            <Alert variant="danger" className="m-2">
                                Se ha producido un error. Por favor vuelva a intentarlo mas tarde.
                            </Alert>
                        }>
                        <Row className="m-0">
                            <Col className="pr-0">
                                <Combo
                                    opcionId="dni"
                                    opciones={anestesistas}
                                    label="Anestesistas"
                                    opcionValue="nombre"
                                    defaultValue={anestesistaSeleccionado}
                                    cargando={cargandoAnestesistas}
                                    onChange={handleSelectedAnestesista}
                                    onTodos={() => setFormulariosParaMostrar(formularios)}
                                />
                            </Col>
                        </Row>
                        <Accordion className="m-2" defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    <Titulo tema={tema}>Formularios Preanestésico</Titulo>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <IfRender condicion={cargandoFormularios}
                                        no={ () => <TablaFormularios formularios={formulariosParaMostrar}/> }>
                                            <Cargando text="Cargando formularios" />
                                        </IfRender>

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </IfRender>
                </SectionWithTitle>
            </div>
        </LayoutConMenuLateral>
        </>
    );
}
