import React, { useContext } from 'react';
import Boton from 'styledComponents/Boton.style';
import { ThemeProvider } from 'styled-components';
import TemaContext from 'providers/Tema';
import FormGroup from 'components/FormGroup';
import SkeletonForm from 'components/SkeletonForm';
import useConfirmPassword from 'hooks/useConfirmPassword';

const ConfirmPasswordForm = () => {
    const tema = useContext(TemaContext);
    const { state, controller, errors, procesando } = useConfirmPassword();

    const onSubmit = evt => {
        evt.preventDefault();
        controller.sendRequest();
    }

    return (
        <SkeletonForm
            invalid={errors}
            validando={procesando}
            handleSubmit={onSubmit}
            invalidMessage={errors}
            validandoMessage="Cambiando contraseña"
            render={() => (
                <>
                <FormGroup
                    type="password"
                    name="password"
                    label="Contraseña"
                    value={state.confirm_password}
                    onChange={controller.handleOnChange}
                    required>
                </FormGroup>
                <FormGroup
                    type="password"
                    name="password_confirmation"
                    label="Repetir contraseña"
                    value={state.confirm_password}
                    onChange={controller.handleOnChange}
                    required>
                </FormGroup>
                <ThemeProvider theme={tema}>
                    <Boton className="btn w-100 mt-2" disabled={false} >
                        Confirmar nueva contraseña
                    </Boton>
                </ThemeProvider>
                </>
            )}
        />
    );
}

export default ConfirmPasswordForm;
