import { useEffect, useState } from "react";
import { apiGet } from "api/configApi";
import { useLocation } from "wouter";
import url from "utils/url";

export default function useFormularioDatosPersonales(pacienteDniCompletado){
    const [nombre, setNombre] = useState();
    const [paciente_dni, setDni] = useState();
    const [fechaNacimiento, setFechaNacimiento] = useState();
    const [edad, setEdad] = useState();
    const [fechaDeConsulta, setFechaDeConsulta] = useState();
    const [anestesista, setAnestesista] = useState();
    const [anestesista_dni, setAnestesistaDni] = useState();
    const [mensajeError, setMensajeError] = useState();
    const [turno_id, setTurnoId] = useState();
    const [telefono, setTelefono] = useState();
    const [, setLocation] = useLocation();

    const datosConsulta = {
        nombre,
        paciente_dni,
        fechaNacimiento,
        edad,
        fechaDeConsulta,
        anestesista,
        anestesista_dni,
        turno_id,
        telefono
    };

    const handlleError = (paciente_dni) => {
        setMensajeError(`No se ha encontrado un turno preanestésico para el dni ${paciente_dni}. Recuerde que para poder completar el formulario preanestésico previamente debe tener un turno.`)
    }

    useEffect(() => {
        pacienteDniCompletado && setDni(pacienteDniCompletado)
    }, [pacienteDniCompletado]);

    useEffect(() => {
        if(paciente_dni && paciente_dni.length > 6){
            apiGet(`api/formulario/anestesistas/pacientes/${paciente_dni}`)
            .then(response => {
                setNombre(`${response.data.nombre_paciente} ${response.data.apellido_paciente}`);
                setFechaNacimiento(response.data.fecha_nacimiento_paciente);
                setEdad(response.data.edad);
                setFechaDeConsulta(response.data.fecha_consulta);
                setAnestesista(`${response.data.nombre_anestesista} ${response.data.apellido_anestesista}`);
                setAnestesistaDni(response.data.dni_anestesista);
                setMensajeError(false);
                setTurnoId(response.data.id_turno);
                setTelefono(response.data.telefono);
                response.data.formulario_id && setLocation(url(`anestesistas/pacientes/${response.data.formulario_id}`));
            })
            .catch(() => {
                handlleError(paciente_dni)
            })
        }
    }, [paciente_dni, pacienteDniCompletado, setLocation]);

    const handleOnChangeDni = evt =>{
        setDni(evt.target.value);
    }

    const handleOnChangeTelefono = evt =>{
        setTelefono(evt.target.value);
    }

    return {
        datosConsulta,
        controller: {
            handleOnChangeDni,
            handleOnChangeTelefono
        },
        mensajeError
    };
}
