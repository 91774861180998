import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useServiciosPorCentro(centro){
    const [serviciosPorCentro, setServicios] = useState([]);
    const [cargandoServicios, setCargandoServicios] = useState();
    const [errorServicio, setErrorServicio] = useState();
    const handleResponse = response =>{
        setServicios(response.data);
        setCargandoServicios(false);
    }

    useEffect(() =>  {
        setCargandoServicios(true);

        apiGet(`api/turnos/ServiciosPorCentro/${centro}`)
            .then(handleResponse)
            .catch(error => {
                setErrorServicio("Error inesperado.");
                setCargandoServicios(false);
            })
    },[centro]);

    return {cargandoServicios, serviciosPorCentro, errorServicio};

}
