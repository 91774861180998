import React from 'react';
import 'css/responsiveTable.css';
import TableHeader from './TableHeader';
import IfRender from 'components/IfRender';
import TableEmpty from './TableEmpty';

const ResponsiveTable = ({
    headers,
    rows,
    emptyText = "No se encontraron registros",
    renderRow
}) => (
    <table className={`table table-hover table-sm table-striped ${rows && rows.length && 'no-more-tables'}`}>
        <IfRender condicion={ rows.length != 0 } 
            no={() => <p className='text-center'>{ emptyText }</p>}>
            <TableHeader headers={headers} />
            <IfRender
                condicion={rows.length > 0}
                no={() => <TableEmpty colSpan={headers.length} emptyText={emptyText} />}
            >
                <tbody>
                    { rows.map((row, index) =>
                        <tr key={row.id || index}>
                            { headers.map( header =>
                                <td key={header} data-title={header}>
                                    { renderRow(header, row) }
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
            </IfRender>
        </IfRender>
    </table>
);

export default ResponsiveTable;
