import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ReactComponent as IcoClip} from 'icons/clip.svg';
import useAdjuntosDeEstudio from 'hooks/estudios/useAdjuntosDeEstudio'
import IfRender from 'components/IfRender';
import Cargando from 'components/Cargando';

export default function ModalLinks({estudio}){
    const [show, setShow] = useState()
    const handleShow = () => {setShow(true)}
    const handleClose = () => {setShow(false)}
    const {cargandoAdjuntos, adjuntos, errorAdjuntos} = useAdjuntosDeEstudio(estudio.id)
    const icoStyle = {width: '20px', height: '20px'}
    return (
        <IfRender condicion={!errorAdjuntos}>
            <IfRender condicion={adjuntos.length > 0}  no={ () => <p>Sin Adjuntos</p>}>
                <button className="btn p-0" onClick={handleShow}>
                    <IcoClip style={icoStyle}/>
                </button>
            </IfRender>
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Imágenes de: {estudio.estudio}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <IfRender condicion={cargandoAdjuntos}>
                        <Cargando text="Cargando Enlaces"/>
                    </IfRender>

                    {adjuntos.map((adjunto, index) =>
                        <a href={adjunto}
                            target="_blank"
                            rel="noopener noreferrer"
                            key={index}>
                                Imagen N°{index+1}
                        </a>
                    )}
                </Modal.Body>
            </Modal>
        </IfRender>
    )}
