import React, {useContext, useState, useEffect} from 'react';
import { Row, Col } from 'react-bootstrap';
import LayoutPrincipal from 'layouts/LayoutPrincipal';
import Formularios from './Formularios';
import SectionWithTitle from 'components/SectionWithTitle';
import TemaContext from 'providers/Tema';
import ProximosTurnosPreanestesicos from './ProximosTurnosPreanestesicos';
import Boton from 'styledComponents/Boton.style';
import MensajeToast from 'components/MensajeToast';
import FormGroup from 'components/FormGroup';
import useFormulariosCompletos from 'hooks/anestesistas/useFormulariosCompletos'
import useProximosTurnosPreanestesicos from 'hooks/anestesistas/useProximosTurnosPreanestesicos'
import IfRender from 'components/IfRender'
import { Accordion, Card } from 'react-bootstrap';
import { Titulo } from 'styledComponents/Titulo.style';

export default function PanelAnestesistas(){
    const { cargandoFormularios, formularios, errorFormulario } = useFormulariosCompletos('getFormulariosAnestesista');
    const { cargandoProximosTurnos, proximosTurnos, errorProximosTurnos } = useProximosTurnosPreanestesicos();
    const [ formulariosParaMostrar, setFormulariosParaMostrar ] = useState(formularios);
    const [ proximosTurnosParaMostrar, setProximosTurnosParaMostrar ] = useState(proximosTurnos);
    const tema = useContext(TemaContext)
    const [copiado, setCopiado] = useState(false)
    const [mensajeAnular, setMensajeAnular] = useState()

    const handleDelete = id => {
        const formularioParaBorrar = formulario => formulario.id !== id;
        const formulariosActualizados = formulariosParaMostrar.filter(formularioParaBorrar);
        setFormulariosParaMostrar(formulariosActualizados);
    };

    const handleOnErrorEliminar = (formulario, mensajeError) => {
        setFormulariosParaMostrar(formulariosPrev => [
            ...formulariosPrev,
            formulario
        ]);
        setMensajeAnular(mensajeError);
    }

    useEffect(()=>{
        setFormulariosParaMostrar(formularios)
    },[formularios])

    useEffect(()=>{
        setProximosTurnosParaMostrar(proximosTurnos)
    },[proximosTurnos])

    const handlePaciente = e => {
        const filtrados = formularios.filter(
            formulario => formulario.paciente_nombre.toLowerCase().includes(e.target.value.toLowerCase())
        );
        const turnosFiltrados = proximosTurnos.filter(
            proximoTurno => proximoTurno.nombre_completo.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setFormulariosParaMostrar(filtrados);
        setProximosTurnosParaMostrar(turnosFiltrados)
    }

    const copyToClipboard = () => {
        setCopiado(false)
        navigator.clipboard.writeText('https://portal.sanatorioboratti.com.ar/anestesistas/pacientes')
        .then(() => {
            setCopiado(true)
        })
        .catch(err => {
          console.log('Error al copiar el enlace.', err);
        });
      }
    return (
        <>
        <IfRender condicion={mensajeAnular}>
             <MensajeToast
                title="Anular turno"
                mensaje={mensajeAnular}
            />
        </IfRender>
        <LayoutPrincipal>
            <div className="container mt-4 mb-4">
                <SectionWithTitle title="Consulta Preanestésica" tema={tema}>
                    <Row className="m-0">
                        <Col className="pr-0">
                            <FormGroup
                                type="text"
                                name="paciente"
                                label="Buscar paciente:"
                                onChange={handlePaciente}>
                            </FormGroup>
                        </Col>
                        <Col className="pl-0 d-flex align-items-center">
                            {/*<Boton className="btn mt-3 ml-2" onClick={onRefrescar} theme={tema}>
                                Refrescar
                            </Boton>*/}
                            <Boton className="btn mt-3 ml-2" onClick={copyToClipboard} theme={tema}>
                                Copiar link formulario paciente
                            </Boton>
                            <IfRender condicion={copiado}>
                                <MensajeToast
                                    title="Enlace copiado"
                                    mensaje="Se ha copiado el enlace al porta papeles"
                                />
                            </IfRender>
                        </Col>
                    </Row>
                    <Accordion className="m-2" defaultActiveKey="0">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                <Titulo tema={tema}>Formularios Preanestésico</Titulo>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <Formularios
                                        formulariosParaMostrar={formulariosParaMostrar}
                                        cargandoFormularios={cargandoFormularios}
                                        errorFormulario={errorFormulario}
                                        handleDelete={handleDelete}
                                        handleOnErrorEliminar={handleOnErrorEliminar}
                                        setMensajeAnular={setMensajeAnular}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                                <Titulo tema={tema}>Próximos Turnos</Titulo>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <ProximosTurnosPreanestesicos
                                        cargandoProximosTurnos={cargandoProximosTurnos}
                                        proximosTurnosParaMostrar={proximosTurnosParaMostrar}
                                        errorProximosTurnos={errorProximosTurnos}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </SectionWithTitle>
            </div>
        </LayoutPrincipal>
        </>
    );
}
