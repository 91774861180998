import TemaContext from 'providers/Tema';
import React, { useContext } from 'react';
import { Titulo } from 'styledComponents/Titulo.style';

export default function SectionWithTitle({title, className, children}){
    const tema = useContext(TemaContext);
    return (
        <section className={`rounded border bg-white shadow w-100 ${className}`}>
            <Titulo tema={tema}>{title}</Titulo>
            {children}
        </section>
    );
}
