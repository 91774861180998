import React from 'react';
import LayoutPrincipal from 'layouts/LayoutPrincipal';
import LoginLayout from 'layouts/LoginLayout';
import RequestResetPasswordForm from './RequestResetPasswordForm';

export default function RequestResetPasswordPage(){
    return (
        <LayoutPrincipal>
            <LoginLayout title="Enviar email de reinicio de contraseña">
                <RequestResetPasswordForm />
            </LoginLayout>
        </LayoutPrincipal>
    );
}
