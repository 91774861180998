import useValidarTelefono from 'hooks/useValidarTelefono';
import React from 'react';
import FormGroup from './FormGroup';

export default function InputTelefono({onChange, value, required = false, id = null, placeholder, readOnly = false}) {
    const { error } = useValidarTelefono(id);
    return (
        <FormGroup
            label="Teléfono"
            type="number"
            placeholder={placeholder}
            name="telefono"
            onChange={onChange}
            value={value}
            required={required}
            id={id}
            error={error}
            readOnly={readOnly}
        />
    )
}
