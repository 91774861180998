import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export function useEstadisticaAltaUsuarios(desde,hasta){
    const [estadisticasAltaUsuarios, setEstadisticasAltaUsuarios] = useState([]); 
    const [cargandoEstadisticasAltaUsuarios, setCargandoEstadisticasAltaUsuarios] = useState(true);
    const [errorEstadisticasAltaUsuarios, setErrorEstadisticasAltaUsuarios] = useState();

    const handleResponse = response =>{
        setEstadisticasAltaUsuarios(response.data);
        setCargandoEstadisticasAltaUsuarios(false);
    }

    useEffect(() =>  {
        setCargandoEstadisticasAltaUsuarios(true);
        apiGet(`api/altaUsuarios?desde=${desde}&hasta=${hasta}`)
            .then(handleResponse)
            .catch(errorEstadisticasAltaUsuarios => {
                setErrorEstadisticasAltaUsuarios("Error inesperado.");
                setCargandoEstadisticasAltaUsuarios(false);
            });
    },[desde,hasta]);

    return {cargandoEstadisticasAltaUsuarios, estadisticasAltaUsuarios, errorEstadisticasAltaUsuarios};
}