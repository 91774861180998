import React from 'react';
import mujerLogo from 'icons/mujer_con_laptop.jpg';
import SectionWithTitle from 'components/SectionWithTitle';
import { Col, Row } from 'react-bootstrap';
import url from 'utils/url';

export default function LoginLayout({children, title}){
    const formBackground = {
        backgroundImage: "url(http://sanatorioboratti.com.ar/imagenes/encabezado/frente.jpg)"
    };
    const backgroundRelleno = {
        backgroundRepeat: 'no-repeat'
    }
    const imgSize = {
        height: "100%", width: "100%"
    }
    return (
        <Row className="w-100 h-100 m-0">
            <Col md={6} className="p-4 d-flex flex-column justify-content-center align-items-center"
                 style={formBackground}>
                <SectionWithTitle title={title}>
                    {children}
                </SectionWithTitle>
            </Col>
            <Col md={6} className="d-none d-md-block p-0" style={backgroundRelleno}>
                <img src={url(mujerLogo)} alt="mujerLogo" style={imgSize}/>
            </Col>
        </Row>
    );
}
