import React, { useState } from "react";
import "css/responsiveTable.css";
import ResponsiveTable from "components/tabla/ResponsiveTable";
import FilaTurno from "components/turnos/FilaTurno";
import { Alert } from "react-bootstrap";

export default function TablaTurnos({ headers, turnos }) {
    const [turnosParaMostrar, setTurnosParaMostrar] = useState(turnos);
    const [mensajeAnular, setMensajeAnular] = useState(null);
    const [visible, setVisible] = useState(false);

    const handleDelete = (id, mensaje) => {
        const turnoParaBorrar = (turno) => turno.id !== id;
        const turnosActualizados = turnosParaMostrar.filter(turnoParaBorrar);
        setTurnosParaMostrar(turnosActualizados);
        setMensajeAnular(mensaje);
        setVisible(true);
    };

    const handleOnErrorEliminar = (turno, mensajeError) => {
        setTurnosParaMostrar((turnosPrev) => [...turnosPrev, turno]);
        setMensajeAnular(mensajeError);
        setVisible(true);
    };

    const handleCerrarAlerta = () => {
        setVisible(false);
    };

    return (
        <>
            {visible && (
                <div>
                    <Alert
                        variant="warning"
                        onClose={handleCerrarAlerta}
                        dismissible
                        style={{
                            backgroundColor: "#FFD700",
                            borderColor: "#FFD700",
                        }}
                    >
                        <Alert.Heading>
                            <span className="mr-2">
                                <i className="bi bi-exclamation-triangle-fill"></i>
                            </span>
                            <strong style={{ fontSize: "85%" }}>
                                Anulaste el Turno
                            </strong>
                        </Alert.Heading>
                        <p style={{ color: "black" }}>{mensajeAnular}</p>
                    </Alert>
                </div>
            )}
            <ResponsiveTable
                headers={headers}
                rows={turnosParaMostrar}
                emptyText="No se encontraron turnos"
                renderRow={(col, row) => (
                    <FilaTurno
                        col={col}
                        turno={row}
                        handleDelete={handleDelete}
                        setMensajeAnular={setMensajeAnular}
                        handleOnErrorEliminar={handleOnErrorEliminar}
                    />
                )}
            />
        </>
    );
}



// import React, { useState, useEffect } from "react";
// import "css/responsiveTable.css";
// import ResponsiveTable from "components/tabla/ResponsiveTable";
// import FilaTurno from "components/turnos/FilaTurno";
// import MensajeToast from "components/MensajeToast";
// import { Alert, Modal, Button } from "react-bootstrap";
// import IfRender from "components/IfRender";
// export default function TablaTurnos({ headers, turnos }) {
//     const [turnosParaMostrar, setTurnosParaMostrar] = useState(turnos);

//     const [mensajeAnular, setMensajeAnular] = useState();
//     const [visible, setVisible] = useState(false);
//     const [showModal, setShowModal] = useState(false);

//     const handleDelete = (id) => {
//         const turnoParaBorrar = (turno) => turno.id !== id;
//         const turnosActualizados = turnosParaMostrar.filter(turnoParaBorrar);
//         setTurnosParaMostrar(turnosActualizados);
//         setShowModal(true);
//     };

//     const handleOnErrorEliminar = (turno, mensajeError) => {
//         setTurnosParaMostrar((turnosPrev) => [...turnosPrev, turno]);
//         setMensajeAnular(mensajeError);
//     };

//     useEffect(() => {
//         if (turnosParaMostrar.length === 0) {
//             setVisible(true);
//         }
//     }, [turnosParaMostrar]);

//     return (
//         <>
//             <IfRender condicion={ mensajeAnular }>
//             <MensajeToast
//                 title="Anular turno"
//                 mensaje={mensajeAnular}
//             />
//          </IfRender>
//             <ResponsiveTable
//                 headers={headers}
//                 rows={turnosParaMostrar}
//                 emptyText="No se encontraron turnos"
//                 renderRow={(col, row) => (
//                     <FilaTurno
//                         col={col}
//                         turno={row}
//                         handleDelete={handleDelete}
//                         setMensajeAnular={setMensajeAnular}
//                         handleOnErrorEliminar={handleOnErrorEliminar}
//                     />
//                 )}
//             />
//         </>
//     );
// } codigo oiginal sin cambios
