import TemaContext from 'providers/Tema';
import React, { useContext, useRef, useState } from 'react';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { ThemeProvider } from 'styled-components';
import Boton from 'styledComponents/Boton.style';

export default function FileUploader({
    nombreArchivo = '',
    placeholder = null,
    onSelect = () => {},
    labelExaminar = null,
    label = null,
    required = false,
    className = '',
    accept = '',
    multiple = false
}) {

    const inputFile = useRef();
    const [fileName, setFileName] = useState(nombreArchivo);
    const tema = useContext(TemaContext);

    const explorarArchivos = () => inputFile.current.click();

    const concatenarNombreArchivos = files => {
        const concatFileNames = (acumulador, fileActual) => `${acumulador}${fileActual.name}; `;
        return Array.from(files).reduce(concatFileNames, '');
    }

    const onChange = evt => {
        onSelect(evt.target.files);
        const filesNamesConcated = concatenarNombreArchivos(evt.target.files);
        setFileName(filesNamesConcated);
    }

    const onChangeinput = evt => setFileName(evt.target.value);

    return (
        <>
            <span className="d-block">{label}</span>
            <InputGroup className={className}>
                <Form.File
                    ref={inputFile}
                    required={required}
                    onChange={onChange}
                    hidden
                    accept={accept}
                    multiple={multiple}
                />

                <FormControl
                    placeholder={placeholder}
                    value={fileName}
                    disabled
                    onChange={onChangeinput}
                />

                <InputGroup.Append>
                    <ThemeProvider theme={tema}>
                        <Boton className="btn" onClick={explorarArchivos}>
                            {labelExaminar}
                        </Boton>
                    </ThemeProvider>
                </InputGroup.Append>
            </InputGroup>
        </>
    )
}
