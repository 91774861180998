import React from 'react';
import LayoutPrincipal from 'layouts/LayoutPrincipal';
import SectionWithTitle from 'components/SectionWithTitle';
import ModalValidacionDni from 'components/ModalValidacionDni/ModalValidacionDni';

export default function SeccionBloqueada({setHasAccess}){
    return (
        <LayoutPrincipal>
            <div className="p-4 w-100">
                <SectionWithTitle title="Mis estudios">
                    <p align="center">
                        Por seguridad de su información, esta sección se encuentra bloqueada hasta que tramite la habilitación
                    </p>
                    <ModalValidacionDni
                        onValidate={() => setHasAccess(true)}
                        botonLabel="Pulse aquí para solicitar su habilitación"
                        title="Envíenos una foto de su D.N.I."
                        placeholderFrente="Suba una foto del frente de su D.N.I."
                        placeholderReverso="Suba una foto del reverso de su D.N.I."
                        validadorUrl="api/validarFotoDni"
                    />
                </SectionWithTitle>
            </div>
        </LayoutPrincipal>
    );
}
