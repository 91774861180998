import React, { useEffect, useState } from 'react';
import CambiarFecha from 'components/estudios/CambiarFecha'
import Combo from 'components/Combo';
import IfRender from 'components/IfRender';
import Cargando from 'components/Cargando';

export default function SeleccionarPropietario({
    familiares,
    cargandoFamiliares,
    user,
    setDocumento,
    setInvalido,
    fechaInicio,
    setFechaInicio,
    mostrarFecha,
    servicio,
    setServicio,
}) {

    const [mostrarCombo, setMostrarCombo] = useState(false)
    const [dniSeleccionado, setDniSeleccionado] = useState(user.dni)
    const [grupoFamiliar, setGrupoFamiliar] = useState([])

    useEffect(() => {
        setMostrarCombo(familiares.length > 0)
        setGrupoFamiliar([user.datos_personales, ...familiares])
    }, [familiares, user.datos_personales])

    const handlePropietarioChange = (e) => {
        if (user.dni === e.target.value) {
            setDocumento(user.dni)
            setInvalido(false)
        } else {
            esFamiliar(e)
        }
        setDniSeleccionado(e.target.value)
    }

    function esFamiliar(e) {
        const familiar = grupoFamiliar.find(f => f.dni === e.target.value)
        if (familiar.validado === "0") {
            setInvalido(familiar)
        } else {
            setDocumento(familiar.dni)
            setInvalido(false)
        }
    }

    const isInvalido = (familiar) => {
        return familiar.validado === "0"
    }

    return (
        <>
            <IfRender condicion={cargandoFamiliares}>
                <Cargando text="Cargando filtros" />
            </IfRender>

            <IfRender condicion={mostrarCombo}>
                <Combo
                    opciones={grupoFamiliar}
                    label="Seleccione una persona"
                    opcionId="dni"
                    opcionValue="apellidoNombre"
                    defaultValue={dniSeleccionado}
                    cargando={cargandoFamiliares}
                    onChange={handlePropietarioChange}
                    destacarOpcion={isInvalido}
                    destacarOpcionClass="text-danger"
                />
            </IfRender>

            <IfRender condicion={!cargandoFamiliares && mostrarFecha}>
                <CambiarFecha
                    fechaInicio={fechaInicio}
                    setFechaInicio={setFechaInicio}
                    servicio={servicio}
                    setServicio={setServicio}>
                </CambiarFecha>
            </IfRender>
        </>
    );
}
