import React from 'react';

const style = {
    width: '5em',
    height: '5em'
}

const MensajeConIcon = ({mensaje, Icon}) => (
    <div className="d-flex justify-content-center align-items-center flex-column border rounded p-2">
        <p className="text-center">{mensaje}</p>
        <Icon style={style}/>
    </div>
);

export default MensajeConIcon;
