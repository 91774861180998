import { ReactComponent as IcoEstudio} from 'icons/estudios.svg';
import { ReactComponent as IcoCalendario} from 'icons/calendario.svg';
import { ReactComponent as IcoMisDatos} from 'icons/misdatos.svg';
import { ReactComponent as IcoHome} from 'icons/home.svg';
import { ReactComponent as IcoUsuario} from 'icons/crear-user.svg';
import { ReactComponent as IcoAnestesista} from 'icons/anestesista.svg';
import { ReactComponent as IcoAnestesista2} from 'icons/anestesista2.svg';
import { ReactComponent as IcoBuscarUsuario} from 'icons/userSearch.svg';
import { ReactComponent as IcoEstadistica} from 'icons/estadistic-solid.svg';
import { ReactComponent as IcoVisitasMedicas} from 'icons/stethoscope.svg';
import { ReactComponent as IcoConfiguracion} from 'icons/settings.svg';
import { ReactComponent as IcoInternaciones} from 'icons/hospital.svg';

const iconos = {
    icoHome: IcoHome,
    icoEstudio: IcoEstudio,
    icoCalendario: IcoCalendario,
    icoMisDatos: IcoMisDatos,
    icoUsuario: IcoUsuario,
    icoVisitasMedicas: IcoVisitasMedicas,
    icoAnestesista: IcoAnestesista,
    icoAnestesista2: IcoAnestesista2,
    icoBuscarUsuario: IcoBuscarUsuario,
    icoEstadistica: IcoEstadistica,
    icoConfiguracion: IcoConfiguracion,
    icoInternaciones : IcoInternaciones
};

const getIcon = name => iconos[name];

export default getIcon;
