const cardStyle = {
    width: '100%',
    heigth: 'auto'
}

const headerStyle = {
    background: 'linear-gradient(180deg, #034ea2 50%, rgba(255,255,255,1) 50%)',
    border: 'none'
}

const imgStyle = {
    border: '.5em solid white',
    borderColor: 'rgba(255,255,255,0.5)',
}

export { cardStyle, headerStyle, imgStyle };
