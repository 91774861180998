import { UserContext } from "providers/UserProvider";
import { useContext } from "react";
import useSubmit from "./useSubmit";

export default function useCambiarPassword({token, email}){
    const { addUser } = useContext(UserContext);

    const handleOnReciveData = ({user}) => addUser(user);

    const {
        failed,
        procesando,
        errors,
        state,
        setErrors,
        controller
    } = useSubmit({
        initialState: {token, email},
        url: 'api/password/reset',
        onReciveData: handleOnReciveData
    });

    return {
        failed,
        procesando,
        errors,
        state,
        setErrors,
        controller: controller
    }
}
