import TemaContext from 'providers/Tema';
import React, { useContext } from 'react';


const TableHeader = ({headers}) => {
    const tema = useContext(TemaContext);
    const thStyle = {borderColor: tema.primario, borderTop: 'none'};
    return (
        <thead>
            <tr>
                { headers.map(header => <th key={header} scope="col" style={thStyle}>{header}</th>) }
            </tr>
        </thead>
    );
}

export default TableHeader;
