import { Modal } from 'react-bootstrap';
import React from 'react';
import Cargando from 'components/Cargando';
import IfRender from 'components/IfRender';
import MensajeExito from 'components/MensajeExito'
import MensajeError from 'components/MensajeError';


export default function ModalReceptar({mostrar, setMostrar, mensaje, titulo, exito}){

    const handleClose = () => setMostrar(false)

    return (
        <>
        <Modal show={mostrar} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{titulo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <IfRender condicion={mensaje} no={()=> <Cargando text="Cargando"/> }>
                    <IfRender condicion={exito} no={()=> <MensajeError mensaje={mensaje}/> }>
                        <MensajeExito mensaje={mensaje} />
                    </IfRender>
                </IfRender>

            </Modal.Body>
        </Modal>
        </>
    );
}
