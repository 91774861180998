import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";


export default function useTraerInternacion(id){
    const [internacion,setInternacion]=useState(null);
    const [cargando, setCargando] = useState();
    const [error, setError] = useState();

    const handleResponse = response =>{
        setInternacion(response.data);
        setCargando(false);
    }

    useEffect(() =>  {
        setCargando(true);
        if(id){
          apiGet(`api/traerInternacion?id_internacion=${id}`)
            .then(handleResponse)
            .catch(error => {
                setError("Error inesperado.");
                setCargando(false);
            })
        }
    },[id]);


    return {cargando, internacion, error};
}

