import LoginProvisorioForm from 'components/login/LoginProvisorioForm';
import SectionWithTitle from 'components/SectionWithTitle';
import LayoutPrincipal from 'layouts/LayoutPrincipal';
import React from 'react';
import { Container } from 'react-bootstrap';

export default function LoginProvisorioPage({params}) {
    return (
        <LayoutPrincipal withLogos={false}>
            <Container className="my-2">
                <SectionWithTitle title="Iniciar sesión" >
                    <p className="m-0">Ingrese con su usuario y contraseña provisorios enviados en el mensaje</p>
                    <LoginProvisorioForm
                        dniInicial={params.dni}
                        contraseñaProvisoriaInicial={params.claveProvisoria}/>
                </SectionWithTitle>
            </Container>
        </LayoutPrincipal>
    );
}
