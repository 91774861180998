import { apiGet } from 'api/configApi';
import { useEffect, useState } from 'react';

export default function useAtencion(){
    const [centros, setCentros] = useState([]);
    const [cargandoCentros, setCargandoCentros] = useState(true);
    const [centroSeleccionado, setCentroSeleccionado] = useState();
    const [error, setError] = useState();
    const [servicios, setServicios] = useState([]);
    const [cargandoServicios, setCargandoServicios] = useState(false);
    const [servicioSeleccionado, setServicioSeleccionado] = useState();
    const [cargandoTurnos, setCargandoTurnos] = useState(false);
    const [turnos, setTurnos] = useState([]);

    const handleSelectCentro = evt => setCentroSeleccionado(evt.target.value);
    const handleSelectServicio = evt => setServicioSeleccionado(evt.target.value);
    const handleError = error => setError(error.response.data.error);
    const getPrimerOpcion = opciones => opciones.length > 0 ? opciones[0].id : null;

    useEffect(() => {
        apiGet('api/getCentrosAtencionMedico')
            .then(response => {
                const centros = response.data.centros;
                setCentros(centros);
                setCargandoCentros(false);
                setCentroSeleccionado(getPrimerOpcion(centros));
            })
            .catch(handleError);
    }, []);

    useEffect(() => {
        if(centroSeleccionado){
            setCargandoServicios(true);
            apiGet(`api/getServiciosCentro/${centroSeleccionado}`)
                .then(response => {
                    const servicios = response.data.servicios;
                    setServicios(servicios);
                    setCargandoServicios(false);
                    setServicioSeleccionado(getPrimerOpcion(servicios));
                })
                .catch(handleError);
        }
    }, [centroSeleccionado]);

    useEffect(() => {
        if(centroSeleccionado && servicioSeleccionado){
            setCargandoTurnos(true);
            apiGet(`api/getTurnosReceptados/${centroSeleccionado}/${servicioSeleccionado}`)
                .then(response => {
                    setTurnos(response.data.turnos);
                    setCargandoTurnos(false);
                })
                .catch(handleError);
        }
    }, [centroSeleccionado, servicioSeleccionado]);

    return {
        state: {
            centros,
            cargandoCentros,
            centroSeleccionado,
            error,
            servicios,
            cargandoServicios,
            servicioSeleccionado,
            cargandoTurnos,
            turnos
        },
        controller: {
            handleSelectCentro,
            handleSelectServicio
        }
    }
}
