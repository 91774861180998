import React from 'react';
import TemaContext from "providers/Tema";
import { useContext, useState } from "react";
import { ThemeProvider } from "styled-components";
import Boton from "styledComponents/Boton.style";
import RegistroPacienteAdministracionForm from './RegistroPacienteAdministracionForm';
import { Modal } from 'react-bootstrap';
import { PacienteContext } from 'providers/PacienteProvider';

export default function ModalNuevoUsuario(){
    const tema = useContext(TemaContext);
    const [show, setShow] = useState(false);
    const { paciente, setUser, setPaciente } = useContext(PacienteContext);
    let nuevoPaciente, nuevoUsuario;

    const handleShow = () => setShow(true);

    const setNuevoUsuarioAndPaciente = () => {
        if(nuevoPaciente && nuevoUsuario){
            setUser(nuevoUsuario);
            setPaciente(nuevoPaciente);
        }
    }

    const handleClose = () => {
        setShow(false);
        setNuevoUsuarioAndPaciente();
    };

    const handleRegister = datos => {
        nuevoPaciente = datos.datos_personales;
        nuevoUsuario = datos;
    }

    return (
        <>
        <ThemeProvider theme={tema}>
            <Boton className="btn w-100 mt-2" onClick={handleShow}>
                Registrar nuevo usuario
            </Boton>
        </ThemeProvider>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Registrar usuario para { paciente.nombre }</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <RegistroPacienteAdministracionForm onRegister={handleRegister}/>
            </Modal.Body>
        </Modal>
        </>
    );
}
