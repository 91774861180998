import React, { useContext, useState } from 'react';
import Boton from 'styledComponents/Boton.style';
import { ThemeProvider } from 'styled-components';
import FormGroup from 'components/FormGroup';
import TemaContext from 'providers/Tema';
import SkeletonForm from 'components/SkeletonForm';
import ComboServiciosPorCentro from 'components/turnos/ComboServiciosPorCentro';
import ComboMedicos from 'components/turnos/ComboMedicos';
import useServiciosPorCentro from 'hooks/turnos/useServiciosPorCentro';
import useMedicosPorServicio from 'hooks/turnos/useMedicos';
import useRegistroRestriccionMedicoTurno from 'hooks/configuracion/RestriccionMedicoTurnoOnline/useRegistroRestriccionMedicoTurno';
import 'primeicons/primeicons.css';
        
export default function RegistroRestriccionMedicoTurno() {
    const {errors, registrando, state, controller} = useRegistroRestriccionMedicoTurno();
    const [servicio, setServicio] = useState(0);
    const {cargandoServicios, serviciosPorCentro, errorServicio} = useServiciosPorCentro(1);
    const {medicos,cargandoMedicos,errorMedicos} = useMedicosPorServicio(servicio);
    const theme = useContext(TemaContext);

    const handleServicioChange =(e) =>{
        setServicio(e.target.value)
    }
            
    return (
        <SkeletonForm
            invalid={errors}
            validando={registrando}
            handleSubmit={controller.onSubmit}
            invalidMessage={errors}
            validandoMessage ="Registrando"
            render={() => (
            <>
                <ComboServiciosPorCentro
                    serviciosPorCentro={serviciosPorCentro}
                    handleServicioChange={handleServicioChange}
                    cargandoServicios={cargandoServicios}
                    servicio={servicio}
                    paddingClass= 'px-0'
                />
                <ComboMedicos
                    medicos={medicos}
                    handleMedicoChange={controller.handleOnChangeMedico}
                    cargandoMedicos={cargandoMedicos}
                    medico={state.medico}
                    paddingClass= 'px-0'
                />
                <FormGroup
                    label="Teléfono a mostrar"
                    type="number"
                    placeholder="Ingrese el numero de teléfono"
                    name="telefono"
                    onChange={controller.handleOnChangeTelefono}
                    value={state.telefono}>
                </FormGroup>
                <ThemeProvider theme={theme}>
                    <Boton className="btn btn-primary w-100 mt-2">
                        Guardar
                    </Boton>
                </ThemeProvider>
            </>
            )}
        />
    );
}
