import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useTaerDatosTurno(id_turno){
    const [turno, setTurno] = useState(null);
    const [idTurno,setIdTurno] = useState();
    const [cargandoTurno, setCargandoTurno] = useState(true);
    const [errorTruno, setErrorTurno] = useState();
    const handleResponse = response =>{
        setTurno(response.data[0]);
        setIdTurno(response.data[0].idTurno);
        setCargandoTurno(false);
    }

    useEffect(() => {
        setCargandoTurno(true);
        apiGet(`api/turnos/traerDatosTurno/${id_turno}`)
            .then(handleResponse)
            .catch(error => {
                setErrorTurno("Error inesperado.");
                setCargandoTurno(false);
            })
    },[id_turno]);


    return {cargandoTurno, turno, errorTruno,idTurno};

}
