import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useTraerDatosPersona(parametro,tipo){
    const [persona, setPersona] = useState();
    const [dni,setDni] = useState();
    const [telefonoCelular,setTelefonoCelular] = useState();
    const [cargandoPersona, setCargandoPersona] = useState(true);
    const [errorPersona, setErrorPersona] = useState();


    const handleResponse = response =>{
        setPersona(response.data[0]);
        setDni(response.data[0].dni);
        setTelefonoCelular(response.data[0].telefonoCelular);
        setCargandoPersona(false)
    }

    useEffect(() =>  {
        setCargandoPersona(true);
        if(tipo ==='dni'){
            apiGet(`api/datosPersonales/${parametro}`)
            .then(handleResponse)
            .catch(error => {
                setErrorPersona("Error Inesperado");
                setCargandoPersona(false);
            })
        }else if(tipo ==='id'){
            apiGet(`api/getDatosPersonaId/${parametro}`)
            .then(handleResponse)
            .catch(error => {
                setErrorPersona("Error Inesperado");
                setCargandoPersona(false);
            })
        }

    },[parametro,tipo]);


    return {cargandoPersona,persona,errorPersona,dni,telefonoCelular};

}
