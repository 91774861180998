import React, { useState } from 'react';
import LayoutConMenuLateral from 'layouts/LayoutConMenuLateral';
import SectionWithTitle from 'components/SectionWithTitle';
import {DataTable} from 'primereact/datatable'
import { Column } from 'primereact/column';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Panel} from 'primereact/panel';
import {InputText} from 'primereact/inputtext';
import {Toolbar} from 'primereact/toolbar';
import {Calendar} from 'primereact/calendar';
import {addLocale} from 'primereact/api';
import useInternaciones from 'hooks/internaciones/useInternaciones';
import useTraerInternacion from 'hooks/internaciones/useTraerInternacion';
import SelectPersona from 'components/Internaciones/SelectPersona';


export default function PageInternaciones(){

    const [id,setId] = useState(null);
    const {internacion}= useTraerInternacion(id);
    const [internacionDialog, setInternacionDialog] = useState(false);
    const [idPersona, setIdPersona] = useState(-1);
    const d = new Date();
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    const [desde, setDesde] = useState(`${ye - 1}-${mo}-${da}`);
    const [hasta, setHasta] = useState(`${ye}-${mo}-${da}`);
    const [fechaActual] = useState(`${da}-${mo}-${ye}`);
    const {internaciones} = useInternaciones(idPersona,desde,hasta);

    const viewInternacion = ({id}) => {
        setId(id);
        setInternacionDialog(true);
    }

    const hideDialog = () => {
        setInternacionDialog(false);
    }

    //Boton de la columna acciones de la tabla
    const actionTemplate = (rowData)=> {
        return  (
            <React.Fragment>
                <Button icon="pi pi-eye" className="p-button-rounded p-button-success mr-2" onClick={() => viewInternacion(rowData)} ></Button>
            </React.Fragment>
        );
    }

    //footer del modal que muestra el datalle de internacion
    const footerContentDialog = () =>{
        return(
            <div>
                {/* <Button label="Imprimir" icon="pi pi-print" onClick={() => hideDialog()} className="p-button-text" /> */}
                <Button label="Cerrar" icon="pi pi-close" onClick={() => hideDialog()} className="p-button-text" />
            </div>
        );
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className=''>
                    <SelectPersona idPersona={idPersona} setIdPersona={setIdPersona} desde={desde} setFechaDesde={setDesde} hasta={hasta} setFechaHasta={setHasta}/>
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" placeholder="buscar..." className='p-inputtext-sm'/>
                </span>
            </React.Fragment>
        )
    }


    return (
        <>
            <LayoutConMenuLateral>
                <SectionWithTitle title="Mis Internaciones">
                    <div className='card'>
                        <Toolbar className="mb-1 p-1" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                        <DataTable value={internaciones} size={'small'} emptyMessage="Sin Resultados">
                            <Column field='id' header="ID"></Column>
                            <Column field='motivo' header="Motivo"></Column>
                            <Column field='ingreso' header="Ingreso"></Column>
                            <Column field='egreso' header="Egreso"></Column>
                            <Column field='servicio' header="Servicio"></Column>
                            <Column body={actionTemplate} header="Accion"> </Column>
                        </DataTable>
                    </div>

                    <div>
                        <Dialog visible={internacionDialog} header="Detalle Internacion" className="" onHide={hideDialog} footer={footerContentDialog}>
                            <Panel header="Cabecera">
                                <div className='d-flex flex-row flex-wrap d-inline'>
                                    <div className='p-2'>
                                        <label id="fechaReporte"><strong for="fechaReporte">Fecha Reporte: </strong>{fechaActual}</label>
                                    </div>
                                    <div className='p-2'>

                                        <label id="nombre"><strong for="nombre">Nombre: </strong>{internacion ? internacion[0].nombre : ''}</label>
                                    </div>
                                    <div className='p-2'>

                                        <label id="nombre"><strong for="nombre">Sexo: </strong>{internacion ? internacion[0].sexo : ''}</label>
                                    </div>
                                    <div className='p-2'>

                                        <label id="nombre"><strong for="nombre">Edad: </strong>{internacion ? internacion[0].edad : ''}</label>
                                    </div>
                                    <div className='p-2'>

                                        <label id="nombre"><strong for="nombre">Informe: </strong>{internacion ? internacion[0].servicio : ''}</label>
                                    </div>
                                </div>
                            </Panel>
                            <Panel header="Problema Principal">
                                <div className='d-flex flex-row flex-wrap d-inline'>
                                    <div className=''>
                                        {internacion ? internacion[0].problema : ''}
                                    </div>
                                </div>
                            </Panel>
                            <Panel header="Profesional">
                                <div className='d-flex flex-row flex-wrap d-inline'>
                                    <div className=''>
                                        {internacion ? internacion[0].profesional : ''}
                                    </div>
                                </div>
                            </Panel>
                        </Dialog>
                    </div>
                </SectionWithTitle>
            </LayoutConMenuLateral>
        </>
    );
}


