import React from 'react';

export default function  FormCheck({label, name, checked, onChange}){
    return (
        <div className="form-check">
            <input
                type="checkbox"
                className="form-check-input"
                id={name}
                name={name}
                onChange={onChange}
                checked={checked}
                />
            <label
                className="form-check-label"
                htmlFor={name}>
                {label}
            </label>
        </div>
    );
}
