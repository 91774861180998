import React, { useContext } from 'react';
import Boton from 'styledComponents/Boton.style';
import { ThemeProvider } from 'styled-components';
import TemaContext from 'providers/Tema';
import FormGroup from 'components/FormGroup';
import SkeletonForm from 'components/SkeletonForm';
import useCambiarPassword from 'hooks/useCambiarPassword';

const ResetPasswordForm = ({token, email}) => {
    const tema = useContext(TemaContext);
    const { state, controller, errors, procesando } = useCambiarPassword({token, email});

    const onSubmit = evt => {
        evt.preventDefault();
        controller.sendRequest();
    }

    return (
        <SkeletonForm
            invalid={errors}
            validando={procesando}
            handleSubmit={onSubmit}
            invalidMessage={errors}
            validandoMessage="Cambiando contraseña"
            render={() => (
                <>
                <input
                    type="hidden"
                    name="email"
                    value={state.email}
                    onChange={controller.handleOnChange}
                    required>
                </input>
                <input
                    type="hidden"
                    name="token"
                    value={state.token}
                    onChange={controller.handleOnChange}
                    required>
                </input>
                <FormGroup
                    type="password"
                    name="password"
                    label="Contraseña"
                    value={state.confirm_password}
                    onChange={controller.handleOnChange}
                    required>
                </FormGroup>
                <FormGroup
                    type="password"
                    name="password_confirmation"
                    label="Repetir contraseña"
                    value={state.confirm_password}
                    onChange={controller.handleOnChange}
                    required>
                </FormGroup>
                <ThemeProvider theme={tema}>
                    <Boton className="btn w-100 mt-2" disabled={false} >
                        Confirmar nueva contraseña
                    </Boton>
                </ThemeProvider>
                </>
            )}
        />
    );

};

export default ResetPasswordForm;
