import { useState } from "react";
import useSubmit from "./useSubmit";

export default function useRegisterPacienteAdministracion({dni, telefono, email ,onRegister}){
    const [noRegistrado, setNoRegistrado] = useState(true);

    const onReciveData = datos => {
        setNoRegistrado(false);
        onRegister(datos);
    };

    const {
        failed,
        procesando,
        errors,
        state,
        setErrors,
        controller
    } = useSubmit({
        initialState: { dni, telefono, email },
        url: 'api/registrarProvisorioAdministracion',
        onReciveData
    });

    return {
        failed,
        registrando: procesando,
        errors,
        state,
        noRegistrado,
        setErrors,
        controller
    }
}
