const { REACT_APP_HOST_URL, NODE_ENV, REACT_APP_HOST_URL_3000, REACT_APP_API_URL} = process.env;
const ERROR_MESAGGE = 'No se definieron las variables REACT_APP_HOST_URL, REACT_APP_HOST_URL_3000 o REACT_APP_API_URL en el .env';

export default function url(path = ''){
    validarVariablesEnv();
    return makeUrl(path);
}

function validarVariablesEnv(){
    if(existenVariablesEntorno()){
        throw new Error(ERROR_MESAGGE);
    }
}

function existenVariablesEntorno(){
    return (
        typeof REACT_APP_HOST_URL === 'undefined' ||
        typeof REACT_APP_HOST_URL_3000 === 'undefined' ||
        typeof REACT_APP_API_URL === 'undefined'
    );
}

function makeUrl(path){
    return determinarHost() + '/' + eliminarBarra(path);
}

function determinarHost(){
    let host;
    if(NODE_ENV === 'production'){
        host = REACT_APP_HOST_URL;
    }
    else{
        host = REACT_APP_HOST_URL_3000;
    }
    return host;
}

function eliminarBarra(path){
    return path.slice(
        path[0] === '/' ? 1 : 0,
        path[path.length - 1] === '/' ? -1 : path.length
    );
}

function apiUrl(path = ''){
    return REACT_APP_API_URL + '/' + eliminarBarra(path);
}

function getParamFromUrl(param){
    const url = new URL(window.location.href);
    const search = new URLSearchParams(url.search);
    return search.get(param);
};

export { apiUrl, getParamFromUrl };
