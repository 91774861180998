import { apiPost } from "api/configApi";

export default function useEliminarRestriccionMedicoTurno({restriccion, setMensajeEliminar, handleOnErrorEliminar}){
    const restriccionBackUp = restriccion;
    const handleEliminarResponse = (respuesta) =>{
        setMensajeEliminar(respuesta.mensaje);
        setTimeout(() => {
            window.location.reload(false);
        }, 100);
    }

    const eliminar = () => {
        apiPost(`api/eliminarRestriccionMedicoTurno/${restriccion.id}`)
            .then((response) => handleEliminarResponse(response.data))
            .catch(error => handleOnErrorEliminar(restriccionBackUp, error.response.data.mensaje))
    };

    return eliminar;
}
