import React from "react";
import LayoutConMenuLateral from "layouts/LayoutConMenuLateral";
import SectionWithTitle from "components/SectionWithTitle";
import { Button } from "react-bootstrap";

export default function RestriccionTurnosPorMes() {
    const turnosPorMesPredefinidos = 3; //Cantidad predefinida de turnos por mes

    return (
        <LayoutConMenuLateral>
            <SectionWithTitle title=" Restricción de turnos online por mes">
                <div style={{ textAlign: "center" }}>
                    <p style={{ margin: "0 auto", maxWidth: "400px" }}>
                        La cantidad de turnos online permitidos por mes es:{" "}
                        {turnosPorMesPredefinidos}
                    </p>
                </div>
            </SectionWithTitle>
        </LayoutConMenuLateral>
    );
}
