import React from 'react';
import { Link } from 'wouter';
import url from 'utils/url';
import { SelectedLink } from 'styledComponents/SelectedLink.style';

export default function BotonMenuLateral({title, Ico, path, selected = false, index = '0', onClick}){
    const estilosIco = {width: '25px', height: '25px', fill: 'white'};
    return (
        <Link href={url(path)} >
            <SelectedLink
                selected={selected}
                className="p-0 border-0 border-md-1 rounded-0"
                data-index={index}
                onMouseUp={onClick}>
                <li className="d-flex m-3 flex-column justify-content-center align-items-center">
                    <Ico style={estilosIco}/>
                    <span className="text-white d-none d-md-block text-nowrap" style={{ fontSize: '1rem' }}>
                        {title}
                    </span>
                </li>
            </SelectedLink>
        </Link>
    );
}
