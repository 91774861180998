import api from "axios";
import { apiUrl } from "utils/url";

api.defaults.withCredentials = true;

const apiGet = url => api.get(apiUrl(url));

const apiPost = (url, data = {}) => api.post(apiUrl(url), data);

const getCsrfCookie = () => apiGet('sanctum/csrf-cookie');

const reduceErrorMessages = errorResponse => {
    const erroresEnRespuesta = errorResponse.response.data.errors;
    let mensajesError = '';
    for (let errores in erroresEnRespuesta){
        mensajesError += erroresEnRespuesta[errores].join() + ' ';
    }
    return mensajesError;
}

export { reduceErrorMessages, apiGet, apiPost, getCsrfCookie };
