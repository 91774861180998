import React from 'react';
import ResponsiveTable from 'components/tabla/ResponsiveTable'
import SpanWithTooltip from 'components/SpanWithTooltip';


export default function TablaFormularios({formularios}){

    const headers = ['Paciente', 'DNI', 'Fecha', 'Anestesista', 'Concluido'];

    const mapColumnas = {
        Paciente: 'paciente_nombre',
        DNI: 'paciente_dni',
        Fecha: 'fecha_de_consulta',
        Anestesista: 'anestesista',
        Concluido: 'concluido'
    };
    return (
        <ResponsiveTable
            headers={headers}
            rows={formularios}
            emptyText="No se encontraron formularios"
            renderRow={(col, formulario) =><SpanWithTooltip text={formulario[mapColumnas[col]]} />}
        />
    );
}
