import { apiPost } from "api/configApi";

export default function useEliminarGrupo({familiar, onDelete, onError}){
    const familiarBackUp = familiar;

    const eliminar = () => {
        onDelete(familiar.id);
        apiPost(`api/eliminarFamiliar/${familiar.id}`)
            .catch(() => onError(familiarBackUp))
    };

    return eliminar;
}
