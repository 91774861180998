import React from 'react';

const TemaContext = React.createContext({
    primario: '#034ea2',
    secundario: '#cc3333',
    background: '#e8eaed',
    fontColor: 'white'
});

export default TemaContext;

