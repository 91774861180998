import React from 'react';
import LayoutConMenuLateral from "layouts/LayoutConMenuLateral";
import SectionWithTitle from "components/SectionWithTitle";
import MenuCard from 'components/configuracion/MenuCard';

export default function PagePanel() {

    const opciones =[
        {id:'1',path:'administracion/configuracion/configMedicoTurno',title:'Restricción de Turnos Online para Médicos',text:'Aqui podrá restringir la generación de Turnos Online para determinados Médicos',icon:'pi pi-calendar-plus'},
        {id:'2',path:'administracion/configuracion/configMedicoEdad',title:'Configuración de Edades Admisibles',text:'Configuración de edades admisibles por prestadores para poder otorgar turnos',icon:'pi pi-sliders-h'},
        {id:'3',path:'administracion/configuracion/configMedicosOs',title:'Restricción de Obras Sociales para Médicos',text:'Establecer qué Médicos no permiten Turnos con ciertas Obras Sociales',icon:'pi pi-user'},
        {id:'4',path:'administracion/configuracion/configInstitucion',title:'Obras Sociales Admitidas',text:'Establecer qué Obras Sociales se pueden utilizar en cada institución',icon:'pi pi-building'},
        {id:'5',path:'administracion/configuracion/configMedicoPrestacion',title:'Restricción de Prestaciones para Médicos',text:'Configuración de Médicos que no permiten generar turnos para determinadas Prestaciones',icon:'pi pi-user'},
        {id:'6',path:'administracion/montosPrestacion',title:'Montos Particulares',text:'Aqui podrá configurar los montos de las prestaciones',icon:'pi pi-dollar'},
        {id:'7',path:'estadistica',title:'Estadisticas',text:'Aqui podrá ver las estadisticas correspondientes al Portal',icon:'pi pi-chart-bar'},
        {id:'8',path: 'administracion/configuracion/restriccionTurnoPorMes',title: 'Restricción de turnos proximos por mes',text: 'Configuración de la restricción de turnos proximos, por usuario, en un mes',icon: 'pi pi-calendar-times'}
    
    ];

    return (
            <>
                <LayoutConMenuLateral>
                        <SectionWithTitle title="Panel de Configuracion" className="d-flex flex-column">
                            <div className="d-inline p-2">
                                {opciones.map(opcion=>(
                                    <MenuCard
                                        titulo={opcion.title}
                                        text={opcion.text}
                                        acceso={opcion.path}
                                        icon={opcion.icon}
                                        key={opcion.id}
                                    />
                                ))}
                            </div>
                        </SectionWithTitle>
                </LayoutConMenuLateral>
        </>
   )
}

