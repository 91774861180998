import React, { useContext, useState } from "react";
import useEstudios from "hooks/estudios/useEstudios";
import { Alert, Button, Modal } from "react-bootstrap";
import LayoutConMenuLateral from "layouts/LayoutConMenuLateral";
import Cargando from "components/Cargando";
import SectionWithTitle from "components/SectionWithTitle";
import SeleccionarPropietario from "pages/estudios/SeleccionarPropietario";
import useFamiliares from "hooks/turnos/useFamiliares";
import useProximosTurnos from "hooks/turnos/useProximosTurnos";
import AlertValidarFamiliar from "components/AlertValidarFamiliar";
import TablaEstudios from "components/estudios/TablaEstudios";
import TablaTurnos from "components/turnos/TablaTurnos";
import IfRender from "components/IfRender";
import url from "utils/url";
import TemaContext from "providers/Tema";
import { ThemeProvider } from "styled-components";
import TurnosExcedidosModal from "hooks/turnos/turnosExcedidosModal";

export default function SeccionConAccesoHome({ user }) {
    const d = new Date();
    d.setDate(d.getDate() - 90);
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

    const tema = useContext(TemaContext);

    const { cargandoFamiliares, familiares, errorFamiliares } = useFamiliares(
        user.id
    );
    const [documento, setDocumento] = useState(user.dni);
    const { cargandoTurnos, turnos, errorTurnos } = useProximosTurnos(
        documento,
        null
    );
    const [invalido, setInvalido] = useState(false);
    const [fechaInicio, setFechaInicio] = useState(`${ye}-${mo}-${da}`);
    const { estudios, cargando, error } = useEstudios(
        documento,
        fechaInicio,
        null
    );

    const [mostrarModal, setMostrarModal] = useState(false);
    const turnosPredefinidos = 3;

    const handleClose = () => {
        setMostrarModal(false);
    };

    return (
        <LayoutConMenuLateral>
            <IfRender
                condicion={error || errorFamiliares || errorTurnos}
                no={() => (
                    <div className="container-fluid">
                        <div className="row pt-2">
                            <IfRender condicion={familiares.length > 0}>
                                <div className="mb-2 col-sm-12 col-md">
                                    <SectionWithTitle
                                        title="Seleccionar Persona"
                                        className="mt-2"
                                    >
                                        <SeleccionarPropietario
                                            familiares={familiares}
                                            cargandoFamiliares={
                                                cargandoFamiliares
                                            }
                                            user={user}
                                            setDocumento={setDocumento}
                                            setInvalido={setInvalido}
                                            fechaInicio={fechaInicio}
                                            setFechaInicio={setFechaInicio}
                                            mostrarFecha={false}
                                        />
                                    </SectionWithTitle>
                                </div>
                            </IfRender>

                            <IfRender condicion={!invalido}>
                                <div className="mb-2 col-sm-12 col-md">
                                    <IfRender
                                        condicion={!cargandoTurnos}
                                        no={() => (
                                            <Cargando text="Cargando Turnos" />
                                        )}
                                    >
                                        <SectionWithTitle title="Próximos Turnos">
                                            <IfRender
                                                condicion={!cargandoTurnos}
                                                no={() => (
                                                    <Cargando text="Cargando formularios" />
                                                )}
                                            >
                                                <TablaTurnos
                                                    headers={[
                                                        "Fecha",
                                                        "Hora",
                                                        "Prestador",
                                                        "Servicio",
                                                        "Centro",
                                                        "Acción",
                                                    ]}
                                                    turnos={turnos}
                                                />
                                            </IfRender>
                                        </SectionWithTitle>
                                        <TurnosExcedidosModal
                                            mostrarModal={mostrarModal}
                                            handleClose={() =>
                                                setMostrarModal(false)
                                            }
                                        />
                                        {/* Condicion para mostrar el boton de Agendar Turno */}
                                        {turnos.length >= turnosPredefinidos ? (
                                            <ThemeProvider theme={tema}>
                                                <button
                                                    onClick={() =>
                                                        setMostrarModal(true)
                                                    }
                                                    className="my-2 text-uppercase btn w-100"
                                                    style={{
                                                        background:
                                                            tema.primario,
                                                        color: "white",
                                                    }}
                                                >
                                                    Agendar Turno
                                                </button>
                                            </ThemeProvider>
                                        ) : (
                                            <ThemeProvider theme={tema}>
                                                <a
                                                    href={url("misTurnos")}
                                                    className="my-2 text-uppercase btn w-100"
                                                    style={{
                                                        background:
                                                            tema.primario,
                                                        color: "white",
                                                    }}
                                                >
                                                    Agendar Turno
                                                </a>
                                            </ThemeProvider>
                                        )}
                                    </IfRender>
                                </div>
                            </IfRender>

                            <div className="mb-2 col-sm-12 col-md">
                                <IfRender
                                    condicion={!cargando}
                                    no={() => (
                                        <Cargando text="Cargando estudios" />
                                    )}
                                >
                                    <IfRender
                                        condicion={!invalido}
                                        no={() => (
                                            <AlertValidarFamiliar
                                                familiar={invalido}
                                                accion={"mostrar estudios"}
                                            />
                                        )}
                                    >
                                        <SectionWithTitle
                                            className="mb-2"
                                            title="Últimos Estudios"
                                        >
                                            <TablaEstudios
                                                headers={[
                                                    "Fecha",
                                                    "Estudio",
                                                    "Servicio",
                                                    "Estado",
                                                    "Descargar",
                                                    "Adjunto",
                                                ]}
                                                estudios={estudios}
                                            />
                                        </SectionWithTitle>
                                    </IfRender>
                                </IfRender>
                            </div>
                        </div>
                    </div>
                )}
            >
                <Alert variant="danger"> {error} </Alert>
            </IfRender>
        </LayoutConMenuLateral>
    );
}
