import React from 'react';

const TableEmpty = ({colSpan, emptyText}) => (
    <tbody>
        <tr>
            <td colSpan={colSpan}>
                <p align="center" className="w-100 py-2"> { emptyText } </p>
            </td>
        </tr>
    </tbody>
);

export default TableEmpty;
