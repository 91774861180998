import React, { useContext, useState } from 'react';
import FormGroup from 'components/FormGroup';
import FormCheck from 'components/FormCheck';
import Boton from 'styledComponents/Boton.style';
import { ThemeProvider } from 'styled-components';
import TemaContext from 'providers/Tema';
import useLogin from 'hooks/useLogin';
import { Link } from 'wouter';
import url from 'utils/url';
import { Col, Row } from 'react-bootstrap';
import SkeletonForm from 'components/SkeletonForm';
import { getParamFromUrl } from 'utils/url';
import IfRender from 'components/IfRender';

export default function LoginForm({isInterno}){
    let disabled = true;

    const endpoint = isInterno ? 'api/validarUsuarioHp' : 'api/login';

    const theme = useContext(TemaContext);
    const [name, setName] = useState(getParamFromUrl('tiene-usuario'));
    const [password, setPassword] = useState(null);
    const [recordado, setRecordado] = useState(true);
    const {validando, invalid, login} = useLogin(
        name, password, recordado, endpoint
    );

    const invalidMessage = isInterno ? 'Ingrese las credenciales del sistema HP y asegurese de tener un email registrado.' : 'Usuario o contraseña incorrectos.';

    const handleInputEmail = evt => setName(evt.target.value);

    const handleInputPassword = evt => setPassword(evt.target.value);

    const handleInputRecordado = () => setRecordado(!recordado);

    disabled = name && password ? false : true;


    const handleSubmit = evt => {
        evt.preventDefault();
        login();
    }

    return (
        <SkeletonForm
            invalid={invalid}
            validando={validando}
            handleSubmit={handleSubmit}
            invalidMessage={invalidMessage}
            validandoMessage ="Validando"
            render={() => (
            <>
                <FormGroup
                    label={isInterno ? 'Usuario' : 'Email'}
                    type={isInterno ? 'text' : 'email'}
                    placeholder={isInterno ? 'Ingrese su usuario' : 'Ingrese su email'}
                    name="name"
                    value={name}
                    onChange={handleInputEmail}
                    required>
                </FormGroup>
                <FormGroup
                    label="Contraseña"
                    type="password"
                    placeholder="Ingrese su contraseña"
                    name="password"
                    onChange={handleInputPassword}
                    required>
                </FormGroup>
                <Row>
                    <Col col={12} md={1}>
                        <FormCheck
                            name="recordar"
                            label="Recordarme"
                            checked={recordado}
                            onChange={handleInputRecordado}>
                        </FormCheck>
                    </Col>
                    <Col col={12} md={11} className="pr-md-4 text-md-right">
                        <IfRender condicion={!isInterno}>
                            <div>
                                {/*<Link href={url('registro')} className="mx-2">
                                    No tengo cuenta
                                </Link>*/}
                                <Link href={url('olvideContrasena')}>
                                    Olvidé mi contraseña
                                </Link>
                            </div>
                        </IfRender>
                    </Col>
                </Row>
                <ThemeProvider theme={theme}>
                    <Boton className="btn w-100 mt-2" disabled={disabled}>
                        Ingresar
                    </Boton>
                </ThemeProvider>
            </>
            )}

        />
    );
}
