import React, { useContext, useState } from 'react';
import SpanWithTooltip from 'components/SpanWithTooltip';
import { apiPost } from 'api/configApi';
import { useLocation } from 'wouter';
import url from 'utils/url';
import TemaContext from 'providers/Tema';
import Boton from 'styledComponents/Boton.style';
import IfRender from 'components/IfRender';

const mapColumnas = {
    Paciente: 'paciente',
    Centro : 'centro',
    Servicio: 'servicio',
    Fecha: 'fecha',
    Hora: 'hora',
    Estado: 'estado'
};

const FilaTurnoReceptado = ({col, turno}) => {
    const [creando, setCreando] = useState(false);
    const tema = useContext(TemaContext);
    const [, setLocation ] = useLocation();

    const crearSala = () => {
        setCreando(true);
        apiPost(`api/createVideoconsulta/${turno.dni_paciente}`)
            .then( () => {
                setCreando(false);
                setLocation(url('videoconsulta'));
            })
            .catch( error => {
                alert(error.response.mensaje)
            })
    }

    switch(col){
        case 'Acción':
            return(
                <Boton
                    className="btn"
                    onClick={crearSala}
                    disabled={creando}
                    theme={tema}
                >
                <IfRender condicion={creando} no={() => 'Atender'}>
                    Creando sala...
                </IfRender>
                </Boton>
            );
        default:
            return <SpanWithTooltip text={turno[mapColumnas[col]]} />
    }
}

export default FilaTurnoReceptado;
