import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export function useEstadisticaVistas(desde,hasta){
    const [estadisticasVistas, setEstadisticasVistas] = useState([]); 
    const [cargandoEstadisticasVistas, setCargandoEstadisticasVistas] = useState(true);
    const [errorEstadisticasVistas, setErrorEstadisticasVistas] = useState();

    const handleResponse = response =>{
        setEstadisticasVistas(response.data);
        setCargandoEstadisticasVistas(false);
    }

    useEffect(() =>  {
        setCargandoEstadisticasVistas(true);
        apiGet(`api/estadisticaVistas?desde=${desde}&hasta=${hasta}`)
            .then(handleResponse)
            .catch(errorEstadisticasVistas => {
                setErrorEstadisticasVistas("Error inesperado.");
                setCargandoEstadisticasVistas(false);
            });
    },[desde,hasta]);

    return {cargandoEstadisticasVistas, estadisticasVistas, errorEstadisticasVistas};
}