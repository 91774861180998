import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export function useEstadistica(desde,hasta){
    const [registros,setRegistros] = useState([]);
    const [cargando, setCargando] = useState();
    const [error, setError] = useState();

    const handleResponse = response =>{
        setRegistros(response.data);
        setCargando(false);
    }

    useEffect(() =>  {
        setCargando(true);
        apiGet(`api/estadistica?desde=${desde}&hasta=${hasta}`)
            .then(handleResponse)
            .catch(error => {
                setError("Error inesperado.");
                setCargando(false);
            })
    },[desde,hasta]);
    return {cargando, registros, error};
}

export function useHistorialEstadistica(desde, hasta, accion){

    const [posts, setPosts] = useState([]);
    const [cargando,setLoading] = useState(false);

    const handleResponse = response =>{
        setPosts(response.data);
        setLoading(false);
    }

    useEffect(() =>  {
        setLoading(true);
        apiGet(`api/estadistica/historial?desde=${desde}&hasta=${hasta}&accion=${accion}`)
            .then(handleResponse)
            .catch(error => {
                setLoading(false);
            });
    },[desde,hasta,accion]);

    return {cargando, posts};
}


export function useDetalleEstadistica(desde,hasta,accion){
    const [detalle, setDetalle] = useState([]); 
    const [cargando, setCargando] = useState();
    const [error, setError] = useState();

    const handleResponse = response =>{
        setDetalle(response.data);
        setCargando(false);
    }

    useEffect(() =>  {
        setCargando(true);
        apiGet(`api/estadistica/detalle?desde=${desde}&hasta=${hasta}&accion=${accion}`)
            .then(handleResponse)
            .catch(error => {
                setError("Error inesperado.");
                setCargando(false);
            });
    },[desde,hasta,accion]);

    return {cargando, detalle};
}
