import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useValidarRestriccionTurnoOnline(dniMedico){
    const [validacionR, setValidacionR] = useState([]);
    const [cargandoValidacionR, setCargandoValidacionR] = useState();
    const [errorValidacionR, setErrorValidacionR] = useState();
    const [urlMensaje,setUrlMensaje] = useState();

    const handleResponse = response =>{
        setValidacionR(response.data);
        setUrlMensaje('https://api.whatsapp.com/send?phone=54'+response.data.telefono);
        setCargandoValidacionR(false);
    }

    useEffect(() =>  {
        if(dniMedico && dniMedico !== 0){
            setCargandoValidacionR(true);
            apiGet(`api/turnos/ValidarRestriccionTurno/${dniMedico}`)
                .then(handleResponse)
                .catch(error => {
                    setErrorValidacionR("Error inesperado.");
                    setCargandoValidacionR(false);
                })
        }
    },[dniMedico]);

    // console.log(validacionR,urlMensaje);

    return {cargandoValidacionR, validacionR, errorValidacionR,urlMensaje};

}
