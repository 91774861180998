import { apiPost, getCsrfCookie, reduceErrorMessages } from "api/configApi";
import { useCallback, useState } from "react";

export default function useRegistroMedicoOS(){
    const [dniMedico, setDniMedico] = useState('');
    const [mutualId, setMutualId] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [fecha, setFecha] = useState('');
    const [registrando,setRegistrando] = useState(null);
    const [errors,setErrors]= useState(null);
    const [noRegistrando, setNoRegistrando] = useState(true);
    const data = {dniMedico,mutualId,descripcion,fecha};


    const handleRegistro = () =>{
        console.log(data);
       apiPost('api/guardarRestriccionMedicoObraSocial',data);
    }

    const handleRegistroResponse = response =>{
        setRegistrando(false);
        setNoRegistrando(false);
        setDniMedico('');
        setMutualId('');
        setDescripcion('');
        setFecha('');
        setTimeout(() => {
            window.location.reload(false);
        }, 100);

    }

    const handleErros = error => {
        setRegistrando(false);
        setErrors(reduceErrorMessages(error));
    }

    const handleOnChangeDniMedico = useCallback(evt =>{
        setErrors(null);
        setDniMedico(evt.target.value);
    },[setDniMedico]);

    const handleOnChangeMutualId = useCallback(evt =>{
        const { innerText } = evt.nativeEvent.target
        setErrors(null);
        setMutualId(evt.target.value);
    },[setMutualId]);

    const handleOnChangeDescripcion = useCallback(evt =>{
        setErrors(null);
        setDescripcion(evt.target.value);
    },[setDescripcion]);

    const handleOnChangeFecha = useCallback(evt =>{
        setErrors(null);
        setFecha(evt.target.value);
    },[setFecha]);

    const onSubmit = evt =>{
        evt.preventDefault();
        setRegistrando(true);
        getCsrfCookie()
                .then(handleRegistro)
                .then(handleRegistroResponse)
                .catch(handleErros);
    }

    return{
        registrando,
        errors,
        state:{
            dniMedico,
            mutualId,
            descripcion,
            fecha
        },
        controller:{
            handleOnChangeDniMedico,
            handleOnChangeMutualId,
            handleOnChangeDescripcion,
            handleOnChangeFecha,
            onSubmit
        },
        noRegistrando
    }
}
