import { apiGet } from "api/configApi";
import { useCallback, useEffect, useState } from "react";

export default function useFormulariosCompletos(url){
    const [formularios, setFormularios] = useState([])
    const [cargandoFormularios, setCargandoFormularios] = useState()
    const [errorFormulario, setErrorFormulario] = useState()
    const [pacientes, setPacientes] = useState([])

    const handleResponse = useCallback(response => {
        setFormularios(ordenarFormularios(response.data.data));
        let pacientesMap = response.data.data.map(item=>{
            return [item.paciente_dni,item]
        });
        var pacientesMapArr = new Map(pacientesMap)
        let pacientesUnicos = [...pacientesMapArr.values()];
        pacientesUnicos = ordenarPacientes(pacientesUnicos)
        setPacientes(pacientesUnicos)
        setCargandoFormularios(false);
    }, [setFormularios, setPacientes, setCargandoFormularios]);

    function ordenarFormularios(lista){
        lista.sort(function (a, b) {
            if (a.fecha_de_consulta > b.fecha_de_consulta) {
              return -1;
            }
            if (a.fecha_de_consulta < b.fecha_de_consulta) {
              return 1;
            }
            return 0;
          });
        return lista;
    }

    function ordenarPacientes(lista){
        lista.sort(function (a, b) {
            if (a.paciente_nombre > b.paciente_nombre) {
              return 1;
            }
            if (a.paciente_nombre < b.paciente_nombre) {
              return -1;
            }
            return 0;
          });
        return lista;
    }

    useEffect(() =>  {
        setCargandoFormularios(true);

        apiGet(`api/formulario/anestesistas/${url}`)
            .then(handleResponse)
            .catch(() => {
                setErrorFormulario("Error inesperado al buscar los formularios cargados.");
                setCargandoFormularios(false);
            })
    }, [handleResponse, url]);


    return { cargandoFormularios, formularios, errorFormulario, pacientes };

}
