import React from 'react';
import GrupoFamiliarItem from './GrupoFamiliarItem';

export default function Integrante({familiar, onDelete, onError}){
    return (
        <GrupoFamiliarItem
            familiar={familiar}
            onDelete={onDelete}
            onError={onError}
            dniProp="dni_titular"
            cargandoNombreLabel="Cargando nombre del titular..."
            labelBtnEliminar="Abandonar"
            preguntaAccionEliminar="¿Dejar de formar parte de este grupo?"
        />
    );
}
