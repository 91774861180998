import React from 'react';
import LayoutPrincipal from 'layouts/LayoutPrincipal';
import LoginLayout from 'layouts/LoginLayout';
import LoginForm from 'components/login/LoginForm';
import { Row } from 'react-bootstrap';
import { ReactComponent as WppIco} from 'icons/social/whatsapp.svg';

const icoStyle = {width: '30px', height: '30px'};

export default function LoginPage() {
    return (
        <LayoutPrincipal>
            <LoginLayout title="Iniciar sesión">
                <LoginForm isInterno={false}/>
                <Row className='mx-4 my-2'>
                    <a className="btn btn-link" href="https://api.whatsapp.com/send?phone=543764293795" target="_blank" rel="noopener noreferrer">
                        ¿No tienes usuario? Comunícate con nosotros <WppIco style={icoStyle}/>
                    </a>
                </Row>
            </LoginLayout>
        </LayoutPrincipal>
    );
}


