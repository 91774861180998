import React, { useContext, useState } from 'react';
import getIcon from 'constantes/IconosMenu';
import BotonMenuLateral from './BotonMenuLateral';
import TemaContext from 'providers/Tema';
import useOpcionesMenu from 'hooks/useOpcionesMenu';
import LoaderMenu from './LoaderMenu';
import IfRender from 'components/IfRender';

export default function NavBarLateral({children}){
    const { secundario } = useContext(TemaContext);
    const { cargandoOpciones, opciones } = useOpcionesMenu();
    const [ ultimoSeleccionado, setUltimoSeleccionado ] = useState(() => {
        const selected = sessionStorage.getItem('menu-selected');
        return selected ? parseInt(selected) : 1;
    });

    const handleClick = evt => {
        let element = evt.target;
        while(!element.dataset.index){
            element = element.parentElement;
        }
        const index = element.dataset.index;
        setUltimoSeleccionado(index);
        sessionStorage.setItem('menu-selected', index);
    }

    return (
        <nav style={{backgroundColor: secundario}} className='d-flex justify-content-center'>
            <ul className='list-unstyled d-flex flex-wrap flex-md-column'>
                <IfRender condicion={ !cargandoOpciones } no={ () => buildLoaderList(5) }>
                    {opciones.map(opcion => (
                        <BotonMenuLateral
                            title={opcion.nombre}
                            Ico={getIcon(opcion.ico)}
                            path={opcion.url}
                            index={opcion.id}
                            key={opcion.id}
                            selected={ultimoSeleccionado === opcion.id}
                            onClick={handleClick}
                            cantidad={opciones.length}
                        />
                    ))}
                </IfRender>
            </ul>
        </nav>
    );
}

function buildLoaderList(cantidad){
    const loaderList = [];
    let i = 1;
    while(i <= cantidad){
        loaderList.push(
            <div className="mx-4 my-3" key={i}>
                <LoaderMenu />
            </div>
        );
        i++;
    }
    return loaderList;
}
