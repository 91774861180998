import React, { useContext } from 'react';
import Boton from 'styledComponents/Boton.style';
import { ThemeProvider } from 'styled-components';
import FormGroup from 'components/FormGroup';
import TemaContext from 'providers/Tema';
import SkeletonForm from 'components/SkeletonForm';
import useMutuales from 'hooks/useMutuales';
import useCentrosAtencion from 'hooks/turnos/useCentrosAtencion';
import useRegistroInstitucionOS from "hooks/configuracion/RestricionInstitucionObraSocial/useRegistroInstitucionOS";
import ComboCentroDeAtencion from 'components/turnos/ComboCentroDeAtencion';
import DatalistMutuales from './DatalistMutuales';
import FormCheck from 'components/FormCheck';

export default function RegistroRestriccionInstitucion() {
    const {errors, registrando, state, controller} = useRegistroInstitucionOS();
    const {cargandoMutuales,mutuales,errorMutuales} = useMutuales();
    const {centrosDeAtencion, cargando, errorCentro} = useCentrosAtencion();
    const theme = useContext(TemaContext);
    return (
        <SkeletonForm
            invalid={errors}
            validando={registrando}
            handleSubmit={controller.onSubmit}
            invalidMessage={errors}
            validandoMessage ="Registrando"
            render={() => (
            <>
                <ComboCentroDeAtencion
                    centrosDeAtencion={centrosDeAtencion}
                    handleCentroChange={controller.handleOnChangeIdInstitucion}
                    cargando={cargando}
                    centro={state.idInstitucion}
                />
                <DatalistMutuales
                    mutuales={mutuales}
                    cargandoMutuales={cargandoMutuales}
                    handleMutualChange={controller.handleOnChangeMutualId}
                    mutual={state.mutualId}
                />
                <FormGroup
                    label="Fecha"
                    type="date"
                    placeholder="Seleccione una fecha"
                    name="fecha"
                    onChange={controller.handleOnChangeFecha}
                    value={state.fecha}
                    required={true}>
                </FormGroup>
                <FormGroup
                    label="Descripcion"
                    type="textarea"
                    placeholder="Ingrese una descripciom"
                    name="descripcion"
                    onChange={controller.handleOnChangeDescripcion}
                    value={state.descripcion}
                    required={false}>
                </FormGroup>
                <ThemeProvider theme={theme}>
                    <Boton className="btn btn-primary w-100 mt-2">
                        Guardar
                    </Boton>
                </ThemeProvider>
            </>
            )}
        />
    );
}
