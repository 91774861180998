import React, { useState } from 'react';
import ResponsiveTable from 'components/tabla/ResponsiveTable'
import IfRender from 'components/IfRender'
import Cargando from 'components/Cargando'
import Alert from 'components/Alert';
import { Container } from 'react-bootstrap';
import MensajeToast from 'components/MensajeToast';
import FilaProximoTurno from 'components/anestesista/FilaProximoTurno'

export default function ProximosTurnosPreanestesicos({
    cargandoProximosTurnos,
    proximosTurnosParaMostrar,
    errorProximosTurnos
}){

    const [copiado, setCopiado] = useState(false)
    return (
         <>
            <IfRender
                condicion={!errorProximosTurnos}
                no={() =>
                    <Container className="mb-2">
                        <Alert>{errorProximosTurnos}</Alert>
                    </Container>
                }>
                <IfRender
                    condicion={!cargandoProximosTurnos}
                    no={ () => <Cargando text="Cargando Proximos turnos"/> }
                >
                    <ResponsiveTable
                        headers={['Fecha', 'Paciente', 'DNI', 'Acción']}
                        rows={proximosTurnosParaMostrar}
                        renderRow={(col, proximoTurno) =>

                            <FilaProximoTurno
                                col={col}
                                proximoTurno={proximoTurno}
                                setCopiado={setCopiado}
                            /> }
                    />
                </IfRender>
            </IfRender>
            <IfRender condicion={copiado}>
                <MensajeToast
                    title="Enlace copiado"
                    mensaje="Se ha copiado el enlace al porta papeles"
                />
            </IfRender>
        </>
    );
}
