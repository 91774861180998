import TemaContext from 'providers/Tema';
import React, { useContext, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { ThemeProvider } from 'styled-components';
import Boton from 'styledComponents/Boton.style';
import FileUploader from '../FileUploader';
import Cargando from '../Cargando';
import useValidacionFotoDni from 'hooks/useValidarFotoDni';
import DniValidadoAlert from './DniValidadoAlert';
import ImagenDni from './ImagenDni';
import TooltipBoton from './TooltipBoton';
import IfRender from 'components/IfRender';

export default function ModalValidacionDni({
    onValidate,
    botonLabel,
    title,
    placeholderFrente,
    placeholderReverso,
    validadorUrl,
    tooltip = null
}){
    const tema = useContext(TemaContext);
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);

    const handleClose = () => {
        setShow(false);
        validado && onValidate();
    };

    const {
        validado,
        validacionMensaje,
        frente,
        reverso,
        onSelectFrente,
        fotoFrente,
        onSelectReverso,
        fotoReverso,
        validar,
        isDisabled,
        validando
    } = useValidacionFotoDni(validadorUrl);

    return (
        <>
        <ThemeProvider theme={tema}>
            <TooltipBoton mensaje={tooltip} show={!show}>
                <Boton className="btn w-100" onClick={handleShow}>
                    { botonLabel }
                </Boton>
            </TooltipBoton>
        </ThemeProvider>
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <IfRender
                    condicion={!validando}
                    no={() => <Cargando text="Validando D.N.I."/>}
                >
                    <>
                    <DniValidadoAlert
                        validado={validado}
                        validacionMensaje={validacionMensaje}
                    />
                    <Row>
                        <Col md={6}>
                            <FileUploader
                                className="mb-3"
                                required
                                placeholder={placeholderFrente}
                                labelExaminar="Buscar"
                                label="Frente"
                                nombreArchivo={frente && frente.name}
                                onSelect={onSelectFrente}
                                accept="image/*"
                            />
                            <ImagenDni
                                imagen={frente}
                                referencia={fotoFrente}
                                safeLabel="Frente"
                            />
                        </Col>
                        <Col md={6}>
                            <FileUploader
                                className="mb-3"
                                required
                                placeholder={placeholderReverso}
                                labelExaminar="Buscar"
                                label="Reverso"
                                nombreArchivo={reverso && reverso.name}
                                onSelect={onSelectReverso}
                                accept="image/*"
                            />
                            <ImagenDni
                                imagen={reverso}
                                referencia={fotoReverso}
                                safeLabel="Reverso"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ThemeProvider theme={tema}>
                                <Boton className="btn w-100"
                                    onClick={validar}
                                    disabled={isDisabled()}>
                                    Validar
                                </Boton>
                            </ThemeProvider>
                        </Col>
                    </Row>
                    </>
                </IfRender>
            </Modal.Body>
        </Modal>
        </>
    );
}


