
import { apiPost, getCsrfCookie, reduceErrorMessages } from "api/configApi";
import { useCallback, useState } from "react";

export default function useEditarRestriccionMedicoEdad({ restriccion }){
    const id = restriccion.id;
    const [edadMinima, setEdadMinima] = useState(restriccion.edadMinima);
    const [edadMaxima, setEdadMaxima] = useState(restriccion.edadMaxima);

    const [registrando,setRegistrando] = useState(null);
    const [errors,setErrors]= useState(null);
    const [noRegistrando, setNoRegistrando] = useState(true);

    const data = { id , edadMinima, edadMaxima};

    const handleRegistro = () =>{
       apiPost('api/editarRestriccionMedicosEdades', data)
       .then(handleRegistroResponse)
       .catch(handleErros);
    }

    const handleRegistroResponse = response =>{
        setRegistrando(false);
        setNoRegistrando(false);
        setEdadMinima(null);
        setEdadMaxima(null);
        setTimeout(() => {
            window.location.reload(false);
        }, 100);

    }

    const handleErros = error => {
        setRegistrando(false);
        setErrors(reduceErrorMessages(error));
    }

    const handleOnChangeEdadMinima = useCallback(evt =>{
        setErrors(null);
        setEdadMinima(parseInt(evt.target.value));
    },[setEdadMinima]);

    const handleOnChangeEdadMaxima = useCallback(evt =>{
        setErrors(null);
        setEdadMaxima(parseInt(evt.target.value));
    },[setEdadMaxima]);

    const onSubmit = evt =>{
        evt.preventDefault();
        setRegistrando(true);
        getCsrfCookie().then(handleRegistro);
    }

    return{
        registrando,
        errors,
        state:{
            edadMinima,
            edadMaxima,
        },
        controller:{
            handleOnChangeEdadMinima,
            handleOnChangeEdadMaxima,
            onSubmit
        },
        noRegistrando
    }
}