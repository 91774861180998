import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useTraerMontosPrestacion(){
    const [montos, setMontos] = useState([]);
    const [cargando, setCargando] = useState();
    const [error, setError] = useState();

    const handleResponse = response =>{
        setMontos(response.data);
        setCargando(false);
    }

    useEffect(() =>  {
        setCargando(true);
        apiGet(`api/montosPrestacion`)
            .then(handleResponse)
            .catch(error => {
                setError("Error inesperado.");
                setCargando(false);
            })
    },[]);


    return {cargando, montos, error};
}
