import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useProximosTurnosPreanestesicos(){
    const [proximosTurnos, setProximosTurnos] = useState([]);
    const [cargandoProximosTurnos, setCargandoProximosTurnos] = useState();
    const [errorProximosTurnos, setErrorProximosTurnos] = useState();
    const handleResponse = response =>{
        setProximosTurnos(response.data);
        setCargandoProximosTurnos(false);
    }

    useEffect(() =>  {
        setCargandoProximosTurnos(true);

        apiGet('api/formulario/anestesistas/turnosPorAnestesista/')
            .then(handleResponse)
            .catch(error => {
                setErrorProximosTurnos("Error al intentar traer los proximos turnos.");
                setCargandoProximosTurnos(false);
            })
    },[]);


    return {cargandoProximosTurnos, proximosTurnos, errorProximosTurnos};

}
