import { apiPost } from "api/configApi";

export default function useEliminarRestriccionEdad(restriccion, setMensajeEliminar, handleOnErrorEliminar){
    const restriccionBackUp = restriccion;
    const handleEliminarResponse = (respuesta) =>{
        setMensajeEliminar(respuesta.mensaje);
        setTimeout(() => {
            window.location.reload(false);
        }, 100)
    };

    const eliminar = () => {
        apiPost(`api/eliminarRestriccionMedicosEdades/${restriccion.id}`)
            .then((response) => handleEliminarResponse(response.data))
            .catch(error => handleOnErrorEliminar(restriccionBackUp, error.response.data.mensaje))
    };

    return eliminar;
}