import { apiPost } from "api/configApi";
import IfRender from "components/IfRender";
import SectionWithTitle from "components/SectionWithTitle";
import useTaerDatosTurno from "hooks/turnos/useTaerDatosTurno";
import useTaerPreparacion from "hooks/turnos/useTraerPreparacion";
import useTraerServicioId from "hooks/turnos/useTraerServicioId";
import useTraerDatosPersona from "hooks/useTraerDatosPersona";
import useTraerMontoPrestacion from "hooks/useTraerMontoPrestacion";
import LayoutConMenuLateral from "layouts/LayoutConMenuLateral";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import url from 'utils/url';
import { useLocation } from 'wouter';

export default function ConfirmarTurnoPage({params}){
//idTurno, propietario, prestacion, servicio,centro,mutual,medico,isConsalud,coseguro
    const {cargandoTurno, turno} = useTaerDatosTurno(params.idTurno);
    const {cargandoPersona,persona} = useTraerDatosPersona(params.propietario,'id');
    const {cargandoPreparacion,preparacion} = useTaerPreparacion(params.prestacion);
    const {cargandoServicio,servicio} = useTraerServicioId(params.servicio);

    const {monto} = useTraerMontoPrestacion(params.medico,params.servicio,params.prestacion,params.dni,params.idTurno,params.mutual,params.isConsalud,params.coseguro);
    const [mensaje,setMensaje]=useState();
    const [visible,setVisible] = useState(false);
    const [, setLocation] = useLocation();
    const handleSubmit = (e) =>{
        apiPost(`api/turnos/grabarTurno?idTurno=${params.idTurno}&dniPaciente=${persona.dni}&telefonoPaciente=${persona.telefonoCelular}&mutual=${params.mutual}&prestacion=${params.prestacion}`).then(handleResponse).catch(error => {
            setMensaje("Error inesperado.");
            setVisible(true);
        })
    }

    const handleResponse = (response) =>{
        if(response.data.mensaje[0].Respuesta === 'ok'){
            setVisible(true);
            setMensaje(response.data.mensaje[0].Mensaje)
       }else{
           setVisible(true);
           setMensaje('Ocurrio un error');
       }
    }

    const aceptar =()=>{
        setVisible(false)
        setLocation(url(`inicio`));
    }


    return(
        <LayoutConMenuLateral>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 p-1">
                            <SectionWithTitle title={'Informacion del Turno'}>
                                 <IfRender condicion={cargandoTurno} no={() =>
                                            <div className="d-flex flex-wrap">
                                                <div className="col-md-6 p-1">
                                                    <div className="bg-primary rounded-sm">
                                                        <p align="center" className="text-white font-size-13px">
                                                            Nombre Medico/a
                                                        </p>
                                                    </div>
                                                    <p align="center" className="font-size-13px border-bottom">
                                                        {turno.nombreCompleto}
                                                    </p>
                                                </div>
                                                <div className="col-md-3 p-1">
                                                    <div className="bg-primary rounded-sm">
                                                        <p align="center" className="text-white font-size-13px">
                                                            Fecha
                                                        </p>
                                                    </div>
                                                    <p align="center" className="font-size-13px border-bottom">
                                                        {turno.fecha}
                                                    </p>
                                                </div>
                                                <div className="col-md-3 p-1">
                                                    <div className="bg-primary rounded-sm">
                                                        <p align="center" className="text-white font-size-13px">
                                                            Hora
                                                        </p>
                                                    </div>
                                                    <p align="center" className="font-size-13px border-bottom">
                                                        {turno.hora}
                                                    </p>
                                                </div>
                                                <div className="col-md-4 p-1">
                                                    <div className="bg-primary rounded-sm">
                                                        <p align="center" className="text-white font-size-13px">
                                                            Servicio
                                                        </p>
                                                    </div>
                                                    <IfRender condicion={cargandoServicio} no={()=>
                                                        <p align="center" className="font-size-10px border-bottom">
                                                                {servicio.nombre}
                                                            </p>
                                                    }>
                                                    </IfRender>

                                                </div>
                                                <div className="col-md-4 p-1">
                                                    <div className="bg-primary rounded-sm">
                                                        <p align="center" className="text-white font-size-13px">
                                                            Prestacion
                                                        </p>
                                                    </div>
                                                    <IfRender condicion={cargandoPreparacion} no={()=>
                                                        <p align="center" className="font-size-10px border-bottom">
                                                                {preparacion.nombre}
                                                            </p>
                                                    }>
                                                    </IfRender>

                                                </div>
                                            </div>
                                        }>
                                        <p>no hay datos</p>
                                </IfRender>
                            </SectionWithTitle>
                        </div>
                        <div className="col-md-6 p-1">
                            <SectionWithTitle title={'Informacion del Paciente'}>
                                <IfRender condicion={cargandoPersona} no={() =>
                                            <div className="d-flex flex-wrap">
                                                <div className="col-md-6 p-1">
                                                    <div className="bg-primary rounded-sm">
                                                        <p align="center" className="text-white p-0 font-size-13px">
                                                            Nombre y Apellido
                                                        </p>
                                                    </div>
                                                    <p align="center" className="font-size-13px border-bottom">
                                                        {persona.apellidoNombre}
                                                    </p>
                                                </div>
                                                <div className="col-md-6 p-1">
                                                    <div className="bg-primary rounded-sm">
                                                        <p align="center" className="text-white p-0 font-size-13px">
                                                           DNI
                                                        </p>
                                                    </div>
                                                    <p align="center" className="font-size-13px border-bottom">
                                                        {persona.dni}
                                                    </p>
                                                </div>
                                                <div className="col-md-6 p-1">
                                                    <div className="bg-primary rounded-sm">
                                                        <p align="center" className="text-white p-0 font-size-13px">
                                                           Telefono
                                                        </p>
                                                    </div>
                                                    <p align="center" className="font-size-13px border-bottom">
                                                        {persona.telefonoCelular}
                                                    </p>
                                                </div>
                                            </div>
                                        }>
                                        <p>no hay datos</p>
                                </IfRender>
                            </SectionWithTitle>
                        </div>
                        <div className="col-md-12 p-1">
                            <SectionWithTitle title={'Confirmación'}>
                                <IfRender condicion={cargandoPreparacion} no={() =>
                                        <div>
                                           <label>¡Información importante!</label> {preparacion.preparacion ? <p><b>La siguiente prestación necesita la siguiente preparacion previa: </b> {preparacion.preparacion}</p> : <p>El siguiente servicio no requiere preparacion previa.</p> }
                                        </div>
                                    }>
                                        <p>El siguiente servicio no requiere preparacion previa.</p>
                                </IfRender>
                                {/* <p>{textoServicio ? textoServicio : " "}</p>
                                <p>{textoPractica ? textoPractica : ""}</p>*/}
                                <p>{monto ? monto : ""}</p>
                                <div className="clearfix">
                                    <button className="btn btn-primary float-right" onClick={handleSubmit}>Confirmar Turno</button>
                                </div>

                            </SectionWithTitle>
                        </div>
                        <div className='p-1 d-flex justify-content-start'>
                            <a data-toggle="tooltip" data-placement="top" title="Volver Atrás" href="javascript:history.back()" role={"button"} className='btn btn-danger float-right'><i className='bi bi-arrow-left'></i></a>
                    </div>
                    </div>
                    <div>
                        <Dialog header="Mensaje" visible={visible} style={{ width: '50vw' }} onHide={aceptar}>
                                <p className="m-0">
                                    {mensaje}
                                </p>
                                <div className="clearfix">
                                    <button className="btn btn-primary float-right" onClick={aceptar}>Aceptar</button>
                                </div>
                            </Dialog>
                    </div>
                </div>
        </LayoutConMenuLateral>
    )
}
