import Buscador from 'components/Buscador';
import SectionWithTitle from 'components/SectionWithTitle';
import React from 'react';

const SeccionBuscarPaciente = ({onSearch, onBuscando, onCancelar, url, seBusca}) => (
    <SectionWithTitle title={`Buscar ${seBusca}`}>
        <Buscador
            onSearch={onSearch}
            onBuscando={onBuscando}
            onCancelar={onCancelar}
            searchUrl={url}
            searchInputType="number"
            searchKey={'dni'}
            placeholder="Buscar por dni"
            helpText="Ingrese al menos 7 dígitos para realizar la búsqueda"
            conditionForSearch={search => search.length >= 7}
        />
    </SectionWithTitle>
);

export default SeccionBuscarPaciente;
