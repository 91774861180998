import React, { useContext } from 'react';
import TemaContext from 'providers/Tema';
import { SpinnerCargandoSmall } from 'styledComponents/SpinnerCargandoSmall.style';
import IfRender from './IfRender';

export default function Combo({
    opciones,
    onChange,
    cargando,
    opcionId = 'id',
    opcionValue,
    defaultValue,
    destacarOpcion = () => {} ,
    destacarOpcionClass,
    label,
    name = '',
    paddingClass = 'px-2',
    nombreClase = "form-control",
    onTodos,
    firstActive
}){
    const tema = useContext(TemaContext);
    const handleOnChange = evt => {
        if(evt.target.value === 'todos'){
            onTodos()
        }
        else{
            onChange(evt);
        }
    }
    return (
        <div className={`form-group ${paddingClass}`}>
            <label className="w-100 d-block">
                <span className="mr-1">
                    <IfRender condicion={cargando}>
                        <SpinnerCargandoSmall theme={tema} />
                    </IfRender>
                </span>
                { label }
                <select
                    name={name}
                    onChange={handleOnChange}
                    className={nombreClase}
                    required
                    value={defaultValue || 0}
                    disabled={cargando}
                >
                    <IfRender condicion={firstActive} no={() => 
                        <option value='0' disabled hidden>
                            Seleccionar...
                        </option>
                    }>
                    </IfRender>
                    
                    <IfRender condicion={onTodos}>
                        <option value="todos">TODOS</option>
                    </IfRender>
                    { opciones.map(opcion =>
                        <option
                            className={destacarOpcion(opcion) ? destacarOpcionClass : ''}
                            key={opcion[opcionId]}
                            value={opcion[opcionId]}
                        >
                            {opcion[opcionValue]}
                        </option>

                    ) }
                </select>
            </label>
        </div>
    );
}
