import FormGroup from 'components/FormGroup';
import InputTelefono from 'components/InputTelefono';
import MensajeExito from 'components/MensajeExito';
import SkeletonForm from 'components/SkeletonForm';
import useRegisterPacienteAdministracion from 'hooks/useRegisterPacienteAdministracion';
import { PacienteContext } from 'providers/PacienteProvider';
import TemaContext from 'providers/Tema';
import React, { useContext } from 'react';
import { Alert } from 'react-bootstrap';
import { ThemeProvider } from 'styled-components';
import Boton from 'styledComponents/Boton.style';

export default function RegistroPacienteAdministracionForm({onRegister}){
    const tema = useContext(TemaContext);
    const { paciente } = useContext(PacienteContext);
    const { dni, email, telefono } = paciente;

    const {
        controller, failed, registrando, errors, state, noRegistrado
    } =
    useRegisterPacienteAdministracion(
        {dni, telefono, email, onRegister}
    );

    const emailValue = email && !state.email ? email : state.email;
    const telefonoValue = telefono && !state.telefono ? telefono : state.telefono;

    const handleSubmit = evt => {
        evt.preventDefault();
        controller.sendRequest();
    }

    return (
        <SkeletonForm
            invalid={failed}
            validando={registrando}
            handleSubmit={handleSubmit}
            invalidMessage={errors}
            validandoMessage="Registrando"
            render={() => {
                if(noRegistrado){
                    return (
                        <>
                        <FormGroup
                            label="Email"
                            type="email"
                            placeholder="Ingrese el email del paciente"
                            name="email"
                            onChange={controller.handleOnChange}
                            value={emailValue}
                            required={true}>
                        </FormGroup>
                        <InputTelefono
                            onChange={controller.handleOnChange}
                            value={telefonoValue}
                            placeholder="Ingrese el teléfono del paciente"
                            required={true}
                            name="telefono"
                            readOnly={true}
                            id="input-registro-telefono">
                        </InputTelefono>
                        <Alert variant="warning">
                            El campo teléfono es de solo lectura. Si el paciente indica que es incorrecto, deberá corregirlo en empadronamiento
                        </Alert>
                        <ThemeProvider theme={tema}>
                            <Boton className="btn mt-2 w-100" >
                                Registrar
                            </Boton>
                        </ThemeProvider>
                        </>
                    );
                }
                return <MensajeExito mensaje="Paciente registrado exitosamente"/>;
            }}
        />
    );
}
