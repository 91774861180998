import React from 'react';
import LayoutPrincipal from 'layouts/LayoutPrincipal';
import Combo from 'components/Combo';
import SectionWithTitle from 'components/SectionWithTitle';
import { Container, Row, Col  } from 'react-bootstrap';
import Alert from 'components/Alert';
import IfRender from 'components/IfRender';
import useAtencion from 'hooks/telemedicina/atencion/useAtencion';
import Cargando from 'components/Cargando';
import ResponsiveTable from 'components/tabla/ResponsiveTable';
import FilaTurnoReceptado from 'components/telemedicina/atencion/FilaTurnoReceptado';

const HomeMedicoPage = () => {
    const { state, controller } = useAtencion();
    return (
        <LayoutPrincipal>
            <Container className="mt-4">
                <SectionWithTitle title="Filtros">
                    <Row>
                        <Col md={6}>
                            <IfRender
                                condicion={!state.error}
                                no={() =>
                                    <Container className="mb-2">
                                        <Alert>{state.error}</Alert>
                                    </Container>
                                }>
                                <Combo
                                    opciones={state.centros}
                                    label="Centros de atención"
                                    opcionValue="nombre"
                                    defaultValue={state.centroSeleccionado}
                                    cargando={state.cargandoCentros}
                                    onChange={controller.handleSelectCentro}
                                />
                            </IfRender>
                        </Col>
                        <Col md={6}>
                            <IfRender
                                condicion={!state.error}
                                no={() =>
                                    <Container className="mb-2">
                                        <Alert>{state.error}</Alert>
                                    </Container>
                                }>
                                <Combo
                                    opciones={state.servicios}
                                    label="Servicios"
                                    opcionValue="nombre"
                                    defaultValue={state.servicioSeleccionado}
                                    cargando={state.cargandoServicios}
                                    onChange={controller.handleSelectServicio}
                                />
                            </IfRender>
                        </Col>
                    </Row>
                </SectionWithTitle>
                <SectionWithTitle title="Turnos pendientes" className="my-2">
                    <IfRender
                        condicion={!state.cargandoTurnos}
                        no={ () => <Cargando text="Cargando turnos"/> }
                    >
                        <ResponsiveTable
                            headers={['Paciente', 'Centro', 'Servicio', 'Fecha', 'Hora', 'Estado', 'Acción']}
                            rows={state.turnos}
                            renderRow={(col, turno) => <FilaTurnoReceptado col={col} turno={turno} /> }
                        />
                    </IfRender>
                </SectionWithTitle>
            </Container>
        </LayoutPrincipal>
    );
}

export default HomeMedicoPage;
