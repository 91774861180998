import React, { useContext } from 'react';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import Cargando from 'components/Cargando';
import Alert from 'components/Alert';
import useBuscar from 'hooks/useBuscar';
import Boton from 'styledComponents/Boton.style';
import { ThemeProvider } from 'styled-components';
import TemaContext from 'providers/Tema';
import IfRender from './IfRender';

export default function Buscador(
    {
        onSearch,
        onBuscando = () => {},
        onCancelar,
        searchUrl,
        searchKey,
        helpText,
        conditionForSearch,
        placeholder,
        searchInputType,
        filters = {}
    }
){
    const { state, controller } = useBuscar({
        onSearch,
        conditionForSearch,
        onBuscando,
        onCancelar,
        searchUrl,
        searchKey,
        filters
    });

    const tema = useContext(TemaContext);
    return (
        <>
            <InputGroup className="px-2 py-0">
                <FormControl
                    value={state.search}
                    type={searchInputType}
                    placeholder={placeholder}
                    onChange={controller.handleOnChange}
                    onKeyPress={controller.handleOnKeyPress}/>
                <InputGroup.Append>
                    <ThemeProvider theme={tema}>
                        <Boton outline className="btn" onClick={controller.buscar} disabled={state.disable}>
                            Buscar
                        </Boton>
                        <Boton outline secondary className="btn btn-danger" onClick={controller.cancelar} >
                            Cancelar
                        </Boton>
                    </ThemeProvider>
                </InputGroup.Append>
            </InputGroup>
            <Form.Text className="text-muted mx-2 my-0">
                {helpText}
            </Form.Text>
            <hr/>
            <IfRender condicion={state.buscando}>
                <Cargando text="Buscando paciente" />
            </IfRender>
            <IfRender condicion={state.error}>
                <Alert>{ state.error }</Alert>
            </IfRender>
        </>
    );
}
