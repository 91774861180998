import React, { useContext, useState } from "react";
import LayoutConMenuLateral from "layouts/LayoutConMenuLateral";
import SectionWithTitle from "components/SectionWithTitle";
import TemaContext from "providers/Tema";
import Boton from "styledComponents/Boton.style";
import useCentrosAtencion from "hooks/turnos/useCentrosAtencion";
import useServiciosPorCentro from "hooks/turnos/useServiciosPorCentro";
import usePrestaciones from "hooks/turnos/usePrestaciones";
import ComboCentroDeAtencion from "components/turnos/ComboCentroDeAtencion";
import ComboServiciosPorCentro from "components/turnos/ComboServiciosPorCentro";
import ComboPrestaciones from "components/turnos/ComboPrestaciones";
import PropietarioDelTurno from "components/turnos/PropietarioDelTurno";
// import ConfirmDialog from 'components/ConfirmDialog';
// import useTraerPrestacionCobrable from 'hooks/pagos/useTraerPrestacionCobrable'
import url from "utils/url";
import { useLocation } from "wouter";
import IfRender from "components/IfRender";
import Cargando from "components/Cargando";
import useMedicosPorServicio from "hooks/turnos/useMedicos";
import ComboMedicos from "components/turnos/ComboMedicos";
import useValidarRestriccionObraSocial from "hooks/turnos/useValidarRestriccionObraSocial";
import useValidarRestriccionMedico from "hooks/turnos/useValidarRestriccionMedicoMutual";
import useTraerResticcionEdad from "hooks/configuracion/RestriccionMedicoTurnoOnline/useTraerResticcionEdad";
import useValidarRestriccionTurnoOnline from "hooks/turnos/useValidarRestriccion";
import useValidarAccesoServicios from "hooks/turnos/useValidarAccesoServicios";
import useProximosTurnos from "hooks/turnos/useProximosTurnos";
import TurnosExcedidosModal from "hooks/turnos/turnosExcedidosModal";
import useFamiliares from "hooks/turnos/useFamiliares";

export default function MisTurnosPage(user) {
    const d = new Date();
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    const tema = useContext(TemaContext);
    const { centrosDeAtencion, cargando, errorCentro } = useCentrosAtencion();
    const [centro, setCentro] = useState(0);
    const [servicio, setServicio] = useState(0);
    const [idPersona, setIdPersona] = useState(-1);
    const [mutual, setMutual] = useState(null);

    const [isConsalud, setIsConsalud] = useState(false);
    const { cargandoAccesoServicio, accesoServicio, errorAccesoServicio } =
        useValidarAccesoServicios(idPersona, servicio, mutual);

    const [prestacionHp, setPrestacionHp] = useState(0);
    const [medico, setMedico] = useState(0);
    const fecha = `${ye}-${mo}-${da}`;
    const { cargandoServicios, serviciosPorCentro, errorServicio } =
        useServiciosPorCentro(centro);
    const { cargandoPrestaciones, prestaciones, errorPrestaciones } =
        usePrestaciones(centro, servicio, medico);
    const { medicos, cargandoMedicos, errorMedicos } =
        useMedicosPorServicio(servicio);
    // const {cargandoPrestacion, prestacion ,errorPrestacion} = useTraerPrestacionCobrable(prestacionHp, centro);

    const { cargandoFamiliares, familiares, errorFamiliares } = useFamiliares(
        user.id
    );
    const [documento, setDocumento] = useState(user.dni);

    const { cargandoValidacionR, validacionR, errorValidacionR, urlMensaje } =
        useValidarRestriccionTurnoOnline(medico);

    const { cargandoValidacionEdad, validacionEdad, errorValidacionEdad } =
        useTraerResticcionEdad(medico, idPersona);

    const { cargandoValidacionOS, validacionOS, errorValidacionOS } =
        useValidarRestriccionObraSocial(mutual, centro);
    const {
        cargandoValidacionMedicoRestriccionMutual,
        validacionMedicoRestriccionMutual,
        errorValidacionMedicoRestriccionMutual,
    } = useValidarRestriccionMedico(mutual, medico, fecha);
    const [error, setError] = useState(false);
    const [, setLocation] = useLocation();
    //const [visible, setVisible] = useState(false);
    const disabled =
        idPersona !== -1 &&
        mutual &&
        centro !== 0 &&
        servicio &&
        medico &&
        prestacionHp &&
        // !cargandoPrestacion ? false : true &&
        !cargandoValidacionR
            ? false
            : true && !cargandoValidacionOS
            ? false
            : true && !cargandoValidacionMedicoRestriccionMutual
            ? false
            : true;

    const [mostrarModal, setMostrarModal] = useState(false);

    const handleClose = () => {
        setMostrarModal(false);
    };

    const handleSubmit = (e) => {
        redireccionar(e);
    };

    function redireccionar(e) {
        setLocation(
            url(
                `seleccionarTurno/${idPersona}/${mutual}/${centro}/${servicio}/${prestacionHp}/${medico}/${isConsalud}/${accesoServicio.coseguro}`
            )
        );
    }

    const handleCentroChange = (e) => {
        setCentro(e.target.value);
        setServicio(null);
        setMedico(0);
        setPrestacionHp(0);
    };

    const handleServicioChange = (e) => {
        setServicio(e.target.value);
        setMedico(0);
        setPrestacionHp(0);
    };

    const handleMedicoChange = (e) => {
        setMedico(e.target.value);
        setPrestacionHp(0);
    };

    const handlePrestacionChange = (e) => {
        setPrestacionHp(e.target.value);
        // const prestacion = prestaciones.find(p => e.target.value === p.id)
    };

    if (
        errorCentro ||
        errorServicio ||
        errorPrestaciones ||
        errorMedicos ||
        // errorPrestacion ||
        errorValidacionR ||
        errorValidacionOS ||
        errorValidacionMedicoRestriccionMutual
    ) {
        setError(true);
    }

    return (
        <LayoutConMenuLateral>
            <TurnosExcedidosModal
                mostrarModal={mostrarModal}
                handleClose={handleClose}
            />
            <div className="container-fluid">
                <div className="row pt-2">
                    <div className="col-12 col-md-6 ">
                        <SectionWithTitle title="¿Para quién es el turno?">
                            <PropietarioDelTurno
                                idPersona={idPersona}
                                setIdPersona={setIdPersona}
                                setError={setError}
                                mutual={mutual}
                                setMutual={setMutual}
                                setIsConsalud={setIsConsalud}
                                setCentro={setCentro}
                                setServicio={setServicio}
                                setMedico={setMedico}
                                setPrestacionHp={setPrestacionHp}
                            />
                        </SectionWithTitle>
                    </div>
                    <div className="col-12 col-md-6">
                        <IfRender condicion={mutual}>
                            <SectionWithTitle title="Solicitar turno">
                                <div>
                                    <ComboCentroDeAtencion
                                        centrosDeAtencion={centrosDeAtencion}
                                        handleCentroChange={handleCentroChange}
                                        cargando={cargando}
                                        centro={centro}
                                    />
                                    <IfRender condicion={centro != 0}>
                                        <IfRender
                                            condicion={!cargandoValidacionOS}
                                            no={() => (
                                                <Cargando text="Validando." />
                                            )}
                                        >
                                            <IfRender
                                                condicion={
                                                    validacionOS || isConsalud
                                                }
                                                no={() => (
                                                    <p className="p-1 text-center alert-danger">
                                                        De momento obra social
                                                        seleccionada no permite
                                                        sacar turnos online
                                                    </p>
                                                )}
                                            >
                                                <>
                                                    <ComboServiciosPorCentro
                                                        serviciosPorCentro={
                                                            serviciosPorCentro
                                                        }
                                                        handleServicioChange={
                                                            handleServicioChange
                                                        }
                                                        cargandoServicios={
                                                            cargandoServicios
                                                        }
                                                        servicio={servicio}
                                                    />
                                                    <IfRender
                                                        condicion={servicio}
                                                    >
                                                        <IfRender
                                                            condicion={
                                                                !cargandoAccesoServicio
                                                            }
                                                            no={() => (
                                                                <Cargando text="Validando." />
                                                            )}
                                                        >
                                                            <IfRender
                                                                condicion={
                                                                    isConsalud &&
                                                                    parseInt(
                                                                        accesoServicio.accede
                                                                    ) === 0
                                                                }
                                                                no={() => (
                                                                    <>
                                                                        <ComboMedicos
                                                                            medicos={
                                                                                medicos
                                                                            }
                                                                            handleMedicoChange={
                                                                                handleMedicoChange
                                                                            }
                                                                            cargandoMedicos={
                                                                                cargandoMedicos
                                                                            }
                                                                            medico={
                                                                                medico
                                                                            }
                                                                        />
                                                                        <IfRender
                                                                            condicion={
                                                                                medico !=
                                                                                    0 &&
                                                                                medico !=
                                                                                    null
                                                                            }
                                                                            no={() => (
                                                                                <div>
                                                                                    <ComboPrestaciones
                                                                                        prestaciones={
                                                                                            prestaciones
                                                                                        }
                                                                                        cargandoPrestaciones={
                                                                                            cargandoPrestaciones
                                                                                        }
                                                                                        setPrestacionHp={
                                                                                            setPrestacionHp
                                                                                        }
                                                                                        handlePrestacionChange={
                                                                                            handlePrestacionChange
                                                                                        }
                                                                                        prestacion={
                                                                                            prestacionHp
                                                                                        }
                                                                                    />
                                                                                    <IfRender
                                                                                        condicion={
                                                                                            prestacionHp !=
                                                                                            0
                                                                                        }
                                                                                    >
                                                                                        <Boton
                                                                                            className="btn w-100 mt-2"
                                                                                            disabled={
                                                                                                disabled
                                                                                            }
                                                                                            theme={
                                                                                                tema
                                                                                            }
                                                                                            onClick={
                                                                                                handleSubmit
                                                                                            }
                                                                                        >
                                                                                            Solicitar
                                                                                            turno
                                                                                        </Boton>
                                                                                    </IfRender>
                                                                                </div>
                                                                            )}
                                                                        >
                                                                            <IfRender
                                                                                condicion={
                                                                                    !cargandoValidacionEdad
                                                                                }
                                                                                no={() => (
                                                                                    <Cargando text="Validando..." />
                                                                                )}
                                                                            >
                                                                                <IfRender
                                                                                    condicion={
                                                                                        validacionEdad ==
                                                                                        0
                                                                                    }
                                                                                    no={() => (
                                                                                        <p className="p-1 text-center alert-danger">
                                                                                            Edad
                                                                                            fuera
                                                                                            del
                                                                                            rango
                                                                                            permitido
                                                                                            por
                                                                                            el
                                                                                            profesional
                                                                                        </p>
                                                                                    )}
                                                                                >
                                                                                    <IfRender
                                                                                        condicion={
                                                                                            !cargandoValidacionMedicoRestriccionMutual
                                                                                        }
                                                                                        no={() => (
                                                                                            <Cargando text="Validando." />
                                                                                        )}
                                                                                    >
                                                                                        <IfRender
                                                                                            condicion={
                                                                                                validacionMedicoRestriccionMutual.permite ===
                                                                                                "1"
                                                                                            }
                                                                                            no={() => (
                                                                                                <>
                                                                                                    <input
                                                                                                        type="hidden"
                                                                                                        name="ExtIdPersona"
                                                                                                        value={
                                                                                                            idPersona
                                                                                                        }
                                                                                                        required
                                                                                                    />
                                                                                                    <IfRender
                                                                                                        condicion={
                                                                                                            !error
                                                                                                        }
                                                                                                        no={() => (
                                                                                                            <p className="ml-2">
                                                                                                                {" "}
                                                                                                                *Ha
                                                                                                                ocurrido
                                                                                                                un
                                                                                                                error,
                                                                                                                por
                                                                                                                favor
                                                                                                                vuelva
                                                                                                                a
                                                                                                                intentarlo
                                                                                                                en
                                                                                                                un
                                                                                                                momento.
                                                                                                            </p>
                                                                                                        )}
                                                                                                    >
                                                                                                        <IfRender
                                                                                                            condicion={
                                                                                                                !cargandoValidacionR
                                                                                                            }
                                                                                                            no={() => (
                                                                                                                <Cargando text="Validando." />
                                                                                                            )}
                                                                                                        >
                                                                                                            <IfRender
                                                                                                                condicion={
                                                                                                                    validacionR
                                                                                                                }
                                                                                                                no={() => (
                                                                                                                    <div>
                                                                                                                        <ComboPrestaciones
                                                                                                                            prestaciones={
                                                                                                                                prestaciones
                                                                                                                            }
                                                                                                                            cargandoPrestaciones={
                                                                                                                                cargandoPrestaciones
                                                                                                                            }
                                                                                                                            setPrestacionHp={
                                                                                                                                setPrestacionHp
                                                                                                                            }
                                                                                                                            handlePrestacionChange={
                                                                                                                                handlePrestacionChange
                                                                                                                            }
                                                                                                                            prestacion={
                                                                                                                                prestacionHp
                                                                                                                            }
                                                                                                                        />
                                                                                                                        <IfRender
                                                                                                                            condicion={
                                                                                                                                prestacionHp
                                                                                                                            }
                                                                                                                        >
                                                                                                                         <Boton
                                                                                                                                className="btn w-100 mt-2"
                                                                                                                                disabled={
                                                                                                                                    disabled
                                                                                                                                }
                                                                                                                                theme={
                                                                                                                                    tema
                                                                                                                                }
                                                                                                                                onClick={
                                                                                                                                    handleSubmit
                                                                                                                                }
                                                                                                                            >
                                                                                                                                Solicitar
                                                                                                                                turno
                                                                                                                            </Boton>
                                                                                                                        </IfRender>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            >
                                                                                                                <p className="p-1 ml-2">
                                                                                                                    No
                                                                                                                    Permite
                                                                                                                    Turnos
                                                                                                                    Online.
                                                                                                                    Solicitar
                                                                                                                    al:{" "}
                                                                                                                    <a
                                                                                                                        href={
                                                                                                                            urlMensaje
                                                                                                                        }
                                                                                                                        target="_blank"
                                                                                                                        rel="noopener noreferrer"
                                                                                                                    >
                                                                                                                        <i className="pi pi-whatsapp">
                                                                                                                            {" "}
                                                                                                                            {
                                                                                                                                validacionR.telefono
                                                                                                                            }{" "}
                                                                                                                        </i>
                                                                                                                    </a>
                                                                                                                </p>
                                                                                                            </IfRender>
                                                                                                        </IfRender>
                                                                                                    </IfRender>
                                                                                                </>
                                                                                            )}
                                                                                        >
                                                                                            <IfRender
                                                                                                condicion={
                                                                                                    validacionMedicoRestriccionMutual.descripcion
                                                                                                }
                                                                                                no={() => (
                                                                                                    <p className="p-1 ml-2">
                                                                                                        No
                                                                                                        Permite
                                                                                                        sacar
                                                                                                        turnos
                                                                                                        online
                                                                                                        con
                                                                                                        la
                                                                                                        obra
                                                                                                        social
                                                                                                        seleccionada.
                                                                                                    </p>
                                                                                                )}
                                                                                            >
                                                                                                <p className="p-1 ml-2">
                                                                                                    No
                                                                                                    Permite
                                                                                                    sacar
                                                                                                    turnos
                                                                                                    online
                                                                                                    con
                                                                                                    la
                                                                                                    obra
                                                                                                    social
                                                                                                    seleccionada.{" "}
                                                                                                    {
                                                                                                        validacionMedicoRestriccionMutual.descripcion
                                                                                                    }
                                                                                                </p>
                                                                                            </IfRender>
                                                                                        </IfRender>
                                                                                    </IfRender>
                                                                                </IfRender>
                                                                            </IfRender>
                                                                        </IfRender>
                                                                    </>
                                                                )}
                                                            >
                                                                <p className="p-1 text-center alert-danger">
                                                                    {
                                                                        accesoServicio.respuesta
                                                                    }
                                                                </p>
                                                            </IfRender>
                                                        </IfRender>
                                                        <IfRender
                                                            condicion={
                                                                !isConsalud
                                                            }
                                                        ></IfRender>
                                                    </IfRender>
                                                </>
                                            </IfRender>
                                        </IfRender>
                                    </IfRender>
                                </div>
                            </SectionWithTitle>
                        </IfRender>
                    </div>
                </div>
                <div className="p-1 d-flex justify-content-start">
                    <a
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Volver Atrás"
                        href="javascript:history.back()"
                        role={"button"}
                        className="btn btn-danger float-right"
                    >
                        <i className="bi bi-arrow-left"></i>
                    </a>
                </div>
            </div>
            {/* <Dialog header="Alerta" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                    <p className="m-0">
                        No puede solicitar el turno por que esta fuera del rango de edad permitida por el médico.
                    </p>
                </Dialog> */}
        </LayoutConMenuLateral>
    );
}
