import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useMutuales(){
    const [mutuales, setMutuales] = useState([]);
    const [cargandoMutuales, setCargandoMutuales] = useState(false);
    const [errorMutuales, setErrorMutuales] = useState();

    const handleResponse = response =>{
        setMutuales(response.data);
        setCargandoMutuales(false);
    }

    useEffect(() => {
        setCargandoMutuales(true);
        apiGet('api/mutuales').then(handleResponse).catch(error => {
            setErrorMutuales("Error inesperado.");
            setCargandoMutuales(false);
        })
    }, []);

    return {cargandoMutuales, mutuales,errorMutuales};
}
