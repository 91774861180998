import TemaContext from 'providers/Tema';
import { ThemeProvider } from 'styled-components';
import React, { useContext } from 'react';
import { SpinnerCargando } from 'styledComponents/SpinnerCargando.style';

export default function Cargando({text}){
    const tema = useContext(TemaContext);
    return (
        <div className="d-flex justify-content-center align-items-center m-4 flex-column">
            <h4 style={{color: tema.primario}}>
                {text}
            </h4>
            <ThemeProvider theme={tema}>
                <SpinnerCargando />
            </ThemeProvider>
        </div>
    );
}
