import React, { useState } from 'react';
import LayoutPrincipal from 'layouts/LayoutPrincipal';
import SectionWithTitle from 'components/SectionWithTitle';
import { Container } from 'react-bootstrap';
import FormGroup from 'components/FormGroup';
import IfRender from 'components/IfRender';
import Cargando from 'components/Cargando';
import Alert from 'components/Alert';
import { Row, Col } from 'react-bootstrap';
import useEstudios from 'hooks/estudios/useEstudios'
import TablaEstudios from 'components/estudios/TablaEstudios';

export default function HomeControlDeInforme() {
    let treintaDias = 1000 * 60 * 60 * 24 * 30
    let hoy = new Date();
    let resta = hoy.getTime() - treintaDias;
    const d = new Date(resta);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    const [documento, setDocumento] = useState(null)
    const [fechaInicio, setFechaInicio] = useState(`${ye}-${mo}-${da}`)
    const {estudios, cargando, error} = useEstudios(documento, fechaInicio, null);

    const handleBuscarPorDni = (e) =>{
        if(e.target.value.length >= 7){
            setDocumento(e.target.value)
        }
    }

    const handleBuscarPorFecha = (e) =>{
        if(e.target.value){
            setFechaInicio(e.target.value)
        }
    }

    return (
        <LayoutPrincipal>
            <Container className="my-4">
                <SectionWithTitle title="Control de informes">
                    <Row className="m-0">
                        <Col className="pr-0">
                            <FormGroup
                                type="text"
                                name="paciente"
                                label="DNI de Paciente"
                                onChange={handleBuscarPorDni}>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup
                                type="date"
                                name="fecha"
                                label="Fecha desde"
                                value={fechaInicio}
                                onChange={handleBuscarPorFecha}>
                            </FormGroup>
                        </Col>
                    </Row>

                    <IfRender condicion={error}>
                        <Alert variant="danger"> {error} </Alert>
                    </IfRender>

                    <IfRender condicion={!cargando} no={() => <Cargando text="Cargando estudios"/>}>
                        <TablaEstudios
                            headers={['Fecha', 'Estudio', 'Servicio', 'Estado', 'Descargar', 'Adjunto']}
                            estudios = {estudios}
                        />
                    </IfRender>

                </SectionWithTitle>
            </Container>
        </LayoutPrincipal>
    );
}
