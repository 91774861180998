import React from 'react';
import SpanWithTooltip from 'components/SpanWithTooltip';
import BotonReinvitar from './BotonReinvitar';
import IfRender from 'components/IfRender';

const mapColumnas = {
    Dni: 'dni',
    Apellido: 'apellido',
    Nombre: 'nombre',
    Celular: 'celular',
}

const FilaPacientesBuscados = ({col, paciente}) => {
    switch(col){
        case 'Acción':
            return <IfRender condicion={paciente.celular == null || paciente.celular == ""}
                no={() => 
                    <BotonReinvitar paciente={paciente} />
                }>
                    <label className='text-danger'>Verificar número de celular en HP</label>
            </IfRender>
        default:
            return <SpanWithTooltip text={paciente[mapColumnas[col]]} />
    }
}

export default FilaPacientesBuscados;
