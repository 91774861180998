import React, { useContext, useState } from 'react';
import Boton from 'styledComponents/Boton.style';
import { ThemeProvider } from 'styled-components';
import FormGroup from 'components/FormGroup';
import TemaContext from 'providers/Tema';
import SkeletonForm from 'components/SkeletonForm';
import 'primeicons/primeicons.css';
import ComboServiciosPorCentro from 'components/turnos/ComboServiciosPorCentro';
import ComboMedicos from 'components/turnos/ComboMedicos';
import useServiciosPorCentro from 'hooks/turnos/useServiciosPorCentro';
import useMedicosPorServicio from 'hooks/turnos/useMedicos';
import useCrearRestriccionMedicoEdad from 'hooks/configuracion/RestriccionMedicoEdad/useCrearRestriccionMedicoEdad';
        
export default function RegistroRestriccionMedicoEdad() {
    const {errors, registrando, state, controller} = useCrearRestriccionMedicoEdad();
    const [servicio, setServicio] = useState(0);
    const {cargandoServicios, serviciosPorCentro, errorServicio} = useServiciosPorCentro(1);
    const {medicos,cargandoMedicos,errorMedicos} = useMedicosPorServicio(servicio);
    const theme = useContext(TemaContext);

    const handleServicioChange =(e) =>{
        setServicio(e.target.value)
    }

    const icoInfo = (
            <div className='d-flex justify-content-end'>
                <i className="pi pi-info-circle ml-5" style={{ color: '#708090' }} 
                title='Dejar los campos de edad vacíos si el profesional atiende a pediatricos y adultos'></i>
            </div>);
            
    return (
        <SkeletonForm
            invalid={errors}
            validando={registrando}
            handleSubmit={controller.onSubmit}
            invalidMessage={errors}
            validandoMessage ="Registrando"
            render={() => (
            <>
                <ComboServiciosPorCentro
                    serviciosPorCentro={serviciosPorCentro}
                    handleServicioChange={handleServicioChange}
                    cargandoServicios={cargandoServicios}
                    servicio={servicio}
                    paddingClass= 'px-0'
                />
                <ComboMedicos
                    medicos={medicos}
                    handleMedicoChange={controller.handleOnChangeDni}
                    cargandoMedicos={cargandoMedicos}
                    medico={state.dni}
                    paddingClass= 'px-0'
                />
                {icoInfo}
                <FormGroup
                    label="Edad mínima de atención"
                    type="number"
                    placeholder="Ingrese la edad mínima de atención"
                    name="edadMinima"
                    onChange={controller.handleOnChangeEdadMinima}
                    value={state.edadMinima}>
                </FormGroup>
                <FormGroup
                    label="Edad máxima de atención"
                    type="number"
                    placeholder="Ingrese la edad máxima de atención"
                    name="edadMaxima"
                    onChange={controller.handleOnChangeEdadMaxima}
                    value={state.edadMaxima}>
                </FormGroup>
                <ThemeProvider theme={theme}>
                    <Boton className="btn btn-primary w-100 mt-2">
                        Guardar
                    </Boton>
                </ThemeProvider>
            </>
            )}
        />
    );
}
