import React, {useState} from 'react';
import useCentrosAtencion  from 'hooks/turnos/useCentrosAtencion'
import useServiciosPorCentro  from 'hooks/turnos/useServiciosPorCentro'
import ComboCentroDeAtencion from 'components/turnos/ComboCentroDeAtencion'
import ComboServiciosPorCentro from 'components/turnos/ComboServiciosPorCentro'
import { Alert } from 'react-bootstrap';
import IfRender from 'components/IfRender';

export default function CambiarFecha({fechaInicio, setFechaInicio, servicio, setServicio}) {
    const [centro, setCentro] = useState(0);
    const {centrosDeAtencion, cargando, errorCentro} = useCentrosAtencion();
    const {cargandoServicios, serviciosPorCentro, errorServicio} = useServiciosPorCentro(centro);

    const handleFechaChange = (e) => {
        setFechaInicio(e.target.value);
    }

    const handleCentroChange =(e) =>{
        setCentro(e.target.value)
        setServicio(null)
    }

    const handleServicioChange =(e) =>{
        setServicio(e.target.value)
    }

    return (
        <>
            <div className="form-group px-2">
                <label htmlFor="fecha">Mostrar estudios desde</label>
                <input className="form-control" type="date" name="fecha" defaultValue={fechaInicio} onChange={handleFechaChange} />
            </div>
            <IfRender condicion={ errorCentro || errorServicio }
                no={ () =>
                    <div className="row pt-2">
                        <div className="col-12 col-md-6">
                            <ComboCentroDeAtencion
                                centrosDeAtencion={centrosDeAtencion}
                                handleCentroChange={handleCentroChange}
                                cargando={cargando}
                                centro={centro}
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <ComboServiciosPorCentro
                                serviciosPorCentro={serviciosPorCentro}
                                handleServicioChange={handleServicioChange}
                                cargandoServicios={cargandoServicios}
                                servicio={servicio}
                            />
                        </div>
                    </div>
                }>
                <Alert variant="danger"> {errorCentro} </Alert>
            </IfRender>
        </>
    );
}
