import React, { useState } from 'react';
import { useLocation } from 'wouter';
import url from 'utils/url';
import 'css/scroll.css'
import { apiGet } from 'api/configApi';
import { Dialog } from 'primereact/dialog';

const BotonTurnoMedico = ({
        nombreCompleto,
        fecha,
        hora,
        idTurno,
        propietario,
        prestacion,
        servicio,
        centro,
        mutual,
        medico,
        isConsalud,
        coseguro
    }) => {

    const [, setLocation] = useLocation();
    const [error,setError] = useState();
    const [visible, setVisible] = useState(false);


    const handleSubmit = (e) =>{
        verificar();
    }

    const handleResponse = (response) =>{
        if(!response.data.permite){
             redireccionar();
        }else{
            setVisible(true);
            setError(response.data.descripcion ? response.data.descripcion : 'no permite');
        }
    }

    const  verificar = () => {
        const [dia, mes, ano] = fecha.split("-");
        let cambiada = `${ano}-${mes}-${dia}`;
         apiGet(`api/ValidarRestriccionMedico/${mutual}/${medico}/${cambiada}`)
                 .then(handleResponse)
                 .catch(error => console.log(error))
    }

    function redireccionar () {
        setLocation(url(`confirmarTurno/${idTurno}/${propietario}/${prestacion}/${servicio}/${centro}/${mutual}/${medico}/${isConsalud}/${coseguro}`));
    }

    return (
        <>
            <div className='col-md-3 p-2'>
                <button className="btn btn-primary" onClick={handleSubmit}>
                    <div className="d-flex justify-content">
                        <small className="p-0 m-0 d-block">{nombreCompleto}</small>

                    </div>
                    <small className="p-1 m-0 d-block smallFechaTurno">{fecha} - {hora}</small>
                </button>
           </div>
           <div>
            <Dialog header="Alerta" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                    <p className="m-0">
                        No puede solicitar el siguiente turno: {error}
                    </p>
                </Dialog>
           </div>
        </>
    );
}

export default BotonTurnoMedico;
