import styled, { css } from 'styled-components';

const BaseStyle = css`
    color: white;
    background-color: ${defineColor};
    outline: none;
    border-color: white;
    transition: all 200ms ease;

    &:hover:not(:disabled) {
        color: ${defineColor} !important;
        background-color: white !important;
        border: 1px solid ${defineColor};
    }

    ${props => props.outline && css`
        border: 1px solid ${defineColor};
        color: ${defineColor};
        background-color: white;

        &:hover {
            background-color: ${defineColor} !important;
            color: white !important;
        }
    `}
`;

function defineColor(props){
    return props.secondary ? props.theme.secundario : props.theme.primario;
}

const Boton = styled.button`
    ${BaseStyle}
`;

const Enlace = styled.a`
    ${BaseStyle}
`;

export default Boton;
export { Enlace }


