import {  useState } from 'react';
import { apiPost } from 'api/configApi';

export default function useReenvioEmailVerificacion(){
    const [reenviando, setReenviando] = useState(false);
    const [error, setError] = useState();
    const [reenviado, setReenviado] = useState(false);

    const handleOnSubmit = evt => {
        evt.preventDefault();
        setReenviando(true);
        setError(false);

        const handleResponse = response => {
            if(response.data.verificationEmailResend){
                setReenviando(false);
                setReenviado(true);
            }
        };

        const handleErrors = error => {
            if(error.response.status === 429){
                setError(error.response.data.mensaje);
            }
            setReenviando(false);
        };

        apiPost('verification-notification').then(handleResponse).catch(handleErrors);
    };

    return {
        error,
        reenviando,
        reenviado,
        handleOnSubmit
    }
}
