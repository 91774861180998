import { useState } from "react";
import { apiPost } from 'api/configApi';

export default function useBuscar(
    {onSearch, conditionForSearch, onBuscando, onCancelar, searchUrl, searchKey, filters}
){
    const [error, setError] = useState();
    const [search, setSearch] = useState('');
    const [buscando, setBuscando] = useState(false);

    const disable = (search ? false : true) || buscando;

    const handleOnKeyPress = evt => {
        if(evt.key === 'Enter' && search) {
            buscar();
        }
    }

    const buscar = () => {
        const handleRespuesta = response => {
            onSearch(response.data);
            setBuscando(false);
        }

        const handleError = error => {
            setError(error.response.data.error);
            setBuscando(false);
        }

        if(conditionForSearch(search)){
            setBuscando(true);
            onBuscando();
            setError(null);
            apiPost(searchUrl, {[searchKey]: search, ...filters})
                .then(handleRespuesta)
                .catch(handleError);
        }
    }

    const handleOnChange = evt => {
        const value = evt.target.value;
        setError(null);
        setSearch(value);
    }

    const cancelar = () => {
        setError(null);
        setSearch('');
        setBuscando(false);
        onCancelar && onCancelar();
    }

    return {
        state: {
            error,
            disable,
            buscando,
            search
        },
        controller: {
            handleOnKeyPress,
            handleOnChange,
            buscar,
            cancelar
        }
    }
}
