import React, { useState } from 'react';
import SectionWithTitle from 'components/SectionWithTitle';
import IfRender from 'components/IfRender';
import ResponsiveTable from 'components/tabla/ResponsiveTable';
import FilaPacientesBuscados from 'components/administrador/FilaPacientesBuscados';
import Buscador from 'components/Buscador';
import LayoutConMenuLateral from 'layouts/LayoutConMenuLateral';
import { Form, FormControl } from 'react-bootstrap';

export default function BuscadorDePacientesPage(){
    const d = new Date();
    d.setDate(d.getDate()-5);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    const [pacientes, setPacientes] = useState([]);
    const [ fechaDesde, setFechaDesde ] = useState(`${ye}-${mo}-${da}`);
    const [sinResultados, setSinResultados] = useState();

    const onSearch = usuariosProvisorios => {
        setPacientes(usuariosProvisorios);
        setSinResultados(usuariosProvisorios.length === 0 ? 'No se encotraron resultados' : null);
    }

    const onCancelar = () => {
        setPacientes([])
        setSinResultados(null);
    }

    const handleFechaChange = (e) => {
        setFechaDesde(e.target.value);
    }

    return (
        <LayoutConMenuLateral>
            <div className="p-2">
                <SectionWithTitle title="Buscador de Pacientes">
                    <div className="form-group px-2">
                        <label htmlFor="fecha">
                            Buscar pacientes desde:
                        </label>
                        <FormControl
                            value={fechaDesde}
                            type="date"
                            onChange={handleFechaChange}
                        />
                        <Form.Text className="text-muted mx-2 my-0">
                            Se buscará entre los usuarios registrados desde la fecha ingresada hasta hoy.
                        </Form.Text>
                    </div>
                    <Buscador
                        onSearch={onSearch}
                        onCancelar={onCancelar}
                        onBuscando={onCancelar}
                        searchUrl="api/buscarPacientePorNombre"
                        searchInputType="text"
                        searchKey="busqueda"
                        placeholder="Buscar por nombre, apellido, dni o código provisorio"
                        helpText="El código provisorio se encuentra en el mensaje enviado al paciente."
                        conditionForSearch={search => search.length >= 3}
                        filters={{fecha: fechaDesde}}
                    />
                    <IfRender condicion={pacientes.length > 0}
                        no={ () => <span className="text-center mb-2 d-block">{sinResultados}</span> }
                    >
                        <ResponsiveTable
                            headers={ ['Dni', 'Nombre', 'Apellido', 'Celular', 'Acción'] }
                            rows={pacientes}
                            emptyText="No se encontraron pacientes"
                            renderRow={
                                (col, paciente) =>
                                <FilaPacientesBuscados
                                    col={col}
                                    paciente={paciente}
                                />
                            }
                        />
                    </IfRender>
                </SectionWithTitle>
            </div>
        </LayoutConMenuLateral>
    );
}
