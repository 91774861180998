import React, { useContext } from 'react';
import LayoutPrincipal from 'layouts/LayoutPrincipal';
import TemaContext from 'providers/Tema';
import NavBarLateral from 'components/MenuLateral/NavBarLateral';

export default function LayoutConMenuLateral({children}){
    const tema = useContext(TemaContext)
    return (
        <LayoutPrincipal>
            <div className="container-fluid m-0 p-0 h-100 d-flex justify-content-between flex-column flex-md-row">
                <NavBarLateral />
                <section className="flex-grow-1" style={{backgroundColor: tema.background}}>
                    {children}
                </section>
            </div>
        </LayoutPrincipal>
    );
}
