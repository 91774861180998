import { apiPost, getCsrfCookie, reduceErrorMessages } from "api/configApi";
import { UserContext } from "providers/UserProvider";
import { useCallback, useContext, useState } from "react";
import url from "utils/url";
import { useLocation } from "wouter";
import useValidarUsuarioProvisorio from "./useValidarUsuarioProvisorio";

export default function useLoginProvisorio({dniInicial, contraseñaProvisoriaInicial}){
    const [dni, setDni] = useState('');
    const [contraseñaProvisoria, setContraseñaProvisoria] = useState(contraseñaProvisoriaInicial);
    const [telefono, setTelefono] = useState();
    const [email, setEmail] = useState();
    const [contraseña, setContraseña] = useState();
    const [repetirContraseña, setRepetirContraseña] = useState();
    const [registrando, setRegistrando] = useState(false);
    const [errors, setErrors] = useState(null);
    const {addUser} = useContext(UserContext);
    const [, setLocation] = useLocation();

    const onValidate = useCallback(data => {
        setEmail(data.datosPersona.email);
        setTelefono(data.datosPersona.telefono);
    }, [setEmail, setTelefono]);

    const redirectIfUserAlreadyCreated = useCallback(({pistaEmail, userAlreadyCreated}) => {
        if(userAlreadyCreated){
            const path = pistaEmail ? `?tiene-usuario=${pistaEmail}` : '';
            setLocation(url(path));
        }
    }, [setLocation]);

    const onError = useCallback(data => {
        redirectIfUserAlreadyCreated(data);
        setDni('');
        setContraseñaProvisoria('');
        setErrors(data.mensaje);
    }, [setErrors, redirectIfUserAlreadyCreated]);

    const {validando, validarUsuarioProvisorio, setValidando} = useValidarUsuarioProvisorio({
        dni: dniInicial,
        contraseñaProvisoria: contraseñaProvisoriaInicial,
        onValidate,
        onError
    });

    const handleOnChangeDni = useCallback(evt => {
        setErrors(null);
        setDni(evt.target.value);
    }, [setDni]);

    const handleOnChangeContraseñaProvisoria = useCallback(evt => {
        setErrors(null);
        setContraseñaProvisoria(evt.target.value);
    }, [setContraseñaProvisoria]);

    const handleOnChangeTelefono = useCallback(evt => {
        setErrors(null);
        setTelefono(evt.target.value);
    }, [setTelefono]);

    const handleOnChangeEmail = useCallback(evt => {
        setErrors(null);
        setEmail(evt.target.value);
    }, [setEmail]);

    const handleOnChangeContraseña = useCallback(evt => {
        setErrors(null);
        setContraseña(evt.target.value);
    }, [setContraseña]);

    const handleOnChangeRepetirContraseña = useCallback(evt => {
        setErrors(null);
        setRepetirContraseña(evt.target.value);
    }, [setRepetirContraseña]);

    const onSubmit = evt => {
        evt.preventDefault();
        setRegistrando(true);
        getCsrfCookie()
            .then(handleRegister)
            .then(handleRegisterResponse)
            .catch(handleErros);
    }

    const data = {
        dni,
        email,
        telefono,
        contraseñaProvisoria,
        password: contraseña,
        password_confirmation: repetirContraseña
    }

    const handleRegister = () => apiPost('api/registrarProvisorio', data);

    const handleRegisterResponse = response =>  {
        addUser(response.data);
        setRegistrando(false);
        setLocation(url(response.data.default_url_home));
    }

    const handleErros = error => {
        setRegistrando(false);
        setErrors(reduceErrorMessages(error));
    }

    const onBlurValidarProvisorio = () => {
        if(dni && contraseñaProvisoria){
            setValidando(true);
            validarUsuarioProvisorio(dni, contraseñaProvisoria);
            setEmail(null);
            setTelefono(null);
            setErrors(null);
        }
    }

    return {
        validando,
        registrando,
        errors,
        state: {
            email,
            telefono,
            dni,
            contraseñaProvisoria
        },
        controller: {
            handleOnChangeDni,
            handleOnChangeContraseñaProvisoria,
            handleOnChangeEmail,
            handleOnChangeTelefono,
            handleOnChangeContraseña,
            handleOnChangeRepetirContraseña,
            onSubmit,
            onBlurValidarProvisorio
        }
    }


}
