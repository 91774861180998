
import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import IfRender from "components/IfRender";
import Cargando from "components/Cargando";

export default function GraficoCircular({ cargando , detalle }) {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const labels = [];
    const cantidad = [];
    const backgroundColor = [];

    function randColors(){
        const simbolos = "0123456789ABCDEF";
        let color = "#";
        for(let i = 0; i < 6; i++){
            color = color + simbolos[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    
    detalle.forEach(e => {
        labels.push(e.concepto);
        cantidad.push(e.cantidad);
        backgroundColor.push(randColors());
    });

    useEffect(() => {
        const data = {
            labels: labels,
            datasets: [
                {
                    data: cantidad,
                    backgroundColor: backgroundColor
                }
            ]
        };
        const options = {
            cutout: '35%'
        };
        setChartData(data);
        setChartOptions(options);
    }, []);

    return (
        <div className="flex justify-content-center">
            <IfRender
                condicion={!cargando}
                no={() => <Cargando text="Cargando gráfica" />}
            >
                <Chart type="doughnut" data={chartData} options={chartOptions}  />                
            </IfRender>
        </div>
    )
}
        