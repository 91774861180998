import React from "react";
import { Card } from "primereact/card";

export default function AltaUsuariosCards({estadisticaAltaUsuarios, registrosMutuales}) {
    return (


                    <div className="card">
                        <Card title='Usuarios dados de alta'>
                            <div className="d-flex align-items-center justify-content-between">
                                <small>Registros totales:</small>
                                <p className="m-0 h1 text-primary">{estadisticaAltaUsuarios[0].usuarios}</p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <small>Via Whatsapp:</small>
                                <p className="m-0 h4 font-weight-bold">{estadisticaAltaUsuarios[0].whatsapp}</p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <small>Via E-Mail:</small>
                                <p className="m-0 h4 font-weight-bold">{estadisticaAltaUsuarios[0].email}</p>
                            </div>
                        </Card>
                    </div>
 
                /* <div className="col-sm-4">
                    <div className="card">
                        <Card title='Ingresos por Whatsapp'>
                            <div className="d-flex align-items-center justify-content-between">
                                <small>Invitados por atención:</small>
                                <p className="m-0 h4 font-weight-bold">{estadisticaAltaUsuarios[0].autogenerado}</p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <small>Invitados por administración:</small>
                                <p className="m-0 h4 font-weight-bold">{estadisticaAltaUsuarios[0].invitado}</p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <small>Reinvitados por administración:</small>
                                <p className="m-0 h4 font-weight-bold">{estadisticaAltaUsuarios[0].reinvitado}</p>
                            </div>
                        </Card>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <Card title='Registros por mutual'>
                            {registrosMutuales.map((registroMutual) => (
                            <div className="d-flex align-items-center justify-content-between">
                                
                                <small>{registroMutual.mutual}:</small>
                                <p className="m-0 h4 font-weight-bold">{registroMutual.cantidad}</p>
                            </div>
                            ))}
                        </Card>
                    </div>
                </div> */
    );
}