import React from "react";
import { Link} from 'wouter';
import url from 'utils/url';

export default function MenuCard({titulo,text,acceso,icon}){
    return(
        <>
            <div className="p-1 bd-highlight" >
                <div className="card">
                    <div className="card-body">
                        <div className='row'>
                            <div className='col-2'>
                                <i className={icon} style={{ fontSize: '1rem' }}></i>
                            </div>
                            <div className='col-10'>
                                <Link href={url(acceso)} className="text-color-black" style={{ color: 'black' }} >
                                    <h5 className="card-title">{titulo}</h5>
                                    <p className="card-text">{text}</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
