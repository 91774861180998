import React, { useState } from "react";
import { useDetalleEstadistica } from "hooks/estadistica/useEstadistica";
import Cargando from "components/Cargando";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import IfRender from "components/IfRender";
import GraficoCircular from "../GraficoCircular";
import { Card } from "primereact/card";

export default function TablaDetalleEstadistica({ desde, hasta, accion }) {
    const { cargando, detalle } = useDetalleEstadistica(desde, hasta, accion);
    const array = [];
    const afiliados = [];
    const fecha = [];
    const medicos = [];
    const servicio = [];
    const mutuales = [];

    function formatearFecha(fecha) {
        return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1");
    }

    detalle.forEach((e) => {
        const items = new Object();
        switch (e.item) {
            case "a":
                items.concepto = e.concepto;
                items.dni = e.dni;
                items.cantidad = e.cantidad;
                afiliados.push(items);
                break;
            case "f":
                items.concepto = e.concepto;
                items.cantidad = e.cantidad;
                fecha.push(items);
                break;
            case "m":
                items.concepto = e.concepto;
                items.dni = e.dni;
                items.servicio = e.servicio;
                items.cantidad = e.cantidad;
                medicos.push(items);
                break;
            case "s":
                items.concepto = e.concepto;
                items.cantidad = e.cantidad;
                servicio.push(items);
                break;
            case "mu":
            items.concepto = e.concepto;
            items.cantidad = e.cantidad;
            mutuales.push(items);
            break;
        }
    });

    array.push(afiliados);
    array.push(fecha);
    array.push(medicos);
    array.push(servicio);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const [filtros, setFiltros] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const renderHeader = () => {
        return (
            <>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        onInput={(e) =>
                            setFilters({
                                global: {
                                    value: e.target.value,
                                    matchMode: FilterMatchMode.CONTAINS,
                                },
                            })
                        }
                        placeholder="Buscar"
                    />
                </span>
            </>
        );
    };
    const renderHeaderMedicos = () => {
        return (
            <>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        onInput={(e) =>
                            setFiltros({
                                global: {
                                    value: e.target.value,
                                    matchMode: FilterMatchMode.CONTAINS,
                                },
                            })
                        }
                        placeholder="Buscar"
                    />
                </span>
            </>
        );
    };
    const header = renderHeader();
    const headerMedicos = renderHeaderMedicos();

    function renderDetalle() {
        if (accion == "mis-estudios") {
            return (
                <>
                    <div className="my-4 mx-2">
                        <h4 className="text-muted">Afiliados</h4>
                        <small>
                            Los siguientes resultados corresponden a :{" "}
                            {formatearFecha(desde)} - {formatearFecha(hasta)}
                        </small>
                    </div>
                    <DataTable
                        value={afiliados}
                        paginator
                        filters={filters}
                        responsiveLayout="scroll"
                        size="small"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks 
                        NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Resultado {first} - {last} de {totalRecords}"
                        rows={10}
                        header={header}
                        emptyMessage="No se encontraron registros."
                    >
                        <Column
                            field="concepto"
                            header="Apellido/Nombre"
                            sortable
                        ></Column>
                        <Column field="dni" header="DNI" sortable></Column>
                        <Column
                            field="cantidad"
                            header="cantidad"
                            sortable
                        ></Column>
                    </DataTable>
                </>
            );
        } else {
            return (
                <>
                    <div className="row justify-content-center my-2">
                        <div className="col-sm">
                            <GraficoCircular
                                cargando={cargando}
                                detalle={servicio}
                            />
                        </div>
                        <div className="col-sm">
                            <Card title="Servicios solicitados">
                                <DataTable
                                    value={servicio}
                                    paginator
                                    responsiveLayout="scroll"
                                    size="small"
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks 
                                    NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Resultado {first} - {last} de {totalRecords}"
                                    rows={10}
                                    emptyMessage="No se encontraron registros"
                                >
                                    <Column
                                        field="concepto"
                                        header="Servicio"
                                        sortable
                                    ></Column>
                                    <Column
                                        field="cantidad"
                                        header="cantidad"
                                        sortable
                                    ></Column>
                                </DataTable>
                            </Card>
                        </div>
                    </div>
                    <div className="row justify-content-center my-2">
                        <div className="col-sm">
                            <GraficoCircular
                                cargando={cargando}
                                detalle={mutuales}
                            />
                        </div>
                        <div className="col-sm">
                            <Card title="Mutuales">
                                <DataTable
                                    value={mutuales}
                                    paginator
                                    responsiveLayout="scroll"
                                    size="small"
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks 
                                    NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Resultado {first} - {last} de {totalRecords}"
                                    rows={10}
                                    emptyMessage="No se encontraron registros"
                                >
                                    <Column
                                        field="concepto"
                                        header="Mutual"
                                        sortable
                                    ></Column>
                                    <Column
                                        field="cantidad"
                                        header="cantidad"
                                        sortable
                                    ></Column>
                                </DataTable>
                            </Card>
                        </div>
                    </div>
                    <div className="row justify-content-center my-2">
                        <div className="col-sm">
                            <GraficoCircular cargando={cargando} detalle={fecha} />
                        </div>
                        <div className="col-sm">
                            <Card title="Meses para los que corresponden los turnos">
                                <DataTable
                                    value={fecha}
                                    responsiveLayout="scroll"
                                    size="small"
                                    emptyMessage="No se encontraron registros"
                                >
                                    <Column
                                        field="concepto"
                                        header="Mes"
                                        sortable
                                    ></Column>
                                    <Column
                                        field="cantidad"
                                        header="cantidad"
                                        sortable
                                    ></Column>
                                </DataTable>
                            </Card>
                        </div>
                    </div>
                    <div className="my-4 mx-2">
                        <h4 className="text-muted">Profesionales</h4>
                        <small>
                            Los siguientes resultados corresponden a :{" "}
                            {formatearFecha(desde)} - {formatearFecha(hasta)}
                        </small>
                    </div>
                    <DataTable
                        value={medicos}
                        paginator
                        filters={filtros}
                        responsiveLayout="scroll"
                        size="small"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks 
                        NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Resultado {first} - {last} de {totalRecords}"
                        rows={10}
                        header={headerMedicos}
                        emptyMessage="No se encontraron registros."
                    >
                        <Column
                            field="concepto"
                            header="Apellido/Nombre"
                            sortable
                        ></Column>
                        <Column field="dni" header="DNI" sortable></Column>
                        <Column
                            field="servicio"
                            header="Servicio"
                            sortable
                        ></Column>
                        <Column
                            field="cantidad"
                            header="cantidad"
                            sortable
                        ></Column>
                    </DataTable>
                    <div className="mx-2">
                        <h4 className="text-muted">Afiliados</h4>
                        <small>
                            Los siguientes resultados corresponden a :{" "}
                            {formatearFecha(desde)} - {formatearFecha(hasta)}
                        </small>
                    </div>
                    <DataTable
                        value={afiliados}
                        paginator
                        filters={filters}
                        responsiveLayout="scroll"
                        size="small"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks 
                        NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Resultado {first} - {last} de {totalRecords}"
                        rows={10}
                        header={header}
                        emptyMessage="No se encontraron registros."
                    >
                        <Column
                            field="concepto"
                            header="Apellido/Nombre"
                            sortable
                        ></Column>
                        <Column field="dni" header="DNI" sortable></Column>
                        <Column
                            field="cantidad"
                            header="cantidad"
                            sortable
                        ></Column>
                    </DataTable>
                </>
            );
        }
    }

    return (
        <IfRender
            condicion={!cargando}
            no={() => <Cargando text="Cargando detalle..." />}
        >
            {renderDetalle()}
        </IfRender>
    );
}
