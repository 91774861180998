import React from "react";
import { Card } from "primereact/card";

export default function CardItemIngresosWhatsapp({estadisticasAltaUsuarios}) {
    return (
                <div className="col-sm-4">
                    <div className="card">
                        <Card title='Ingresos por Whatsapp'>
                            <div className="d-flex align-items-center justify-content-between">
                                <small>Invitados por atención:</small>
                                <p className="m-0 h4 font-weight-bold">{estadisticasAltaUsuarios[0].autogenerado}</p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <small>Invitados por administración:</small>
                                <p className="m-0 h4 font-weight-bold">{estadisticasAltaUsuarios[0].invitado}</p>
                            </div>
                        </Card>
                    </div>
                </div>
    );
}