import { apiPost } from "api/configApi";
import { useEffect, useState } from "react";

export default function useReinvitarPaciente(idUsuarioProvisorio){
    const [mensaje, setMensaje] = useState();
    const [reinvitando, setReinvitando] = useState();
    const [seDebeReinvitar, setSeDebeReinvitar] = useState(false);

    const handleResponse = response => {
        setMensaje(response.data.message);
        setReinvitando(false);
        setSeDebeReinvitar(false);
    }

    const reinvitar = () => {
        setSeDebeReinvitar(true);
        setMensaje(null);
    };

    const handleError = error => {
        setMensaje(error.response.data.message);
        setReinvitando(false);
    }

    useEffect(() =>  {
        if(seDebeReinvitar){
            setReinvitando(true);
            apiPost('api/reInvitarPaciente', { idUsuarioProvisorio })
                .then(handleResponse)
                .catch(handleError)
        }
    },[idUsuarioProvisorio, seDebeReinvitar]);

    return { mensaje, reinvitando, reinvitar};
}

