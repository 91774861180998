import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useValidarRestriccionObraSocial(obraSocial,institucion){
    const [validacionOS, setValidacionOS] = useState([]);
    const [cargandoValidacionOS, setCargandoValidacionOS] = useState(false);
    const [errorValidacionOS, setErrorValidacionOS] = useState();

    const handleResponse = response =>{
        setValidacionOS(response.data);
        setCargandoValidacionOS(false);
    }

    useEffect(() =>  {
        if(institucion && institucion !== 0){
            setCargandoValidacionOS(true);
            apiGet(`api/ValidarRestriccionInstitucion/${obraSocial}/${institucion}`)
                .then(handleResponse)
                .catch(error => {
                    setErrorValidacionOS("Error inesperado.");
                    setCargandoValidacionOS(false);
                })

        }
    },[obraSocial,institucion]);

    return {cargandoValidacionOS, validacionOS, errorValidacionOS};
}
