import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "providers/UserProvider";
import useFamiliares from "hooks/turnos/useFamiliares";
import useMutuales from "hooks/turnos/useMutuales";
import ComboFamiliares from "components/turnos/ComboFamiliares";
import ComboMutuales from "components/turnos/ComboMutuales";
import AlertValidarFamiliar from "components/AlertValidarFamiliar";
import Combo from "components/Combo";
import IfRender from "components/IfRender";
import useProximosTurnos from "hooks/turnos/useProximosTurnos";

export default function PropietarioDelTurno({
    idPersona,
    setIdPersona,
    setError,
    mutual,
    setMutual,
    setIsConsalud,
    setCentro,
    setServicio,
    setMedico,
    setPrestacionHp,
}) {
    const { user } = useContext(UserContext);
    const { cargandoFamiliares, familiares, errorFamiliares } = useFamiliares(
        user.id
    );
    const [mostrar, setMostrar] = useState(false);
    const [invalido, setInvalido] = useState(null);
    const [seleccionado, setSeleccionado] = useState(null);

    const { cargandoMutuales, mutuales, errorMutuales } = useMutuales(
        0,
        idPersona
    );

    const [documento, setDocumento] = useState(user.dni);
    const { cargandoTurnos, turnos, errorTurnos, tieneTurnoProximo } =
        useProximosTurnos(documento, idPersona);

    const handlePropietarioChange = (e) => {
        var seleccion = parseInt(e.target.value);
        setSeleccionado(seleccion);
        setInvalido(null);
        setCentro(0);
        setServicio(null);
        setMedico(0);
        setPrestacionHp(0);
        setMutual(null);

        if (seleccion === 2) {
            setters(true, -1);
        } else {
            seleccion === 1 && setters(false, user.datos_personales.id_persona);
        }
    };

    const handleMutualChangeAdditional = (e) => {
        setMutual(e.target.value);
        setCentro(0);
        setServicio(null);
        setMedico(0);
        setPrestacionHp(0);
        setIsConsalud(
            mutuales.some((m) => {
                return (
                    parseInt(m.usaAppConsalud) === 1 &&
                    parseInt(m.id) === parseInt(e.target.value)
                );
            })
        );
    };

    function setters(mostar, idPersona) {
        setMostrar(mostar);
        setIdPersona(idPersona);
    }

    if (errorFamiliares || errorMutuales) {
        setError(true);
    }

    return (
        <>
            <Combo
                name="usuarioLogueado"
                opcionValue="denominacion"
                label="Selecciona para quién será el turno"
                opciones={[
                    { id: 1, denominacion: "Para mí" },
                    { id: 2, denominacion: "Para un familiar" },
                ]}
                onChange={handlePropietarioChange}
                defaultValue={seleccionado}
            />
            <ComboFamiliares
                familiares={familiares}
                mostrar={mostrar}
                cargandoFamiliares={cargandoFamiliares}
                setIdPersona={setIdPersona}
                setInvalido={setInvalido}
            />
            <IfRender
                condicion={!invalido}
                no={() => (
                    <AlertValidarFamiliar
                        familiar={invalido}
                        accion={"solicitar turnos"}
                    />
                )}
            >
                <IfRender condicion={idPersona != -1} no={() => <></>}>
                    <IfRender condicion={!cargandoTurnos}>

                    <IfRender
                        condicion={!(tieneTurnoProximo && turnos.length >= 3)}
                        no={() => (
                            <p style={{ color: "red" }}>
                                Tienes 3 turnos pendientes
                            </p>
                        )}
                    >
                        <ComboMutuales
                            mutuales={mutuales}
                            cargandoMutuales={cargandoMutuales}
                            handleMutualChange={handleMutualChangeAdditional}
                            mutual={mutual}
                        />
                        
                    </IfRender>
                    </IfRender>
                </IfRender>
            </IfRender>
        </>
    );
}
