import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useEstudios(dni, fecha, idServicio){
    const [estudios, setEstudios] = useState([]);
    const [cargando, setCargando] = useState();
    const [error, setError] = useState();
    const handleResponse = response =>{
        setEstudios(response.data);
        setCargando(false);
    }

    useEffect(() =>  {
        setCargando(true);
        apiGet(`api/estudiosPacientes?dni=${dni}&fecha=${fecha}&idServicio=${idServicio}`)
            .then(handleResponse)
            .catch(error => {
                setError("Error inesperado.");
                setCargando(false);
            })
    },[dni, fecha, idServicio]);


    return {cargando, estudios, error};

}
