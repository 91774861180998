import React from 'react';
import ResponsiveTable from 'components/tabla/ResponsiveTable'
import IfRender from 'components/IfRender'
import Cargando from 'components/Cargando'
import FilaFormularioCompleto from 'components/anestesista/FilaFormularioCompleto'
import Alert from 'components/Alert';
import { Container } from 'react-bootstrap';

export default function Formularios({
    formulariosParaMostrar,
    cargandoFormularios,
    errorFormulario,
    handleDelete,
    handleOnErrorEliminar,
    setMensajeAnular,
}){

    return (
            <IfRender
                condicion={!errorFormulario}
                no={() =>
                    <Container className="mb-2">
                        <Alert>{errorFormulario}</Alert>
                    </Container>
                }>

                <IfRender
                    condicion={!cargandoFormularios}
                    no={ () => <Cargando text="Cargando formularios"/> }
                >
                    <ResponsiveTable
                        headers={['Fecha', 'Paciente', 'DNI', 'Acción']}
                        rows={formulariosParaMostrar}
                        renderRow=  {
                            (col, formulario) =>
                            <FilaFormularioCompleto
                                col={col}
                                formulario={formulario}
                                handleDelete={handleDelete}
                                handleOnErrorEliminar={handleOnErrorEliminar}
                                setMensajeAnular={setMensajeAnular}
                            />
                        }
                    />
                </IfRender>
            </IfRender>
    );
}
