import Combo from 'components/Combo';
import IfRender from 'components/IfRender';
import React, { useState } from 'react';

export default function ComboFamiliares({familiares, mostrar, cargandoFamiliares, setIdPersona, setInvalido}){

    const [idPersonaSeleccionada, setIdPersonaSeleccionada] = useState(null)

    const handleIdPersonaChange = (e) =>{
        const familiar = familiares.find(f => f.idPersona === e.target.value)
        if(familiar.validado === "0"){
            setInvalido(familiar)
            setIdPersona(-1)
            setIdPersonaSeleccionada(null)
        }else{
            setIdPersona(e.target.value)
            setInvalido(null)
            setIdPersonaSeleccionada(e.target.value)
        }
    }

    const isInvalido = (familiar) => {
        return familiar.validado === "0"
    }
    return (
        <IfRender condicion={mostrar}>
            <Combo
                opciones={familiares}
                label="Familiar"
                opcionId="idPersona"
                opcionValue="apellidoNombre"
                defaultValue={idPersonaSeleccionada}
                cargando={cargandoFamiliares}
                onChange={handleIdPersonaChange}
                destacarOpcion={isInvalido}
                destacarOpcionClass="text-danger"
            />
        </IfRender>
    );
}
