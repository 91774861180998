import SkeletonForm from "components/SkeletonForm";
import ComboMedicos from "components/turnos/ComboMedicos";
import ComboPrestaciones from "components/turnos/ComboPrestaciones";
import ComboServiciosPorCentro from "components/turnos/ComboServiciosPorCentro";
import useRegistroMontoPrestacion from "hooks/configuracion/MontoPrestacion/useRegistroMontoPrestacion";
import useMedicosPorServicio from "hooks/turnos/useMedicos";
import useServiciosPorCentro from "hooks/turnos/useServiciosPorCentro";
import TemaContext from "providers/Tema";
import React, { useContext, useState } from "react";
import { ThemeProvider } from "react-bootstrap";
import Boton from "styledComponents/Boton.style";
import usePrestaciones  from 'hooks/turnos/usePrestaciones'
import FormGroup from "components/FormGroup";

export default function RegistroMontoPrestacion(){
    const {errors, registrando, state, controller} = useRegistroMontoPrestacion();
    const {cargandoServicios, serviciosPorCentro} = useServiciosPorCentro(1);
    const theme = useContext(TemaContext);
    const [centro, setCentro] = useState(1);
    const {medicos,cargandoMedicos,errorMedicos} = useMedicosPorServicio(state.servicio);
    const {cargandoPrestaciones, prestaciones} = usePrestaciones(centro, state.servicio,state.medico);


    return (
        <SkeletonForm
            invalid={errors}
            validando={registrando}
            handleSubmit={controller.onSubmit}
            invalidMessage={errors}
            validandoMessage ="Registrando"
            render={() => (
            <>
                <ComboServiciosPorCentro
                    serviciosPorCentro={serviciosPorCentro}
                    handleServicioChange={controller.handleOnChangeServicio}
                    cargandoServicios={cargandoServicios}
                    servicio={state.servicio}
                />
                <ComboMedicos
                    medicos={medicos}
                    handleMedicoChange={controller.handleOnChangeMedico}
                    cargandoMedicos={cargandoMedicos}
                    medico={state.medico}
                />
                <ComboPrestaciones
                    prestaciones={prestaciones}
                    cargandoPrestaciones={cargandoPrestaciones}
                    handlePrestacionChange={controller.handleOnChangePrestacion}
                    prestacion={state.prestacion}
                />
                <FormGroup
                    label="Monto"
                    type="text"
                    placeholder="Ingrese el monto de la prestacion"
                    name="monto"
                    onChange={controller.handleOnchangeMonto}
                    value={state.monto}
                    required={true}>
                </FormGroup>
                <FormGroup
                    label="Fecha"
                    type="date"
                    placeholder="Seleccione una fecha"
                    name="fecha"
                    onChange={controller.handleOnChangeFecha}
                    value={state.fecha}
                    required={true}>
                </FormGroup>
                <ThemeProvider theme={theme}>
                    <Boton className="btn btn-primary w-100 mt-2">
                        Guardar
                    </Boton>
                </ThemeProvider>
            </>
            )}
        />
    );
}
