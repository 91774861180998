import React, { useState } from 'react';
import useEstudios from 'hooks/estudios/useEstudios'
import { Alert } from 'react-bootstrap';
import TablaEstudios from 'components/estudios/TablaEstudios';
import Cargando from 'components/Cargando';
import SectionWithTitle from 'components/SectionWithTitle';
import SeleccionarPropietario from 'pages/estudios/SeleccionarPropietario'
import useFamiliares  from 'hooks/turnos/useFamiliares'
import AlertValidarFamiliar from 'components/AlertValidarFamiliar';
import IfRender from 'components/IfRender';

export default function SeccionConAcceso({user, Layout}){
    const d = new Date();
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

    const [servicio, setServicio] = useState(null);
    const {cargandoFamiliares, familiares, errorFamiliares} = useFamiliares(user.id);
    const [documento, setDocumento] = useState(user.dni)
    const [invalido, setInvalido] = useState(false)
    const [fechaInicio, setFechaInicio] = useState(`${ye-1}-${mo}-${da}`)
    const {estudios, cargando, error} = useEstudios(documento, fechaInicio, servicio);

    return (
        <Layout>
            <IfRender condicion={ error || errorFamiliares }
                no={() =>
                    <div className="container-fluid">
                    <div className="mt-2 mb-2">
                        <SectionWithTitle title="Últimos Estudios">
                            <SeleccionarPropietario
                                familiares={familiares}
                                cargandoFamiliares={cargandoFamiliares}
                                user={user}
                                setDocumento={setDocumento}
                                setInvalido={setInvalido}
                                fechaInicio={fechaInicio}
                                setFechaInicio={setFechaInicio}
                                mostrarFecha={true}
                                servicio={servicio}
                                setServicio={setServicio}
                            />
                        </SectionWithTitle>
                    </div>
                    <IfRender condicion={!cargando}
                        no={() => <Cargando text="Cargando estudios"/>}
                    >
                        <IfRender condicion={!invalido}
                            no={() =>
                                <AlertValidarFamiliar
                                    familiar={invalido}
                                    accion={'mostrar estudios'}
                                />
                            }>
                             <SectionWithTitle >
                                <TablaEstudios
                                    headers={['Fecha', 'Estudio', 'Servicio', 'Estado','Ver Informe', 'Ver Imagen']}
                                    estudios = {estudios}
                                />
                            </SectionWithTitle>
                        </IfRender>
                    </IfRender>
                </div>
                }>
                <Alert variant="danger"> {error} </Alert>
            </IfRender>

        </Layout>
    );
}
