import React, { useContext } from 'react';
import Boton from 'styledComponents/Boton.style';
import IfRender from 'components/IfRender';
import TemaContext from 'providers/Tema';
import useReinvitarPaciente from 'hooks/useReinvitarPaciente';
import MensajeToast from 'components/MensajeToast';

const BotonReinvitar = ({paciente}) => {
    const tema = useContext(TemaContext);
    const { mensaje, reinvitando, reinvitar } = useReinvitarPaciente(paciente.id);

    return (
        <>
        <IfRender condicion={mensaje}>
            <MensajeToast
                title="Reinvitación"
                mensaje={mensaje}
            />
        </IfRender>
        <Boton className="btn" onClick={reinvitar} theme={tema} disabled={reinvitando}>
            <IfRender condicion={!reinvitando} no={ () => 'Enviando...' }>
                Reinvitar
            </IfRender>
        </Boton>
        </>
    );
}

export default BotonReinvitar;
