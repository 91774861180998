import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import SpanWithTooltip from 'components/SpanWithTooltip';
import ModalLinks from './ModalLinks';
import { ReactComponent as IcoDescargar } from 'icons/descargar.svg';

const mapColumnas = {
    Fecha: 'fecha_realizacion',
    Estudio: 'estudio',
    Servicio: 'servicio',
    Estado: 'estado'
}

const FilaEstudios = ({col, estudio}) => {
    const icoStyle = {width: '20px', height: '20px'};
    const [showModal, setShowModal] = useState(false);

    const handleDownloadClick = async (e) => {
        e.preventDefault();

        if (!estudio.existencia) {
            setShowModal(true);
        } else {
            window.open(estudio.path_documento, '_blank');
            const link = document.createElement('a');
            link.href = estudio.path_documento;
            link.download = estudio.filename || 'archivo';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <>
            {col === 'Ver Informe' ? (
            (estudio.path_documento && estudio.path_documento.trim() !== '') ? (
                <a href={estudio.path_documento} onClick={handleDownloadClick}>
                    <IcoDescargar style={icoStyle}/>
                </a>
            ) : 
                (estudio.url_informe ? (
                <a href={estudio.url_informe} download>
                    <IcoDescargar style={icoStyle}/>
                </a>
            ) : (
                <span onClick={handleDownloadClick} style={{ cursor: 'pointer', color: 'red' }}>
                    <IcoDescargar style={icoStyle} /> No disponible
                </span>
                )
            )
            ) : col === 'Ver Imagen' ? (
                <ModalLinks estudio={estudio} />
            ) : (
                <SpanWithTooltip text={estudio[mapColumnas[col]]} />
            )}

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Estudio no disponible</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Los estudios más antiguos deben ser solicitados enviando un correo a 
                    <a href="mailto:cipac.patologia@gmail.com"><strong> cipac.patologia@gmail.com</strong></a>.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
         </>
        );
};

export default FilaEstudios;
