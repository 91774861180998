
import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import TablaHistorialTurnosEstadistica from './Tabla/TablaHistorialTurnosEstadistica';
import TablaHistorialEstadistica from './Tabla/TablaHistorialEstadistica';
import TablaDetalleEstadistica from './Tabla/TablaDetalleEstadistica';
import CardItemIngresosWhatsapp from './CardItemIngresosWhatsapp';
import CardItemIngresosMutuales from './CardItemIngresosMutuales';
        
export default function AccordionEstadistica({ fechaDesde, fechaHasta, estadisticasIngresos, estadisticasMutuales}) {
    return (
        <div className="card">
            <Accordion>
                <AccordionTab header="Mis Estudios">
                    <TablaDetalleEstadistica desde={fechaDesde} hasta={fechaHasta} accion='mis-estudios'/>
                    <div className='my-4'>
                        <TablaHistorialEstadistica fechaDesde={fechaDesde} fechaHasta={fechaHasta} accion='mis-estudios'/>
                    </div>
                </AccordionTab>
                <AccordionTab header="Turnos otorgados">
                    <TablaDetalleEstadistica desde={fechaDesde} hasta={fechaHasta} accion='grabar-turno'/>
                    <div className="my-4">
                        <TablaHistorialTurnosEstadistica fechaDesde={fechaDesde} fechaHasta={fechaHasta} accion='grabar-turno'/>
                    </div>
                </AccordionTab>
                <AccordionTab header="Turnos anulados">
                    <TablaDetalleEstadistica desde={fechaDesde} hasta={fechaHasta} accion='anular-turno'/>
                    <div className="my-4">
                        <TablaHistorialTurnosEstadistica fechaDesde={fechaDesde} fechaHasta={fechaHasta} accion='anular-turno'/>
                    </div>
                </AccordionTab>
                <AccordionTab header="Alta de Usuarios">
                    <div className="my-4">
                        <CardItemIngresosWhatsapp estadisticasAltaUsuarios={estadisticasIngresos}/>
                    </div>
                    <div className="my-4">
                        <CardItemIngresosMutuales registrosMutuales={estadisticasMutuales}/> 
                    </div>
                </AccordionTab>
            </Accordion>
        </div>
    )
}
        