import TemaContext from 'providers/Tema';
import React, { useContext, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { SpinnerCargandoSmall } from 'styledComponents/SpinnerCargandoSmall.style';
import useMetodosDePagoPorCentro from 'hooks/pagos/useMetodosDePagoPorCentro'
import { apiPost } from 'api/configApi';
import { Dropdown } from 'react-bootstrap'
import Emergente from './Emergente';
import IfRender from 'components/IfRender';

export default function ModalPago({
    mostrar = false,
    turno,
    cargandoTurno,
    turnoInterno,
    errorTruno,
    cargandoPrestacion,
    prestacion,
    errorPrestacion
}){
    const tema = useContext(TemaContext)
    const [show, setShow] = useState(mostrar);
    const [disabled, setDisabled] = useState(true);
    const [cargando, setCargando] = useState(false);
    const [metodoDePago, setMetodoDePago] = useState();
    const {cargandoMetodos, metodos, errorMetodos} = useMetodosDePagoPorCentro(turno.id_centro)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const handleMetodo = (e) =>{
        setMetodoDePago(e.target.value)
        setDisabled(false)
    }

    const datos = {
        id_turno: turno.id,
        id_centro: turno.id_centro,
        metodoDePago: metodoDePago,

    }

    const pagar = () =>{
        setCargando(true)
        apiPost('api/pagos/Pagar', datos)
            .then( response => window.location.href = response.data.url )
    }

    return (
        <ThemeProvider theme={tema}>
            <IfRender condicion = {cargandoTurno || cargandoMetodos || cargandoPrestacion }
                no={() =>
                    <IfRender condicion={errorMetodos || errorTruno || errorPrestacion}
                        no={() =>
                            <IfRender condicion={turnoInterno && turnoInterno.pagado}
                                no={() =>
                                    <IfRender condicion={prestacion}>
                                        <Dropdown.Item onClick={handleShow}>Pagar</Dropdown.Item>
                                    </IfRender>
                                }>
                                <Dropdown.Item disabled>Pagado</Dropdown.Item>
                            </IfRender>
                        }>
                        <Dropdown.Item disabled>Error en pago</Dropdown.Item>
                    </IfRender>
                }>
                <ThemeProvider theme={tema}>
                    <SpinnerCargandoSmall />
                </ThemeProvider>
            </IfRender>

            <Emergente
                show={show}
                disabled={disabled}
                cargando={cargando}
                metodos={metodos}
                handleClose={handleClose}
                handleMetod={handleMetodo}
                pagar={pagar}
                metodoDePago={metodoDePago}
            />
        </ThemeProvider>

    );
}
