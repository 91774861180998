import React, { useContext } from 'react';
import FormGroup from 'components/FormGroup';
import FormRadio from 'components/FormRadio';
import File from 'components/File/File';
import { Section } from './styles';
import IfRender from 'components/IfRender';
import { Col, Row } from 'react-bootstrap';

const CuestionarioContext = React.createContext();
const CuestionarioProvider = CuestionarioContext.Provider;

const inputFileWarnings = [
    'puede adjuntar archivos PDF e imágenes en formato PNG, JPG o JPEG',
    'Máximo 10 archivos adjuntos',
    'Tamaño máximo por archivo 10MB'
];

const Cuestionario = ({state, controller}) => (
    <CuestionarioProvider value={{state, controller}}>
        <SeccionesPreguntas
            secciones={state.secciones}
        />
        <Section>
            <IfRender condicion={!state.forAnestesista}>
                <InputFile
                    state={state}
                    controller={controller}
                />
            </IfRender>
        </Section>
    </CuestionarioProvider>
);

const SeccionesPreguntas = ({secciones}) => (
    secciones.map(
        seccion =>
        <SeccionPregunta
            key={seccion.id}
            seccion={seccion}
        />
    )
);

const SeccionPregunta = ({seccion}) => (
    <Section key={seccion.id}>
        <h4>{seccion.seccion}</h4>
        <ListaPreguntas
            preguntas={seccion.preguntas}
        />
    </Section>
);

const ListaPreguntas = ({preguntas}) => (
    preguntas.map(
        pregunta =>
        <Pregunta
            key={pregunta.name}
            pregunta={pregunta}
        />
    )
);

const Pregunta = ({pregunta}) => (
    <div className="row" key={pregunta.name}>
        <div className="col">
            <TipoPregunta pregunta={pregunta} />
            <PreguntaImagen pregunta={pregunta} />
        </div>
    </div>
);

const PreguntaImagen = ({pregunta}) => (
    <IfRender condicion={pregunta.imagen_titulo}>
        <div className="my-2">
            <p>{pregunta.imagen_titulo}</p>
            <img src={pregunta.imagen_url} alt={pregunta.imagen_titulo} style={{maxWidth: '345px'}}/>
        </div>
    </IfRender>
);

const TipoPregunta = ({pregunta}) => {
    const { state } = useContext(CuestionarioContext);
    const readonly = pregunta.allowed_for === 'anestesista' && !state.forAnestesista ? true : false;
    const PreguntaAbiertaInstancia = <PreguntaAbierta pregunta={pregunta} readOnly={readonly}/>;

    const componentesPreguntas = {
        radio: <PreguntaCerrada pregunta={pregunta} />,
        number: PreguntaAbiertaInstancia,
        text: PreguntaAbiertaInstancia
    }

    return componentesPreguntas[pregunta.type];
}

const PreguntaCerrada = ({pregunta}) => (
    <Row>
        <Col>
            <label className="form-check-label">
                {pregunta.label}
            </label>
        </Col>
        <Col>
            <IfRender condicion={pregunta && pregunta.respuestas}>
                { pregunta.respuestas.map(
                    respuesta =>
                    <InputRadio
                        key={`${pregunta.id}-${respuesta.id}`}
                        preguntaId={pregunta.id}
                        respuesta={respuesta}
                    />
                )}
            </IfRender>
        </Col>
    </Row>
);

const PreguntaAbierta = ({ pregunta, readOnly }) => {
    const { state, controller } = useContext(CuestionarioContext);
    const respuesta = state.completados.find(
        completado => completado.pregunta_id === pregunta.id
    );
    const respuestaAbierta = respuesta && respuesta.respuesta_abierta;
    return (
        <FormGroup
            label={pregunta.label}
            name={pregunta.id}
            type={pregunta.type}
            maxLength={1000}
            placeholder={pregunta.placeholder}
            readOnly={readOnly}
            onChange={controller.handleOnChangeRespuestaLibre}
            required={!!pregunta.required}
            defaultValue={respuestaAbierta}/>
    );
}

const isCompleted = (completados, pregunta_id, respuesta_id) => {
    const contestado = pregunta => (
        pregunta.pregunta_id === pregunta_id &&
        pregunta.respuesta_id === respuesta_id
    );

    if(completados.length === 0) {
        return null;
    }
    return completados.some(contestado)

}

const InputRadio = ({ preguntaId, respuesta }) => {
    const { state, controller } = useContext(CuestionarioContext);
    const respuestaJson = JSON.stringify({
        pregunta_id: preguntaId,
        respuesta_id: respuesta.id
    });
    return (
        <FormRadio
            id={`${preguntaId}-${respuesta.id}-${respuesta.denominacion}`}
            name={preguntaId}
            label={respuesta.denominacion}
            value={respuestaJson}
            onChange={controller.handleOnChangeAnswer}
            defaultChecked={isCompleted(state.completados, preguntaId, respuesta.id)}
        />
    );
}

const InputFile = ({controller, state}) => (
    <div className="row">
        <div className="col-12">
            <h6>
                Aqui debe adjuntar los estudios de su último laboratorio (que contenga hemograma y coagulograma) y electrocardiograma completo con valoración
            </h6>
        </div>
        <div className="col-12">
            <File
                label="Cargar estudios"
                id="cargarEstudiosId"
                name="estudios"
                warnings={inputFileWarnings}
                fileLimit="10"
                sizeLimit="10000000"
                mimeTypes={['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']}
                state={state}
                controller={controller} />
        </div>
    </div>
);

export default Cuestionario;
