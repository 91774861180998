import Combo from 'components/Combo';
import React from 'react';

export default function ComboServiciosPorCentro({
    serviciosPorCentro,
    handleServicioChange,
    cargandoServicios,
    servicio,
    paddingClass = 'px-2'
}){
    return (
        <Combo
            opciones={serviciosPorCentro}
            label="Servicio"
            opcionValue="nombre"
            defaultValue={servicio}
            cargando={cargandoServicios}
            onChange={handleServicioChange}
            paddingClass={paddingClass}
        />
    );
}
