import React, { useContext, useState } from 'react';
import SpanWithTooltip from 'components/SpanWithTooltip';
import TemaContext from 'providers/Tema';
import Boton from 'styledComponents/Boton.style';
import { useLocation } from "wouter";
import url from 'utils/url';
import ModalCarruselAdjuntos from 'components/anestesista/ModalCarruselAdjuntos';
import { ReactComponent as IcoDelete} from 'icons/delete.svg';
import ConfirmDialog from 'components/ConfirmDialog';
import useAnularFormulario from 'hooks/anestesistas/useAnularFormulario';


const mapColumnas = {
    Paciente: 'paciente_nombre',
    DNI: 'paciente_dni',
    Fecha: 'fecha_de_consulta'
};

const FilaFormularioCompleto = ({col, formulario, handleDelete, handleOnErrorEliminar, setMensajeAnular}) => {
    const [, setLocation] = useLocation()
    const tema = useContext(TemaContext)
    const [show, setShow] = useState(false)
    const [confirmar, setConfirmar] = useState(false)


    const handleClic = () => {
        setLocation(url(`anestesistas/pacientes/${formulario.id}`))
    }
    const handleConfirmar = () => setConfirmar(true)
    const handleCancelar = () => setConfirmar(false)


    const eliminar = useAnularFormulario({formulario, handleDelete, setMensajeAnular, handleOnErrorEliminar})

    const icoStyle = {width: '20px', height: '20px', fill:'rgb(119, 36, 36)'};
    const preguntaAccionAnular = `¿Está seguro de anular el formulario? - Esta acción debe realizarse solamente cuando el formulario por algún motivo deja de tener validéz.`
    switch(col){
        case 'Acción':
            return(
                <>
                    <Boton
                        className="btn"
                        onClick={handleClic}
                        theme={tema}
                    >
                        Completar
                    </Boton>

                    <ModalCarruselAdjuntos
                        formulario={formulario}
                        show={show}
                        setShow={setShow}
                    />

                    <Boton secondary
                        className="btn"
                        onClick={handleConfirmar}
                        theme={tema}
                    >
                        <IcoDelete style={icoStyle}/>
                    </Boton>

                    <ConfirmDialog
                        abrir={confirmar}
                        titulo="Anular Formulario"
                        mensaje={preguntaAccionAnular}
                        onAceptar={eliminar}
                        onCancelar={handleCancelar}
                        onHide={handleCancelar}
                    />

                </>
            );
        default:
            return <SpanWithTooltip text={formulario[mapColumnas[col]]} />
    }
}

export default React.memo(
    FilaFormularioCompleto,
    (prevProps, nextProps) => prevProps.formulario.id === nextProps.formulario.id
);
