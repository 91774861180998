import React, { useContext } from 'react';
import TemaContext from 'providers/Tema';
import { SpinnerCargandoSmall } from 'styledComponents/SpinnerCargandoSmall.style';
import IfRender from './IfRender';

export default function Datalist({
    opciones,
    onChange,
    cargando,
    opcionId = 'id',
    opcionValue,
    defaultValue,
    destacarOpcion = () => {} ,
    destacarOpcionClass,
    label,
    name = '',
    paddingClass = 'px-2',
    nombreClase = "form-control",
    onTodos
}){
    const tema = useContext(TemaContext);
    const handleOnChange = evt => {
        onChange(evt);
    }

    return (
        <div className={`form-group ${paddingClass}`}>
            <label className="w-100 d-block" htmlFor="data">
                <span className="mr-1">
                    <IfRender condicion={cargando}>
                        <SpinnerCargandoSmall theme={tema} />
                    </IfRender>
                </span>
                { label }
                <input
                    type="text"
                    list="data"
                    onChange={handleOnChange}
                    name={name}
                    className={nombreClase}
                    required
                    value={defaultValue}
                    disabled={cargando}
                    placeholder="Buscar"/>
            </label>
            <datalist id="data" >
                    { opciones.map(opcion =>
                        <option key={opcion[opcionId]} value={opcion[opcionId]+'-'+opcion[opcionValue]}>{opcion[opcionValue]}</option>)}
            </datalist>
        </div>
    );
}
