//import useHasValue from 'hooks/useHasValue';
import React from 'react';
import { Form } from 'react-bootstrap';
import IfRender from 'components/IfRender';

export default function FormGroup(props){
    //const { handleblur, hasValue } = useHasValue();
    return(
        <Form.Group>
            <Form.Label>
                <IfRender condicion={props.required}>
                    <span className="text-danger">* </span>
                </IfRender>
                {props.label}
            </Form.Label>
            <Form.Control
                className="form-control"
                {...props}
            />
            <IfRender condicion={props.error}>
                <span className="text-danger">{props.error}</span>
            </IfRender>
        </Form.Group>
    );
}
