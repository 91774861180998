import React, { useState } from 'react';
import LayoutConMenuLateral from 'layouts/LayoutConMenuLateral';
import 'css/responsiveTable.css';
import { Col, Container,  Row } from 'react-bootstrap';
import CardPerfil from 'components/CardPerfil';
import SeccionBuscarPaciente from './SeccionBuscarPaciente';
import SeccionGestion from './SeccionGestion';
import { PacienteProvider } from 'providers/PacienteProvider';
import useBuscarPaciente from 'hooks/useBuscarPaciente';

export default function HomeAdministracion(){
    const [activarCrearUsuario, setActivarCrearUsuario] = useState(false);

    const onSearch = data => {
        setActivarCrearUsuario(!data.user ? true : false);
    }

    const {
        paciente,
        user,
        setUser,
        setPaciente,
        handleOnSearch,
        handleBuscando,
        cancelar
    } = useBuscarPaciente({onSearch});

    return (
        <LayoutConMenuLateral>
            <Container fluid>
                <Row className="pt-2">
                    <Col col={12} md={6}>
                        <SeccionBuscarPaciente
                            url="api/buscarPaciente"
                            seBusca="paciente"
                            onSearch={handleOnSearch}
                            onBuscando={handleBuscando}
                            onCancelar={cancelar}
                        />
                        <PacienteProvider value={{paciente, user, setUser, setPaciente}}>
                            <SeccionGestion activarCrearUsuario={activarCrearUsuario} />
                        </PacienteProvider>
                    </Col>
                    <Col col={12} md={6}>
                        { paciente && <CardPerfil datos={paciente} /> }
                    </Col>
                </Row>
            </Container>
        </LayoutConMenuLateral>
    );
}




