import React, { useState } from "react";
import SectionWithTitle from "components/SectionWithTitle";
import LayoutConMenuLateral from "layouts/LayoutConMenuLateral";
import CardItemEstadistica from "components/estadistica/CardItemEstadistica";
import { useEstadistica } from "hooks/estadistica/useEstadistica";
import IfRender from "components/IfRender";
import Cargando from "components/Cargando";
import { Alert } from "react-bootstrap";
import AccordionEstadistica from "components/estadistica/AccordionEstadistica";
import { Button } from 'primereact/button';
import { useEstadisticaAltaUsuarios } from "hooks/estadistica/useEstadisticaAltaUsuarios";
import AltaUsuariosCards from "components/estadistica/AltaUsuariosCards";
import { useEstadisticaMutualesAltaUsuarios } from "hooks/estadistica/useEstadisticaMutualesAltaUsuarios";
import { useEstadisticaVistas } from "hooks/estadistica/useEstadisticaVistas";
import CardItemVistas from "components/estadistica/CardItemVistas";

export default function EstadisticaPage() {
    const d = new Date();
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    
    const [fechaDesde, setFechaDesde] = useState(`${ye}-${mo}-01`);
    const [fechaHasta, setFechaHasta] = useState(`${ye}-${mo}-${da}`);
    
    const { cargando, registros, error } = useEstadistica( fechaDesde, fechaHasta );
    const { cargandoEstadisticasAltaUsuarios, 
            estadisticasAltaUsuarios, 
            errorEstadisticasAltaUsuarios } = useEstadisticaAltaUsuarios( fechaDesde, fechaHasta );
    const { cargandoEstadisticasMutualesAltaUsuarios,
            estadisticasMutualesAltaUsuarios,
            errorEstadisticasMutualesAltaUsuarios } = useEstadisticaMutualesAltaUsuarios( fechaDesde, fechaHasta );
    const { cargandoEstadisticasVistas, 
            estadisticasVistas, 
            errorEstadisticasVistas } = useEstadisticaVistas( fechaDesde, fechaHasta );
            const formRef = React.useRef();
    function handleSubmit(evt) {
      evt.preventDefault();
      const formData = new FormData(formRef.current);
      const values = Object.fromEntries(formData);
      setFechaDesde(values.desde);
      setFechaHasta(values.hasta)
    }

    return (
        <LayoutConMenuLateral>
            <IfRender
                condicion={error&&errorEstadisticasAltaUsuarios&&errorEstadisticasMutualesAltaUsuarios&&errorEstadisticasVistas}
                no={() => (
                    <div className="container-fluid">
                            <SectionWithTitle title={'Filtros'}>
                                <form onSubmit={handleSubmit} ref={formRef} className="row my-2 mx-2">
                                    <div className="col-sm-12 col-md-12 col-lg-5">
                                        <p className="text-muted">Fecha desde:</p>
                                        <input
                                            className="form-control"
                                            type="date"
                                            name="desde"
                                            id="desde"
                                            defaultValue={fechaDesde}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-5">
                                        <p className="text-muted">Fecha hasta:</p>
                                        <input
                                            className="form-control"
                                            type="date"
                                            name="hasta"
                                            id="hasta"
                                            defaultValue={fechaHasta}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-2 align-self-end">
                                        <Button style={{ backgroundColor:'#034EA2'}} className="w-100" icon="pi pi-search" label='Buscar'/>
                                    </div>
                                </form>
                            </SectionWithTitle>

                        <SectionWithTitle title={"Estadística General"} className="mt-2">
                            <IfRender
                                condicion={!cargando&&!cargandoEstadisticasAltaUsuarios&&!cargandoEstadisticasMutualesAltaUsuarios&&!cargandoEstadisticasVistas}
                                no={() => <Cargando text="Cargando registros" />}
                            >
                                <CardItemEstadistica registros={registros}/>
                            
                            <div className="row mx-2 mb-2">
                                <div className="col-sm-4">
                                    <AltaUsuariosCards estadisticaAltaUsuarios={estadisticasAltaUsuarios} registrosMutuales={estadisticasMutualesAltaUsuarios}/>
                                </div>
                                <div className="col-sm-4">
                                    <CardItemVistas estadisticaVistas={estadisticasVistas}/>
                                </div>
                            </div>
                            </IfRender>
                        </SectionWithTitle>

                        <SectionWithTitle title={"Detalle"} className="mt-2">
                            <IfRender
                                    condicion={!cargando&&!cargandoEstadisticasAltaUsuarios&&!cargandoEstadisticasMutualesAltaUsuarios&&!cargandoEstadisticasVistas}
                                    no={() => <Cargando text="Cargando registros" />}>
                                <AccordionEstadistica fechaDesde={ fechaDesde } fechaHasta={ fechaHasta } estadisticasIngresos ={estadisticasAltaUsuarios} estadisticasMutuales={estadisticasMutualesAltaUsuarios}/>
                            </IfRender>
                        </SectionWithTitle>
                    </div>
                )}
            >
                <Alert variant="danger"> {error + errorEstadisticasAltaUsuarios + errorEstadisticasMutualesAltaUsuarios + errorEstadisticasVistas } </Alert>
            </IfRender>
        </LayoutConMenuLateral>
    );
}
