import React from "react";
import { Card } from "primereact/card";

export default function CardItemEstadistica({ registros }) {
    return (
        <div className="row mx-2 mb-2">
            {registros.map((registro) => (
                <div className="col-sm-4">
                    <div className="card">
                        <Card title={registro.concepto}>
                            <div className="d-flex align-items-center justify-content-between">
                                <small>Total:</small>
                                <p className="m-0 h1 text-primary">{registro.cantidadT}</p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <small>Usuarios:</small>
                                <p className="m-0 h4 font-weight-bold">{registro.cantidadU}</p>
                            </div>
                        </Card>
                    </div>
                </div>
            ))}
        </div>
    );
}
