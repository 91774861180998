import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useAnestesistas(){
    const [anestesistas, setAnestesistas] = useState([]);
    const [cargandoAnestesistas, setCargandoAnestesistas] = useState();
    const [errorAnestesistas, setErrorAnestesistas] = useState();
    const handleResponse = response =>{
        setAnestesistas(response.data.data);
        setCargandoAnestesistas(false);
    }

    useEffect(() =>  {
        setCargandoAnestesistas(true);

        apiGet(`api/formulario/anestesistas`)
            .then(handleResponse)
            .catch(error => {
                setErrorAnestesistas("Error inesperado.");
                setCargandoAnestesistas(false);
            })
    },[]);


    return {cargandoAnestesistas, anestesistas, errorAnestesistas};

}
