import { apiPost, getCsrfCookie, reduceErrorMessages } from "api/configApi";
import { useCallback, useState } from "react";

export default function useRegistroRestriccionMedicoTurno(){
    const [medico, setMedico] = useState('');
    const [telefono, setTelefono] = useState('');
    const [registrando,setRegistrando] = useState(null);
    const [errors,setErrors]= useState(null);
    const [noRegistrando, setNoRegistrando] = useState(true);
    const data = {medico,telefono};

    const handleRegistro = () =>{
       apiPost('api/guardarRestriccionMedicoTurno',data)
       .then(handleRegistroResponse)
       .catch(handleErros);
    }

    const handleRegistroResponse = response =>{
        setRegistrando(false);
        setNoRegistrando(false);
        setMedico('');
        setTelefono('');
        setTimeout(() => {
            window.location.reload(false);
        }, 100);

    }

    const handleErros = error => {
        setRegistrando(false);
        setErrors(reduceErrorMessages(error));
    }

    const handleOnChangeMedico = useCallback(evt =>{
        setErrors(null);
        setMedico(evt.target.value);
    },[setMedico]);

    const handleOnChangeTelefono = useCallback(evt =>{
        setErrors(null);
        setTelefono(evt.target.value);
    },[setTelefono]);


    const onSubmit = evt =>{
        evt.preventDefault();
        setRegistrando(true);
        getCsrfCookie().then(handleRegistro);
    }

    return{
        registrando,
        errors,
        state:{
            medico,
            telefono,
        },
        controller:{
            handleOnChangeMedico,
            handleOnChangeTelefono,
            onSubmit
        },
        noRegistrando
    }
}
