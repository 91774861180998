import { useState } from "react";

export default function useBuscarPaciente({onSearch = null, onBuscando = null} = {}){
    const [paciente, setPaciente] = useState();
    const [user, setUser] = useState();

    const handleOnSearch = data => {
        setPaciente(data.paciente);
        setUser(data.user);
        onSearch && onSearch(data);
    }

    const handleBuscando = () => {
        setPaciente(null);
        setUser(null);
        onBuscando && onBuscando();
    }

    const cancelar = () => {
        setPaciente(null);
        setUser(null);
    }

    return {
        paciente,
        user,
        setPaciente,
        setUser,
        handleOnSearch,
        handleBuscando,
        cancelar
    };
}
