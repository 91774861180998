import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import { Alert } from 'react-bootstrap';
import IfRender from './IfRender';
import Cargando from './Cargando';


export default function Carrusel({
    imagenes,
    path,
    alt,
    nombre,
    descripcion,
    cargandoImagenes,
    errorImagenes
}){
    return (
        <>
            <IfRender condicion={cargandoImagenes}>
                <Cargando text="Buscando imágenes" />
            </IfRender>
            <IfRender condicion={!errorImagenes}
                no={() => <Alert variant="danger"> {errorImagenes}</Alert>}>
                <Carousel>
                    { imagenes.map(imagen =>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={imagen[path]}
                                alt={imagen[alt]}
                            />
                            <IfRender condicion={imagen[nombre]}>
                                <Carousel.Caption>
                                    <h3>{imagen[nombre]}</h3>
                                    <p>{imagen[descripcion]}</p>
                                </Carousel.Caption>
                            </IfRender>

                        </Carousel.Item>
                    )}
                </Carousel>
            </IfRender>
        </>

    );
}
