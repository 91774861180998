import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useTraerRestriccionesOs(){
    const [restriccionesInstitucionMutual, setRestriccionesInstitucionMutual] = useState([]);
    const [cargandoRestriccionesInstitucionMutual, setCargandoRestriccionesInstitucionMutual] = useState();
    const [errorRestriccionesInstitucionMutual, setErrorRestriccionesInstitucionMutual] = useState();

    const handleResponse = response =>{
        setRestriccionesInstitucionMutual(response.data);
        setCargandoRestriccionesInstitucionMutual(false);
    }

    useEffect(() =>  {
        setCargandoRestriccionesInstitucionMutual(true);
        apiGet("api/getRestriccionesInstitucion")
            .then(handleResponse)
            .catch(errorRestriccionesInstitucionMutual => {
                setErrorRestriccionesInstitucionMutual("Error inesperado.");
                setCargandoRestriccionesInstitucionMutual(false);
            })
    },[]);

    return {cargandoRestriccionesInstitucionMutual, restriccionesInstitucionMutual, errorRestriccionesInstitucionMutual};
}
