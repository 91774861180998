import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";


export default function useInternaciones(dni,desde,hasta){
    const [internaciones,setInternaciones]=useState(null);
    const [cargando, setCargando] = useState();
    const [error, setError] = useState();

    const handleResponse = response =>{
        setInternaciones(response.data);
        setCargando(false);
    }


    useEffect(() =>  {
        if(dni != -1){
            setCargando(true);
            apiGet(`api/traerInternacionesPaciente?dni=${dni}&desde=${desde}&hasta=${hasta}`)
                .then(handleResponse)
                .catch(error => {
                    setError("Error inesperado.");
                    setCargando(false);
                })
        }

    },[dni, desde, hasta]);


    return {cargando, internaciones, error};
}

