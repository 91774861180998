import React from 'react';

export default function FormRadio({label, name, id, value, onChange, defaultChecked, disabled}){
    return (
        <div className="form-check form-check-inline">
            <input
                onChange={onChange}
                value={value}
                type="radio"
                className="form-check-input"
                id={id}
                name={name}
                defaultChecked={defaultChecked}
                disabled={disabled}/>
            <label className="form-check-label" htmlFor={id}>
                {label}
            </label>
        </div>
    );
}
