import React, { useContext, useState } from 'react';
import InlineForm from 'components/InlineForm';
import DatosPersonales from './DatosPersonales';
import TemaContext from 'providers/Tema';
import SectionWithTitle from 'components/SectionWithTitle';
import Cuestionario from './Cuestionario';
import useFormularioDatosPersonales from 'hooks/useFormularioDatosPersonales';
import Boton from 'styledComponents/Boton.style';
import useFormulario from 'hooks/useFormulario';
import IfRender from 'components/IfRender';
import Cargando from 'components/Cargando';
import MensajeExito from 'components/MensajeExito';
import Alert from 'components/Alert';

export default function FormularioPreAnestesico({id_formulario}){
    const {state, controller} = useFormulario(id_formulario);
    const {
        datosConsulta,
        controller:controllerDatosPersonales,
        mensajeError
    }  = useFormularioDatosPersonales(state.pacienteDniCompletado);
    const theme = useContext(TemaContext);
    const [validado, setValidado] = useState(false)

    const handleCheck = () => setValidado(validadoPrev => !validadoPrev)


    const onSubmit = evt => {
        evt.preventDefault();
        controller.submit(datosConsulta, id_formulario, validado);
    }

    return (

        <SectionWithTitle title={'Consulta Preanestésica'}>
            <IfRender
                condicion={!state.cargandoFormulario}
                no={() => <Cargando text="Cargando formulario"/>}
            >
                <IfRender condicion={state.error}>
                    <Alert>
                        {state.error}
                    </Alert>
                    <Boton theme={theme} className="btn btn-primary w-100 mt-2" onClick={onSubmit}>
                        Reintentar
                    </Boton>
                </IfRender>
                <IfRender condicion={state.mensajeFormularioEnviado}>
                    <FormularioEnviado mensaje={state.mensajeFormularioEnviado} />
                </IfRender>
                <IfRender
                    condicion={!state.enviando}
                    no={() => <Cargando text="Enviando formulario" />}
                >
                    <IfRender condicion={!state.error && !state.mensajeFormularioEnviado}>
                        <h5 className="ml-2">Formulario del Paciente</h5>
                        <p className="ml-2 text-danger">Los campos marcados con (*) son obligatorios</p>
                        <IfRender condicion={mensajeError}>
                            <Alert variant="warning">
                                {mensajeError}
                            </Alert>
                        </IfRender>
                        <InlineForm onSubmit={onSubmit}>
                            <DatosPersonales
                                formularioCompletado={state.formularioCompletado}
                                state={datosConsulta}
                                controller={controllerDatosPersonales}
                            />
                            <Cuestionario state={state} controller={controller} />
                            <IfRender condicion={state.forAnestesista}>
                                <div className="d-flex my-2 shadow border p-4 rounded">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value={validado}
                                        onChange={handleCheck}
                                        id="validado"
                                        name="validado"
                                    />
                                    <label class="form-check-label" htmlFor="validado">
                                        <strong className="text-uppercase text-center">
                                            Validar formulario
                                        </strong>
                                    </label>
                                </div>
                            </IfRender>
                            <Boton theme={theme} className="btn btn-primary w-100 mt-2" disabled={mensajeError?true:false}>
                                Enviar formulario
                            </Boton>
                        </InlineForm>
                    </IfRender>
                </IfRender>
            </IfRender>
        </SectionWithTitle>
    );
}

const FormularioEnviado = ({mensaje}) => (
    <div className="p-4">
        <MensajeExito mensaje={mensaje} />
        <SectionWithTitle
            title="Indicación para el día de la cirugía:"
            className="mt-4"
        >
            <div className="px-2">
                <p>8 hs de ayuno (nada de sólidos ni líquidos).</p>
                <p>El día previo tomar la medicación habitual ( NO ASPIRINAS).</p>
                <p>Quitarse la prótesis dental antes de ir a quirofano.</p>
                <p>No ir a quirofano con uñas pintadas.</p>
            </div>
        </SectionWithTitle>
    </div>
);
