import Alert from 'components/Alert';
import CardPerfil from 'components/CardPerfil';
import Cargando from 'components/Cargando';
import TemaContext from 'providers/Tema';
import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import Boton from 'styledComponents/Boton.style';
import MensajeToast from 'components/MensajeToast';

export default function AgregarNuevoFamiliar({familiar, onAgregar, error, cargando, agregado}){
    const tema = useContext(TemaContext);

    if(error){
        return <Alert>{ error }</Alert>
    }

    if(cargando){
        return <Cargando text="Agregando" />
    }

    if(agregado){
        const celular = familiar.telefono.slice(familiar.telefono.length - 3);
        const mensaje = `Se ha enviado un mensaje de confirmación al siguiente número finalizado en ${celular}.
        Si no lo reconoce deberá acercarse a la secretaría del S. Boratti para modificarlo.`;    
        
        if(familiar.edad >= 18){
            return <>
                <MensajeToast title="Grupo familiar" mensaje={mensaje}/>
            </>
        }
        return <MensajeToast title="Grupo familiar" mensaje="Familiar agregado exitosamente" />
    }

    if(familiar){
        return (
            <>
            <CardPerfil datos={familiar} />
            <ThemeProvider theme={tema}>
                <Boton className="btn w-100" secondary onClick={onAgregar}>
                    Añadir al grupo
                </Boton>
            </ThemeProvider>
            </>
        );
    }
    return '';
}
