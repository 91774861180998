import React from 'react';
import LayoutPrincipal from 'layouts/LayoutPrincipal';
import LoginLayout from 'layouts/LoginLayout';
import ConfirmPasswordForm from 'components/login/ConfirmPasswordForm';

export default function ConfirmPasswordPage(){
    return (
        <LayoutPrincipal>
            <LoginLayout title="Agregue una nueva contraseña">
                Cambie su contraseña provisoria por una nueva
                <ConfirmPasswordForm />
            </LoginLayout>
        </LayoutPrincipal>
    );
}
