import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useTaerPreparacion(prestacion){
    const [preparacion, setPreparacion] = useState(null);
    const [cargandoPreparacion, setCargandoPreparacion] = useState(true);
    const [errorPreparacion, setErrorPreparacion] = useState();
    const handleResponse = response =>{
        setPreparacion(response.data[0]);
        setCargandoPreparacion(false);
    }

    useEffect(() => {
        setCargandoPreparacion(true);
        apiGet(`api/turnos/TraerDatosPrestacion/${prestacion}`)
            .then(handleResponse)
            .catch(error => {
                setErrorPreparacion("Error inesperado.");
                setCargandoPreparacion(false);
            })
    },[prestacion]);


    return {cargandoPreparacion, preparacion, errorPreparacion};

}
