import { apiPost, getCsrfCookie, reduceErrorMessages } from "api/configApi";
import { useCallback, useState } from "react";

export default function useRegistroMedicoPrestacion(){
    const [dni, setDni] = useState('');
    const [idPrestacion, setIdPrestacion] = useState('');
    const [registrando,setRegistrando] = useState(null);
    const [errors,setErrors]= useState(null);
    const [noRegistrando, setNoRegistrando] = useState(true);
    const data = {dni,idPrestacion};


    const handleRegistro = () =>{
       apiPost('api/guardarRestriccionMedicoPrestacion',data)
       .then(handleRegistroResponse)
       .catch(handleErros);
    }

    const handleRegistroResponse = response =>{
        setRegistrando(false);
        setNoRegistrando(false);
        setDni('');
        setIdPrestacion('');
        setTimeout(() => {
            window.location.reload(false);
        }, 100);

    }

    const handleErros = error => {
        setRegistrando(false);
        setErrors(reduceErrorMessages(error));
    }

    const handleOnChangeMedico = useCallback(evt =>{
        setErrors(null);
        setDni(evt.target.value);
    },[setDni]);

    const handleOnChangePrestacion = useCallback(evt =>{
        setErrors(null);
        setIdPrestacion(evt.target.value);
    },[setIdPrestacion]);


    const onSubmit = evt =>{
        evt.preventDefault();
        setRegistrando(true);
        getCsrfCookie()
                .then(handleRegistro);
    }

    return{
        registrando,
        errors,
        state:{
            dni,
            idPrestacion
        },
        controller:{
            handleOnChangeMedico,
            handleOnChangePrestacion,
            onSubmit
        },
        noRegistrando
    }
}
