import { apiGet, apiPost } from "api/configApi";
import { UserContext } from "providers/UserProvider";
import { useContext, useEffect, useState } from "react";
import url from "utils/url";
import { useLocation } from "wouter";

export default function useLogin(
    name, password, recordado, endpoint
){
    const setLocation = useLocation()[1];
    const [validando, setValidando] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const {addUser} = useContext(UserContext);

    useEffect(() => setInvalid(false), [name, password]);

    const getCsrfCookie = () => apiGet('sanctum/csrf-cookie');

    const loginUser = () => {
        const formulario = {
            'name': name,
            'password': password,
            'remember': recordado
        };
        return apiPost(endpoint, formulario);
    }

    const handleLoginResponse = response => {
        const user = response.data;
        setValidando(false);
        addUser(user);
        setLocation(url(user.default_url_home));
    }

    const handleErrorResponse = error => {
        setValidando(false);
        switch(error.response.status){
            case 422:
                setInvalid(true);
            break;
            case 500:
                alert('Se ha producido un error en el servidor. Vuelva a intentar iniciar sesión');
            break;
            default:
                console.log(error.response.error);
        }
    }

    const login = () => {
        setValidando(true);
        getCsrfCookie()
            .then(loginUser)
            .then(handleLoginResponse)
            .catch(handleErrorResponse);
    }

    return {validando, invalid, login}
}
