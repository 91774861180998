import { apiPost } from "api/configApi";
import { useEffect } from "react";
import { useState } from "react";

export default function useSustituirEmailHp( paciente, onChange ){
    const [ errors, setErrors ] = useState();
    const [ cargando, setCargando ] = useState(false);
    const [ message, setMessage ] = useState();

    const data = {
        'dni' : paciente.dni,
        'email': paciente.email
    };

    const handleResponse = response => {
        setCargando(false);
        setMessage(response.data);
    }

    useEffect(() => {
        if ( onChange ) {
            setCargando(true);
            apiPost(`api/modificarEmailDesdeHp`,data).then(handleResponse).catch(setErrors);
        }
    }, [onChange]);


    return { cargando, message , errors };
}