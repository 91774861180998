import React from "react"
import ContentLoader from "react-content-loader"

const LoaderMenu = (props) => (
  <ContentLoader
    speed={2}
    width={89}
    height={55}
    viewBox="0 0 55 55"
    backgroundColor="#f3f3f3"
    foregroundColor="#cc3333"
    {...props}
  >
    <rect x="0" y="49" rx="3" ry="3" width="52" height="6" />
    <circle cx="25" cy="25" r="20" />
  </ContentLoader>
)

export default LoaderMenu
