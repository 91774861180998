import { apiGet } from "api/configApi";
import IfRender from "components/IfRender";
import SectionWithTitle from "components/SectionWithTitle";
import BotonTurnoMedico from "components/turnos/BotonTurnoMedico";
import useTurnosDispobiles from "hooks/turnos/useTurnosDispobiles";
import LayoutConMenuLateral from "layouts/LayoutConMenuLateral";
import React from "react";
import { useState, useContext } from "react";
import Boton from 'styledComponents/Boton.style';
import TemaContext from 'providers/Tema';
import useMutuales  from 'hooks/turnos/useMutuales'

export default function SeleccionarturnoPage({params}){
    //parametros = idPersona, mutual ,centro,servicio,prestacion,medico,isConsalud,coseguro
    const tema = useContext(TemaContext);
    const [fecha, setFecha] = useState(formatFecha(new Date()));
    const [fechaMostrar, setFechaMostrar] = useState(formatFechaMostrar(new Date()));

    function formatFecha(fecha){
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(fecha);
        const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(fecha);
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(fecha);
        return `${ye}${mo}${da}`;
    }

    function formatFechaMostrar(fecha){
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(fecha);
        const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(fecha);
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(fecha);
        return `${ye}-${mo}-${da}`;
    }

    const [medico,setMedico]= useState(params.medico);

    const { cargandoOpciones, turnos , setTurnos } = useTurnosDispobiles( params.servicio,medico,fecha,params.prestacion,params.idPersona,params.mutual);

    const formRef = React.useRef();

    function handleSubmit(evt) {
        evt.preventDefault();
        const formData = new FormData(formRef.current);
        const values = Object.fromEntries(formData);
        setFecha(formatFecha(new Date(values.fecha+'T00:00:00')));
        setFechaMostrar(values.fecha);

        apiGet(`api/turnos/TraerTurnos/${params.servicio}/${medico}/${formatFecha(new Date(values.fecha+'T00:00:00'))}/${params.prestacion}/${params.idPersona}/${params.mutual}`)
        .then(response => {
            setTurnos(response.data);
        });
    }

    return(
        <LayoutConMenuLateral>
            <div className="container-fluid">
                <div className="row">
                    <IfRender condicion={ !cargandoOpciones && turnos.length > 0}>
                            <div className="col-md-4">
                                <SectionWithTitle title={'Filtro'}>
                                    <form onSubmit={handleSubmit} ref={formRef}>
                                        <div className="form-group p-2">
                                            <label htmlFor="fecha">Mostrar turnos desde</label>
                                            <input className="form-control" type="date" name="fecha" defaultValue={fechaMostrar}/>
                                        </div>
                                        <div className="form-group p-2">
                                            <Boton className="btn w-100" theme={tema}>
                                                Filtrar
                                            </Boton>
                                        </div>
                                    </form>
                                </SectionWithTitle>
                            </div>
                    </IfRender>
                    <div className="col-md-8">
                        <SectionWithTitle title={'Turnos Disponibles'}>
                            <IfRender condicion={ !cargandoOpciones } 
                                no={() => 
                                    <div className="alert alert-light mx-4 my-3 text-center text-uppercase">
                                        <span className="text-danger">Cargando ...</span>
                                    </div>                                
                                }>
                                <IfRender condicion={ turnos.length > 0 }
                                    no={ () => 
                                        <div className="alert alert-dark mx-4 my-3 text-center">
                                            No hay turnos disponibles.
                                        </div>
                                    }>
                                    <div className="d-flex flex-wrap scrollable-div">
                                        {turnos.map(turno => (
                                            <BotonTurnoMedico
                                                key={turno.id}
                                                nombreCompleto={turno.nombreCompleto}
                                                fecha={turno.fechaTurno}
                                                hora={turno.horaTurno}
                                                idTurno={turno.id}
                                                propietario={params.idPersona}
                                                prestacion={params.prestacion}
                                                servicio={params.servicio}
                                                centro={params.centro}
                                                mutual={params.mutual}
                                                medico={turno.dni}
                                                isConsalud={params.isConsalud}
                                                coseguro={params.coseguro}
                                            />
                                        ))}
                                    </div>
                                </IfRender> 
                            </IfRender>
                        </SectionWithTitle>
                    </div>
                    <div className='p-1 d-flex justify-content-start'>
                        <a data-toggle="tooltip" data-placement="top" title="Volver Atrás" href="javascript:history.back()" role={"button"} className='btn btn-danger float-right'><i className='bi bi-arrow-left'></i></a>
                    </div>
                </div>
            </div>
        </LayoutConMenuLateral>
    )
}