import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useMetodosDePagoPorCentro(id_centro){
    const [metodos, setMetodos] = useState([]);
    const [cargandoMetodos, setCargandoMetodos] = useState();
    const [errorMetodos, setErrorMetodos] = useState();
    const handleResponse = response =>{
        setMetodos(response.data);
        setCargandoMetodos(false);
    }

    useEffect(() =>  {
        setCargandoMetodos(true);

        apiGet(`api/pagos/metodosDePagoPorCentro/${id_centro}`)
            .then(handleResponse)
            .catch(error => {
                setErrorMetodos("Error inesperado.");
                setCargandoMetodos(false);
            })
    },[id_centro]);


    return {cargandoMetodos, metodos, errorMetodos};

}
