import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useTraerRestriccionesMedicoOS(){
    const [restriccionesPorObraSocial, setRestriccionesPorObraSocial] = useState([]);
    const [cargandoRestriccionesPorObraSocial, setCargandoRestriccionesPorObraSocial] = useState();
    const [errorRestriccionesPorObraSocial, setErrorRestriccionesPorObraSocial] = useState();

    const handleResponse = response =>{
        setRestriccionesPorObraSocial(response.data);
        setCargandoRestriccionesPorObraSocial(false);
    }

    useEffect(() =>  {
            setCargandoRestriccionesPorObraSocial(true);
            apiGet(`api/getRestriccionesMedicoObraSocial`)
                .then(handleResponse)
                .catch(errorRestriccionesPorObraSocial => {
                    setErrorRestriccionesPorObraSocial("Error inesperado.");
                    setCargandoRestriccionesPorObraSocial(false);
                })

    },[]);

    return {cargandoRestriccionesPorObraSocial, restriccionesPorObraSocial, errorRestriccionesPorObraSocial};
}
