import Cargando from 'components/Cargando';
import IfRender from 'components/IfRender';
import React from 'react';

export default function ListaFamiliares(
    {error, buscando, familiares, setGruposFamiliares, ComponenteFamiliar, mensajeCargando}
){
    const handleDelete = id => {
        const familiarParaBorrar = familiar => familiar.id !== id;
        const familiaresActualizados = familiares.filter(familiarParaBorrar);
        setGruposFamiliares(familiaresActualizados);
    };

    const handleOnErrorEliminar = familiar => {
        setGruposFamiliares(familiaresPrev => [
            ...familiaresPrev,
            familiar
        ]);
    }

    return (
        <div className="p-2">
           <IfRender condicion={!error}
                no={() =>
                    <span className="text-center d-block">{ error }</span>
                }>
                <IfRender condicion={!buscando}
                        no={() =>
                            <Cargando text={mensajeCargando}/>
                        }>
                        {familiares.map(
                            familiar => (
                                <ComponenteFamiliar
                                    onDelete={handleDelete}
                                    onError={handleOnErrorEliminar}
                                    key={familiar.id}
                                    familiar={familiar}
                                />
                            )
                        )}
                </IfRender>
           </IfRender>
        </div>
    );
}

