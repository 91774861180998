import { apiPost } from "api/configApi";

export default function useLogout(){
    const redirectToLogin = (response, onLogout) => {
        if(response.status === 200){
            onLogout();
        }
    };

    const logout = onLogout => {
        apiPost('api/logout')
             .then(response => redirectToLogin(response, onLogout))
             .catch(() => alert('Error al cerrar la sesión'));
    }

    return { logout };
}


