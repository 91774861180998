import ConfirmDialog from 'components/ConfirmDialog';
import IfRender from 'components/IfRender';
import useResetPassword from 'hooks/useResetPassword';
import TemaContext from 'providers/Tema';
import React, { useContext, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import Boton from 'styledComponents/Boton.style';

export default function BlanquearEmail({ user }){
    const tema = useContext(TemaContext);
    const [ onChange, setOnChange ] = useState(false);
    const [ confirmar, setConfirmar ] = useState(false);
    const { cargando, message, errors } = useResetPassword(user, onChange);
    const mensaje = "La siguiente acción enviará un email a "+ user.email + " para reestablecer la contraseña. El email puede llegar a SPAM.";

    const showModal = () => setConfirmar(true);
    const handleCancelar = () => setConfirmar(false);

    const enviar = () => {
        setOnChange(true);
        handleCancelar(false);
    };

    return(
        <div className="my-1">
            <IfRender condicion={!cargando} no={() => <p className='text-center'>Enviando email...</p>}>
                <IfRender condicion={!message}
                    no={() => 
                        <p className='text-center alert-success'>{ message }</p>
                    }>
                    <ThemeProvider theme={tema}>
                        <Boton className="btn w-100" secondary onClick={showModal}>
                            Restablecer contraseña
                        </Boton>
                    </ThemeProvider>
                    <ConfirmDialog
                        abrir={confirmar}
                        titulo="Emparejar email"
                        mensaje={mensaje}
                        onAceptar={enviar}
                        onCancelar={handleCancelar}
                        onHide={handleCancelar}
                    />
                </IfRender>
            </IfRender>
        </div>
    );
}