import TelefonoArgentino from 'utils/telefonos_argentinos';

export default class ValidadorTelefono{
    constructor({id, onValid, onInvalid, onReset}){
        this._onValid = onValid;
        this._onInvalid = onInvalid;
        this._onReset = onReset;
        this._inputId = id;
        this.registrarEventoOnInput();
    }

    registrarEventoOnInput(){
        this.inputTelefono.addEventListener(
            'keyup',
            this.validarCuandoSeIngresanDiezNumeros.bind(this)
        );
        this.inputTelefono.addEventListener(
            'focusout',
            this.validar.bind(this)
        );
    }

    validarCuandoSeIngresanDiezNumeros(){
        if(this.seAlcanzoLongitudMaxima()){
            this.trimInputToDiezCaracteres();
            this.validar();
        }
        else{
            this.reset();
        }
    }

    seAlcanzoLongitudMaxima(){
        return this.inputTelefono.value.length >= this.inputTelefono.maxLength;
    }

    trimInputToDiezCaracteres(){
        this.inputTelefono.value = this.inputTelefono.value.slice(
            0, this.inputTelefono.maxLength
        );
    }

    validar(){
        const validadorTelefono = new TelefonoArgentino(this.inputTelefono.value);
        if(validadorTelefono.isValid()){
            this.onValid(this.inputTelefono.value, validadorTelefono);
        }
        else{
            this.inputTelefono.classList.add('is-invalid');
            this.onInvalid(this.inputTelefono.value, validadorTelefono);
        }
    }

    onValid(inputTelefonoValue, validadorTelefono){
        this._onValid && this._onValid(inputTelefonoValue, validadorTelefono);
    }

    onInvalid(inputTelefonoValue, validadorTelefono){
        this._onInvalid && this._onInvalid(inputTelefonoValue, validadorTelefono);
        this.inputTelefono.value = null;
    }

    reset(){
        this.inputTelefono.classList.remove('is-invalid');
        this.onReset();
    }

    onReset(){
        this._onReset && this._onReset();
    }

    get inputTelefono(){
        if(!this._inputTelefono){
            this._inputTelefono = document.getElementById(this._inputId);
            this._inputTelefono.maxLength = 10;
        }
        return this._inputTelefono;
    }

}
