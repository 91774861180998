import React, { useState } from "react";
import LayoutConMenuLateral from "layouts/LayoutConMenuLateral";
import SectionWithTitle from "components/SectionWithTitle";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import {Dialog} from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { Modal, Row } from 'react-bootstrap';
import RegistroRestriccionInstitucion from 'components/configuracion/RegistroRestriccionInstitucion';
import useTraerRestriccionesOs from "hooks/configuracion/RestricionInstitucionObraSocial/useTraerRestriccionesOs";
import useEliminarRestriccionOS from "hooks/configuracion/RestricionInstitucionObraSocial/useEliminarRestriccionOS";
import IfRender from "components/IfRender";
import Alert from "components/Alert";

export default function PageConfiguracionInstitucionOS(){
    const {cargandoRestriccionesInstitucionMutual, restriccionesInstitucionMutual, errorRestriccionesInstitucionMutual} = useTraerRestriccionesOs();
    const [restriccion,setRestriccion] = useState([]);
    const [deleteRestriccionDialog, setDeleteRestriccionDialog] = useState(false);
    const [, setMensajeEliminar] = useState();
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false)
    };
    const handleOnErrorEliminar = (mensaje) => {
        setMensajeEliminar(mensaje);
    }
    
    const eliminar = useEliminarRestriccionOS({restriccion,setMensajeEliminar,handleOnErrorEliminar});

    const columns = [
        {field:'institucion',header:'Institución'},
        {field:'obraSocial',header:'Mutual Admitida'},
        {field:'descripcion',header:'Descripcion'},
        {field:'fecha',header:'Fecha'},
        {field:'accion',header:'Accion'}];

    const [filters,setFilters]= useState({
        global:{value:null, matchMode: FilterMatchMode.CONTAINS},
    })


    const hideDeleteRestriccionDialog = () => {
        setDeleteRestriccionDialog(false);
    }

    const confirmDeleteRestriccion = (rest) => {
        setRestriccion(rest);
        setDeleteRestriccionDialog(true);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDeleteRestriccion(rowData)} />
            </React.Fragment>
        );
    }

    const dynamicColumns = columns.map((col) => {
            switch(col.header){
                case'Accion':{
                    return <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
                };
                default:return <Column key={col.field} field={col.field} header={col.header} sortable/>;
            }
        });


    const deleteRestriccionDialogFooter = (
            <React.Fragment>
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteRestriccionDialog} />
                <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={eliminar} />
            </React.Fragment>
        );

    const paginatorLeft = <Button type="button" className="p-button-text" />;
    const paginatorRight = <Button type="button" className="p-button-text" />;

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <InputText
                    placeholder="Buscar..."
                    onInput={(e)=>
                        setFilters({
                            global:{value: e.target.value,matchMode: FilterMatchMode.CONTAINS},
                        })
                    }
                    className="p-inputtext-sm"
                />
            </React.Fragment>
        )
    }
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nueva admisión" icon="pi pi-plus" className="p-button-info p-button-sm p-button-success" onClick={handleShow} />
            </React.Fragment>
        )
    }
    return(
        <LayoutConMenuLateral>
            <SectionWithTitle title="Configuración de Obras Sociales admitidas por cada Institución">
                <IfRender condicion={!cargandoRestriccionesInstitucionMutual} no={()=>
                    <Alert>Espere un momento...</Alert>
                }>
                        <center>  Las Mutuales que no aparezcan en la lista serán consideradas como <b>NO ADMITIDAS</b></center>
                            <div className="card">
                                <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                                <DataTable value={restriccionesInstitucionMutual} paginator size="small"  sortMode="multiple" responsiveLayout="stack" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Mostrando {first} de {last}. Total: {totalRecords}" rows={10} rowsPerPageOptions={[10,20,50]} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} filters={filters} dataKey="id" emptyMessage="Sin resultados">
                                    {dynamicColumns}
                                </DataTable>
                            </div>
                            <div>
                                <Dialog visible={deleteRestriccionDialog} style={{ width: '450px' }} header="Eliminar Restriccion" modal footer={deleteRestriccionDialogFooter} onHide={hideDeleteRestriccionDialog}>
                                    <div className="confirmation-content">
                                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                                        {restriccion && <span>¿Está seguro/a que quiere eliminar la mutual <b>{restriccion.institucion}</b> ?</span>}
                                    </div>
                                </Dialog>
                                <Modal show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Registrar Admisión</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Row>
                                            <RegistroRestriccionInstitucion/>
                                        </Row>
                                    </Modal.Body>
                                </Modal>
                            </div>
                </IfRender>
            </SectionWithTitle>
        </LayoutConMenuLateral>
    );
}

