import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useProximosTurnos(dni, idPersona){
    const [turnos, setTurnos] = useState([]);
    const [cargandoTurnos, setCargandoTurnos] = useState();
    const [errorTurnos, setErrorTurnos] = useState();
    const [tieneTurnoProximo, setTieneTurnoProximo] = useState(false);

    const handleResponse = response =>{
        setTurnos(response.data);
        setCargandoTurnos(false);
    }

    useEffect(() =>  {
        setCargandoTurnos(true);

        apiGet(`api/turnos/ProximosTurnos/${dni}/${idPersona}`)
        .then(response => {
            handleResponse(response);

            // Verifica si ya tiene un turno proximo
            if (response.data.length >=3 ) {
                setTieneTurnoProximo(true);
            } else {
                setTieneTurnoProximo(false);
            }
        })
        .catch(error => {
            setErrorTurnos("Error inesperado.");
            setCargandoTurnos(false);
        });
}, [dni, idPersona]);

return { cargandoTurnos, turnos, errorTurnos, tieneTurnoProximo };

}
