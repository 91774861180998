import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useTurnosDispobiles(servicio,dniMedico,fecha,prestacion,idPersona,mutual){
    const [cargandoOpciones, setCargandoOpciones] = useState(true);
    const [turnos, setTurnos] = useState([]);

    useEffect(() => {
            setCargandoOpciones(true);
            apiGet(`api/turnos/TraerTurnos/${servicio}/${dniMedico}/${fecha}/${prestacion}/${idPersona}/${mutual}`)
            .then(response => {
                setTurnos(response.data);
                setCargandoOpciones(false);
            });
    }, [servicio,dniMedico,fecha,prestacion,idPersona,mutual]);

    return { cargandoOpciones, turnos, setTurnos };
}
