import React from "react";
import { Card } from "primereact/card";

export default function CardItemIngresosMutuales({registrosMutuales}) {
    return (
        <div className="col-sm-4">
            <div className="card">
                <Card title='Registros por mutual'>
                    {registrosMutuales.map((registroMutual) => (
                        <div className="d-flex align-items-center justify-content-between"> 
                            <small>{registroMutual.mutual}:</small>
                            <p className="m-0 h4 font-weight-bold">{registroMutual.cantidad}</p>
                        </div>
                    ))}
                </Card>
            </div>
        </div>
    );
}