import ConfirmDialog from 'components/ConfirmDialog';
import IfRender from 'components/IfRender';
import useSustituirEmailHp from 'hooks/useSustituirEmailHP';
import TemaContext from 'providers/Tema';
import React, { useContext, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import Boton from 'styledComponents/Boton.style';

export default function ActualizarEmailDesdeHp({ paciente, user }){
    const tema = useContext(TemaContext);
    const [ onChange, setOnChange ] = useState(false);
    const [ confirmar, setConfirmar ] = useState(false);
    const { cargando, message, errors } = useSustituirEmailHp(paciente, onChange);
    const mensaje = "La siguiente acción enviará un email de confirmación a "+ paciente.email + " para reestablecer la contraseña.";

    const showModal = () => setConfirmar(true);
    const handleCancelar = () => setConfirmar(false);
    const emparejar = () => {
        setOnChange(true);
        handleCancelar(false);
    };

    return(
        <div className="mx-1">
            <IfRender condicion={!cargando} no={() => <p className='text-center'>Emparejando...</p>}>
                <IfRender condicion={!message}
                    no={() => <>
                        <p className='text-center alert-success'>{ message }</p>
                        <p className='px-1 text-lowercase'><strong>E-mail:</strong> { paciente.email }</p></>
                    }>
                    <p className='text-center alert-info text-uppercase'>El email no coincide</p>
                    <p className="px-1">
                        <strong>E-mail Portal:</strong> { user.email } <br/>
                        <strong>E-mail Sistema HP:</strong> <span className='text-lowcleaercase'>{ paciente.email }</span>                        
                    </p>
                    <IfRender condicion={paciente.email != ''}>
                        <p className='px-1'>La opción emparejar le permitirá al usuario acceder al portal con el email registrado en el sistema HP.</p>
                        <ThemeProvider theme={tema}>
                            <Boton className="btn w-100" secondary onClick={showModal}>
                                Actualizar mail en Portal
                            </Boton>
                        </ThemeProvider>
                        <ConfirmDialog
                            abrir={confirmar}
                            titulo="Emparejar email"
                            mensaje={mensaje}
                            onAceptar={emparejar}
                            onCancelar={handleCancelar}
                            onHide={handleCancelar}
                        />
                    </IfRender>
                </IfRender>
            </IfRender>
        </div>
    );
}