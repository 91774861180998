import React, { useContext, useState } from 'react';
import Boton from 'styledComponents/Boton.style';
import { ThemeProvider } from 'styled-components';
import TemaContext from 'providers/Tema';
import FormGroup from 'components/FormGroup';
import SkeletonForm from 'components/SkeletonForm';
import useSubmit from 'hooks/useSubmit';

export default function RequestResetPasswordForm() {
    const tema = useContext(TemaContext);
    const [emailEnviado, setEmailEnviado] = useState();
    
    const {
        failed,
        procesando,
        errors,
        controller
    } = useSubmit({
        url: 'api/password/email',
        onReciveData: data => setEmailEnviado(data.message)
    });

    const enviarEmail = evt => {
        evt.preventDefault();
        controller.sendRequest();
    }

    const reenviar = () => setEmailEnviado(null);

    return (
        <SkeletonForm
            invalid={failed}
            validando={procesando}
            handleSubmit={enviarEmail}
            invalidMessage={errors}
            validandoMessage ="Enviando"
            render={() => (
            <>
                { emailEnviado ?
                    <>
                    <p>{ emailEnviado }</p>
                    <ThemeProvider theme={tema}>
                        <Boton className="btn w-100 mt-2" onClick={reenviar}>
                            Reenviar
                        </Boton>
                    </ThemeProvider>
                    </>
                    :
                    <>
                    <FormGroup
                        label="Email"
                        type="email"
                        placeholder="Ingrese su email usado para el registro"
                        name="email"
                        onChange={controller.handleOnChange}
                        required>
                    </FormGroup>
                    <ThemeProvider theme={tema}>
                        <Boton className="btn w-100 mt-2" >
                            Enviar
                        </Boton>
                    </ThemeProvider>
                    </>
                }
            </>
            )}/>
    );
}