import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from 'providers/UserProvider';
import useFamiliares  from 'hooks/turnos/useFamiliares'
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import {addLocale} from 'primereact/api';
import ComboFamiliares from 'components/turnos/ComboFamiliares';
import Combo from 'components/Combo';

export default function SelectPersona({idPersona, setIdPersona,desde,setFechaDesde,hasta,setFechaHasta}){
    const { user } = useContext(UserContext);

    const {cargandoFamiliares, familiares, errorFamiliares} = useFamiliares(user ? user.id : -1);
    const [mostrar, setMostrar] = useState(false);
    const [invalido, setInvalido] = useState(null);
    const [seleccionado, setSeleccionado] = useState(null);

    const handlePropietarioChange = (e) => {
        var seleccion = parseInt(e.target.value);
        setSeleccionado(seleccion);
        setInvalido(null);
        if(seleccion === 2){
            setters(true, -1);
        }else{
            seleccion === 1 && setters(false, user.datos_personales.id_persona);
        }
    }

    const handleFechaDesdeChange = (e) => {
        setFechaDesde(e.target.value);
    }

    const handleFechaHastaChange = (e) => {
        setFechaHasta(e.target.value);
    }

    function setters(mostar, idPersona) {
        setMostrar(mostar);
        setIdPersona(idPersona);
    }


    const [dates,setDates]=useState();

    addLocale('es',{
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'MI', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
        }
    );

    useEffect(()=>{
        if(desde != null){
            setFechaDesde(desde);
            setFechaHasta(hasta);
        }
    },[desde,hasta])

    return(
        <>
            <div className='d-flex flex-wrap'>
                <div className="m-1">
                        <Combo
                            label="Ver internaciones"
                            name="usuarioLogueado"
                            opcionValue="denominacion"
                            opciones={[ {id: 1, denominacion: "Propias"}, {id: 2, denominacion: "De un familiar"} ]}
                            onChange={handlePropietarioChange}
                            defaultValue={seleccionado}
                        />
                    </div>
                    <div className="m-1">
                        <ComboFamiliares
                            familiares={familiares}
                            mostrar={mostrar}
                            cargandoFamiliares={cargandoFamiliares}
                            setIdPersona={setIdPersona}
                            setInvalido={setInvalido}
                        />
                    </div>
                    <div className='m-1'>
                        <div className='form-group px-2'>
                            <label htmlFor="">Desde
                                <input type="date" defaultValue={desde} placeholder='Seleccionar Fecha' className='form-control' onChange={handleFechaDesdeChange}/>
                            </label>
                        </div>
                    </div>
                    <div className='m-1'>
                        <div className='form-group px-2'>
                            <label htmlFor="">Hsta
                                <input type="date" defaultValue={hasta} placeholder='Seleccionar Fecha' className='form-control' onChange={handleFechaHastaChange}/>
                            </label>
                        </div>
                    </div>
            </div>

        </>
    )
}
