import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useValidarAccesoServicios(idPersona, servicio,obraSocial){
    const [accesoServicio, setValidacion] = useState([]);
    const [cargandoAccesoServicio, setCargandoValidacion] = useState();
    const [errorAccesoServicio, setErrorValidacion] = useState();

    const handleResponse = response =>{
        setValidacion(response.data[0]);
        setCargandoValidacion(false);
    }

    useEffect(() =>  {
        if(servicio && servicio !== 0 && idPersona !== -1){
            setCargandoValidacion(true);
            apiGet(`api/turnos/ValidarAccesoServicio/${idPersona}/${servicio}/${obraSocial}`)
                .then(handleResponse)
                .catch(error => {
                    setErrorValidacion("Error inesperado.");
                    setCargandoValidacion(false);
                })
        }
    },[idPersona, servicio,obraSocial]);


    return {cargandoAccesoServicio, accesoServicio, errorAccesoServicio};

}
