import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useTraerRestriccionesMedicosTurnoOnline(){
    const [restriccionesTurnos, setRestriccionesTurnos] = useState([]);
    const [cargandoRestriccionesTurnos, setCargandoRestriccionesTurnos] = useState();
    const [errorRestriccionesTurnos, setErrorRestriccionesTurnos] = useState();

    const handleResponse = response =>{
        setRestriccionesTurnos(response.data);
        setCargandoRestriccionesTurnos(false);
    }

    useEffect(() =>  {
            setCargandoRestriccionesTurnos(true);
            apiGet(`api/getRestriccionesMedicosTurnoOnline`)
                .then(handleResponse)
                .catch(errorRestriccionesTurnos => {
                    setErrorRestriccionesTurnos("Error inesperado.");
                    setCargandoRestriccionesTurnos(false);
                })
    },[]);

    return {cargandoRestriccionesTurnos, restriccionesTurnos, errorRestriccionesTurnos};
}
