import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useImagenesDeFormulario(idFormulario, show){
    const [imagenes, setImagenes] = useState([]);
    const [cargando, setCargando] = useState();
    const [error, setError] = useState();
    const handleResponse = response =>{
        setImagenes(response.data);
        setCargando(false);
    }

    useEffect(() =>  {
        if(show && imagenes.length === 0){
            setCargando(true);
            setError(null);
            apiGet(`api/imagenesFormulario/${idFormulario}`)
                .then(handleResponse)
                .catch(error => {
                    setError("No se encontraron imágenes adjuntas");
                    setCargando(false);
                })
        }
    },[idFormulario, show, imagenes]);

    return {cargando, imagenes, error};
}
