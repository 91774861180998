import SectionWithTitle from "components/SectionWithTitle";
import useTraerMontosPrestacion from "hooks/useTraerMontosPrestacion";
import LayoutConMenuLateral from "layouts/LayoutConMenuLateral";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import React, { useState } from "react";
import { FilterMatchMode } from 'primereact/api';
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Modal, Row } from 'react-bootstrap';
import RegistroMontoPrestacion from "components/configuracion/RegistroMontoPrestacion";
import useEliminarMontoPrestacion from "hooks/configuracion/MontoPrestacion/useEliminarMontoPrestacion";


export default function PageConfiguracionMontosPrestacion(){
    const {montos} = useTraerMontosPrestacion();
    const [monto,setMonto] = useState([]);
    const [show, setShow] = useState(false);
    const [deleteRestriccionDialog, setDeleteRestriccionDialog] = useState(false);
    const [mensajeAnular, setMensajeEliminar] = useState();

    const [filters,setFilters]= useState({
        global:{value:null, matchMode: FilterMatchMode.CONTAINS},
    })

    const handleOnErrorEliminar = (mensaje) => {
        setMensajeEliminar(mensaje);
    }

    const eliminar = useEliminarMontoPrestacion({monto,setMensajeEliminar,handleOnErrorEliminar});

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false)
    };

    const columns = [
        {field:'dniMedico',header:'DNI'},
        {field:'nombreMedico',header:'Nombre'},
        {field:'servicio',header:'Servicio'},
        {field:'prestacion',header:'Prestacion'},
        {field:'monto',header:'Monto'},
        {field:'fecha',header:'Fecha'},
        {field:'accion',header:'Accion'}];

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo monto para Prestación" icon="pi pi-plus" className="p-button-info p-button-sm" onClick={handleShow} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <InputText
                    placeholder="Buscar..."
                    onInput={(e)=>
                        setFilters({
                            global:{value: e.target.value,matchMode: FilterMatchMode.CONTAINS},
                        })
                    }
                    className="p-inputtext-sm"
                />
            </React.Fragment>
        )
    }

    const paginatorLeft = <Button type="button" className="p-button-text" />;
    const paginatorRight = <Button type="button" className="p-button-text" />;

    const actionBodyTemplate = (rowData) => {
            return (
                <React.Fragment>
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteRestriccion(rowData)} />
                </React.Fragment>
            );
    }


    const dynamicColumns = columns.map((col) => {
        switch(col.header){
            case'Accion':{
                return <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
            };
            default:return <Column key={col.field} field={col.field} header={col.header} sortable/>;
        }
    });



    const confirmDeleteRestriccion = (monto) => {
        setMonto(monto);
        setDeleteRestriccionDialog(true);
    }

    const hideDeleteRestriccionDialog = () => {
        setDeleteRestriccionDialog(false);
    }

    const deleteRestriccionDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteRestriccionDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={eliminar} />
        </React.Fragment>
    );

    return(
        <>
            <LayoutConMenuLateral>
                <SectionWithTitle title={"Configuración Montos Prestacion"}>
                    <div className="card">
                        <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                        <DataTable value={montos} paginator size="small"  sortMode="multiple" responsiveLayout="stack" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Mostrando {first} de {last}. Total: {totalRecords}" rows={10} rowsPerPageOptions={[10,20,50]} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} filters={filters} dataKey="id" emptyMessage="Sin resultados">
                            {dynamicColumns}
                        </DataTable>
                    </div>
                    <div>
                        <Dialog visible={deleteRestriccionDialog} style={{ width: '450px' }} header="Eliminar Restriccion" modal footer={deleteRestriccionDialogFooter} onHide={hideDeleteRestriccionDialog}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                                {monto && <span>Estas seguro/a que quieres eliminar esta restriccion<b>{monto.name}</b>?</span>}
                            </div>
                        </Dialog>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Registrar restriccion</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <RegistroMontoPrestacion/>
                                </Row>
                            </Modal.Body>
                        </Modal>
                    </div>
                </SectionWithTitle>
            </LayoutConMenuLateral>
        </>
    );
}
