import React from 'react';
import Combo from 'components/Combo';

export default function ComboCentroDeAtencion({
    centrosDeAtencion,
    handleCentroChange,
    cargando,
    centro
}){
    const centrosDeAtencion1 = [{id: 1,nombre: 'Sanatorio Boratti'}];
    return (
        <Combo
            opciones={centrosDeAtencion1}
            label="Centro de Atención"
            opcionValue="nombre"
            defaultValue={centro}
            cargando={cargando}
            onChange={handleCentroChange}
        />
    );
}
