import { useState } from 'react';
import { apiGet } from 'api/configApi';
import { useEffect } from 'react';

export default function useVisitasMedicas( dni , fechaDesde ){
    const [visitas, setVisitas] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        setCargando(true);
        apiGet(
            `api/traerVisitasMedicas?dni=${dni}&desde=${fechaDesde}`
        )
        .then(response => {
            setVisitas(response.data);
            setCargando(false);
        })
        .catch(() => setError('Se ha producido un error. Vuelva a intentar'));
        
    }, [dni, fechaDesde]);
    
    return {
        visitas,
        cargando,
        error
    };
}
