import { apiPost } from "api/configApi";

export default function useEliminarMontoPrestacion({monto, setMensajeEliminar, handleOnErrorEliminar}){
    const montoBackUp = monto;

    const handleEliminarResponse = (respuesta) =>{
        setMensajeEliminar(respuesta.mensaje);
        setTimeout(() => {
            window.location.reload(false);
        }, 100);
    }

    const eliminar = () => {
        apiPost(`api/eliminarMonto/${monto.id}`)
            .then((response) => handleEliminarResponse(response.data))
            .catch(error => handleOnErrorEliminar(montoBackUp, error.response.data.mensaje))
    };

    return eliminar;
}
