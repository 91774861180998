import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useAdjuntosDeEstudio(id_informe){
    const [adjuntos, setAdjuntos] = useState([]);
    const [cargandoAdjuntos, setCargandoAdjuntos] = useState();
    const [errorAdjuntos, setErrorAdjuntos] = useState();
    const handleResponse = response =>{
        setAdjuntos(response.data);
        setCargandoAdjuntos(false);
    }

    useEffect(() =>  {
        setCargandoAdjuntos(true);
        apiGet(`api/estudios/adjuntos/${id_informe}`)
            .then(handleResponse)
            .catch(error => {
                setErrorAdjuntos("Error inesperado.");
                setCargandoAdjuntos(false);
            })
    },[id_informe]);


    return {cargandoAdjuntos, adjuntos, errorAdjuntos};

}
