import styled, { keyframes } from 'styled-components';

const girar = keyframes`
    0%{
        transform: rotate(0deg);
    }

    12.5%{
        transform: rotate(45deg);
    }

    25%{
        transform: rotate(90deg);
    }

    37.5%{
        transform: rotate(135deg);
    }

    50%{
        transform: rotate(180deg);
    }

    62.5%{
        transform: rotate(225deg);
    }

    75%{
        transform: rotate(270deg);
    }

    87.5%{
        transform: rotate(315deg);
    }

    100%{
        transform: rotate(360deg);
    }
`;

export const SpinnerCargando = styled.div`
    border: 4px solid;
    border-top-color: ${props => props.theme.secundario};
    border-right-color: ${props => props.theme.primario};
    border-bottom-color: ${props => props.theme.secundario};
    border-left-color: ${props => props.theme.primario};
    border-radius: 50%;
    height: 30px;
    width: 30px;
    animation: ${girar} 500ms ease infinite;
`;
