import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export function useEstadisticaMutualesAltaUsuarios(desde,hasta){
    const [estadisticasMutualesAltaUsuarios, setEstadisticasMutualesAltaUsuarios] = useState([]); 
    const [cargandoEstadisticasMutualesAltaUsuarios, setCargandoEstadisticasMutualesAltaUsuarios] = useState(true);
    const [errorEstadisticasMutualesAltaUsuarios, setErrorEstadisticasMutualesAltaUsuarios] = useState();

    const handleResponse = response =>{
        setEstadisticasMutualesAltaUsuarios(response.data);
        setCargandoEstadisticasMutualesAltaUsuarios(false);
    }

    useEffect(() =>  {
        setCargandoEstadisticasMutualesAltaUsuarios(true);
        apiGet(`api/mutualesAltaUsuarios?desde=${desde}&hasta=${hasta}`)
            .then(handleResponse)
            .catch(errorEstadisticasMutualesAltaUsuarios => {
                setErrorEstadisticasMutualesAltaUsuarios("Error inesperado.");
                setCargandoEstadisticasMutualesAltaUsuarios(false);
            });
    },[desde,hasta]);

    return {cargandoEstadisticasMutualesAltaUsuarios, estadisticasMutualesAltaUsuarios, errorEstadisticasMutualesAltaUsuarios};
}