import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TooltipBoton = ({mensaje, children, show}) => {
    if(!mensaje){
        return children;
    }
    return (
        <OverlayTrigger
            placement="top"
            show={show}
            overlay={<Tooltip>{ mensaje }</Tooltip>}>
            { children }
        </OverlayTrigger>
    );
}

export default TooltipBoton;
