import React from 'react';
import Alert from './Alert';
import Cargando from './Cargando';
import IfRender from './IfRender';
import InlineForm from './InlineForm';

export default function SkeletonForm(
    {invalid, render, validando, handleSubmit, invalidMessage, validandoMessage
}) {
    return (
        <>
        <IfRender condicion={invalid}>
            <Alert>{ invalidMessage }</Alert>
        </IfRender>
        <InlineForm onSubmit={handleSubmit}>
            <IfRender condicion={validando} no={() => render() }>
                <Cargando text={validandoMessage}/>
            </IfRender>
        </InlineForm>
        </>
    );
}
