import React, { useContext, useState } from 'react';
import LayoutPrincipal from 'layouts/LayoutPrincipal';
import SectionWithTitle from 'components/SectionWithTitle';
import TemaContext from 'providers/Tema';
import { Container } from 'react-bootstrap';
import SkeletonForm from 'components/SkeletonForm';
import FormGroup from 'components/FormGroup';
import Boton from 'styledComponents/Boton.style';
import useSubmit from 'hooks/useSubmit';
import MensajeToast from 'components/MensajeToast';
import IfRender from 'components/IfRender';
import Cargando from 'components/Cargando';
import Alert from 'components/Alert';

export default function TestsPage() {
    const [mensajeEnviado, setMensajeEnviado] = useState();
    return (
        <LayoutPrincipal>
            <IfRender condicion={mensajeEnviado}>
                <MensajeToast
                    title="Enviar mensaje"
                    mensaje={mensajeEnviado}
                    onHide={() => setMensajeEnviado(null)}
                />
            </IfRender>
            <Container className="mt-4">
                <SeccionEnviarMensaje setMensajeEnviado={setMensajeEnviado} />
                <SeccionrefreshDatabase setMensajeEnviado={setMensajeEnviado} />
                <h6>prueba de comando</h6>
            </Container>
        </LayoutPrincipal>
    );
}

const SeccionEnviarMensaje = ({setMensajeEnviado}) => {
    const tema = useContext(TemaContext);

    const {
        failed,
        procesando,
        errors,
        controller
    } = useSubmit({
        url: 'api/tests/enviarMensaje',
        onReciveData: data => setMensajeEnviado(data.mensaje)
    });

    const enviarMensaje = evt => {
        evt.preventDefault();
        controller.sendRequest();
    }

    return (
        <SectionWithTitle title="Mensajes portal">
        <SkeletonForm
            invalid={failed}
            validando={procesando}
            handleSubmit={enviarMensaje}
            invalidMessage={errors}
            validandoMessage ="Enviando mensaje"
            render={() => (
                <>
                <FormGroup
                    label="DNI"
                    type="number"
                    name="dni"
                    onChange={controller.handleOnChange}
                    required>
                </FormGroup>
                <FormGroup
                    label="Teléfono"
                    type="number"
                    name="telefono"
                    onChange={controller.handleOnChange}
                    required>
                </FormGroup>
                <Boton className="btn w-100 mt-2" theme={tema}>
                    Enviar mensaje
                </Boton>
                </>
            )}
        />
        </SectionWithTitle>
    );
}

const SeccionrefreshDatabase = ({setMensajeEnviado}) => {
    const tema = useContext(TemaContext);

    const {
        failed,
        procesando,
        errors,
        controller
    } = useSubmit({
        url: 'api/tests/refreshDataBase',
        onReciveData: data => setMensajeEnviado(data.mensaje)
    });

    const resetDatabase = evt => {
        evt.preventDefault();
        controller.sendRequest();
    }

    return (
        <SectionWithTitle className="my-4" title="Bases de datos">
            <IfRender condicion={failed}>
                <Alert>
                    { errors }
                </Alert>
            </IfRender>
            <IfRender
                condicion={!procesando}
                no={ () =>  <Cargando text="Reiniciando base de datos" /> }
            >
            <Boton className="btn w-100 mt-2" onClick={resetDatabase} theme={tema}>
                Reiniciar base de datos
            </Boton>
            </IfRender>
        </SectionWithTitle>
    );
}


