import { useState, useRef, useEffect } from "react";
import loadImage from 'blueimp-load-image';
import { apiPost } from 'api/configApi';

const imageConfig = {
    orientation: false,
    maxWidth: 500,
    maxHeight: 200,
    canvas: true
};

function handleImage(canvas) {
    if(this){
        this.src = canvas.toDataURL();
    }
}

export default function useValidacionFotoDni(validadorUrl){
    const [validando, setValidando] = useState(false);
    const [validacionMensaje, setValidacionMensaje] = useState('');
    const [validado, setValidado] = useState(null);
    const [frente, setFrente] = useState(null);
    const [reverso, setReverso] = useState(null);

    const fotoFrente = useRef();
    const fotoReverso = useRef();

    useEffect(() => {
        frente && cargarImagen(frente, fotoFrente);
        reverso && cargarImagen(reverso, fotoReverso);
    });

    const cargarImagen = (archivoImagen, imageRef) => {
        loadImage(
            archivoImagen,
            handleImage.bind(imageRef.current) ,
            imageConfig
        );
    }

    const onSelectFrente = files => {
        setFrente(files[0]);
    }

    const onSelectReverso = files => {
        setReverso(files[0]);
    }

    const isDisabled = () => !(frente && reverso);

    const handleResponsevalidacion = response => {
        setValidando(false);
        setValidado(response.data.validado);
        setValidacionMensaje(response.data.msg);
    }

    const validar = () => {
        setValidando(true);
        setValidado(false);
        apiPost(
            validadorUrl,
            createFormData()
        ).then(handleResponsevalidacion)
        .catch(() => {
            setValidando(false);
            setValidado(false);
            setValidacionMensaje('Algo salió mal. Vuelva a intentar');
        });
    }

    const createFormData = () => {
        const data = new FormData();
        data.append('frontal', frente);
        data.append('reverso', reverso);
        return data;
    }

    return {
        validado,
        validacionMensaje,
        frente,
        reverso,
        onSelectFrente,
        fotoFrente,
        onSelectReverso,
        fotoReverso,
        validar,
        isDisabled,
        validando
    }
}
