import React, { useContext, useState } from 'react';
import LayoutPrincipal from 'layouts/LayoutPrincipal';
import SectionWithTitle from 'components/SectionWithTitle';
import TemaContext from 'providers/Tema';
import { Container } from 'react-bootstrap';
import { ThemeProvider } from 'styled-components';
import Boton from 'styledComponents/Boton.style';
import useBuscarNombrePersona from 'hooks/useBuscarNombrePersona'
import IfRender from 'components/IfRender';
import Cargando from 'components/Cargando';
import { apiGet } from "api/configApi";
import { Alert } from 'react-bootstrap';

export default function ConfirmarPertenezcoGrupoFamiliar({params}) {
    const tema = useContext(TemaContext);
    const {nombre, cargandoNombre} = useBuscarNombrePersona(params.dniPropietario)
    const [rechazado, setRechazado] = useState(false);
    const [aceptado, setAceptado] = useState(false);
    const [error, setError] = useState(false)

    const onRechazado = () =>{
        setRechazado(true)
    }

    const onAceptado = () =>{
        setAceptado(true)
    }

    const rechazar = () => {
        apiGet(`api/rechazarSolicitud/${params.dniPropietario}/${params.dni}/${params.tokenRechazar}`)
            .then(onRechazado)
            .catch(() => {setError(true)})
    };

    const aceptar = () => {
        apiGet(`api/aceptarSolicitud/${params.dniPropietario}/${params.dni}/${params.tokenAceptar}`)
            .then(onAceptado)
            .catch(() => {setError(true)})
    };

    return (
        <LayoutPrincipal>

            <Container className="mt-4">
                <IfRender condicion={error}
                    no={() =>
                        <SectionWithTitle tema={tema} title="Confirmación de Pertenecencia de Grupo Familiar">
                            <IfRender condicion={ aceptado || rechazado }
                                no={() =>
                                    <>
                                        <IfRender condicion={cargandoNombre}>
                                            <Cargando text="Cargando" />
                                        </IfRender>
                                        <p className="ml-2 mr-2">El Sr/a {nombre} ha solicitado unirlo a su grupo familiar, está usted de acuerdo?</p>
                                        <p className="ml-2 mr-2"><b>IMPORTANTE:</b> si usted acepta {nombre} podrá ver sus estudios y solicitar turnos a su nombre.</p>
                                        <ThemeProvider theme={tema}>
                                            <div className="m-2">
                                                <Boton className="btn w-100 col-12 col-md-6" secondary onClick={rechazar}>
                                                    Rechazar
                                                </Boton>
                                                <Boton className="btn w-100 col-12 col-md-6" onClick={aceptar}>
                                                    Aceptar
                                                </Boton>
                                            </div>
                                        </ThemeProvider>
                                    </>
                                }>
                                <p>Se ha realizado la operación correctamente.</p>
                            </IfRender>
                        </SectionWithTitle>
                    }>
                    <Alert variant="danger"> Ha ocurrido un error. Por favor vuelva a intentarlo en un momento. </Alert>
                </IfRender>
            </Container>
        </LayoutPrincipal>
    );
}

