import { useState, useEffect, useReducer, useContext } from 'react'
import { apiGet, apiPost } from 'api/configApi';
import {
    updateFormulario,
    updateCompletos,
    cargandoFormulario,
    onSubmit,
    errorSubmit,
    successSubmit,
    updateEstudios,
    reducerFormulario,
    initialState
} from './reducers/reducerFormulario';
import { UserContext } from 'providers/UserProvider';

export default function useFormulario(id_formulario){
    const [stateReduced, dispatch] = useReducer(reducerFormulario, initialState);

    const { user } = useContext(UserContext);

    const [respuestas, setRespuestas] = useState([]);

    const state = {
        ...stateReduced,
        respuestas,
        forAnestesista: user,
    };

    useEffect(() => {
        const promesas = [];
        cargandoFormulario(dispatch);
        const preguntas = apiGet('api/formulario/anestesistas/preguntas');
        promesas.push(preguntas);
        if(id_formulario){
            const requestCompletados = apiGet(
                `api/formulario/completo/${id_formulario}`
            )
            promesas.push(requestCompletados)
        }

        Promise.all(promesas).then(
            promesaResuelta => {
                updateFormulario(dispatch, promesaResuelta[0]);
                promesaResuelta[1] && updateCompletos(dispatch, promesaResuelta[1]);
            }
        );
    }, [id_formulario]);

    const handleOnChangeFiles = elements => updateEstudios(dispatch, elements)

    const handleOnChangeAnswer = evt => {
        const respuestaActual = JSON.parse(evt.target.value);
        const filtrarPregunta = respuesta => respuesta.pregunta_id !== respuestaActual.pregunta_id;
        setRespuestas(prevRespuestas => [
            ...prevRespuestas.filter(filtrarPregunta),
            respuestaActual
        ]);
    }

    const handleOnChangeRespuestaLibre = evt => {
        const pregunta_id = evt.target.name;
        const respuesta = evt.target.value;
        setRespuestas(prevRespuestas => [
            ...prevRespuestas.filter(
                respuesta => pregunta_id !== respuesta.pregunta_id
            ),
            {pregunta_id, respuesta}
        ]);
    }

    const makeDataForSumbit = (datosConsulta, formulario_id, validado) => {
        const data = new FormData();
        state.files.forEach(file => data.append('estudios[]', file));
        data.append('respuestas', JSON.stringify(state.respuestas));
        data.append('paciente_dni', datosConsulta.paciente_dni);
        data.append('anestesista_dni', datosConsulta.anestesista_dni);
        data.append('fecha_consulta', datosConsulta.fechaDeConsulta);
        data.append('paciente_nombre', datosConsulta.nombre);
        data.append('anestesista_nombre', datosConsulta.anestesista);
        data.append('fecha_nacimiento_paciente', datosConsulta.fechaNacimiento);
        data.append('edad_paciente', datosConsulta.edad);
        data.append('turno_id', datosConsulta.turno_id);
        data.append('telefono', datosConsulta.telefono);
        data.append('validado', validado);
        formulario_id && data.append('formulario_id', formulario_id);
        return data;
    }

    const submit = async (datosConsulta, formulario_id, validado) => {
        onSubmit(dispatch);
        try {
            const response = await apiPost(
                'api/formulario/anestesistas',
                makeDataForSumbit(datosConsulta, formulario_id, validado)
            );
            successSubmit(dispatch, response);
        }
        catch(error){
            errorSubmit(dispatch, error);
        }
    }

    return {
        state,
        controller: {
            handleOnChangeAnswer,
            handleOnChangeRespuestaLibre,
            handleOnChangeFiles,
            submit
        }
    }
}
