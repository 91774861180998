import React from 'react';
import { Alert } from 'react-bootstrap';

const DniValidadoAlert = ({validado, validacionMensaje}) => {
    const variante = validado ? 'success' : 'danger';
    if(validado !== null){
        return <Alert variant={variante}> { validacionMensaje } </Alert>
    }
    return null;
}

export default DniValidadoAlert;

