import React from 'react';
import ResponsiveTable from 'components/tabla/ResponsiveTable';
import FilaEstudios from 'components/estudios/FilaEstudios'

export default function TablaEstudios({headers, estudios}){
    return (
        <ResponsiveTable
            headers={headers}
            rows={estudios}
            emptyText="No se encontraron estudios"
            renderRow={(col, estudio) => <FilaEstudios col={col} estudio={estudio} />}
        />
    );
}
