import React, { useContext } from 'react';
import LayoutPrincipal from 'layouts/LayoutPrincipal';
import LoginLayout from 'layouts/LoginLayout';
import Boton from 'styledComponents/Boton.style';
import { ThemeProvider } from 'styled-components';
import TemaContext from 'providers/Tema';
import Cargando from 'components/Cargando';
import Alert from 'components/Alert';
import useReenvioEmailVerificacion from 'hooks/useReenvioEmailVerificacion';
import IfRender from 'components/IfRender';

export default function VerificationPage(){
    const { error, reenviado, reenviando, handleOnSubmit } = useReenvioEmailVerificacion();
    return (
        <LayoutPrincipal>
            <LoginLayout title="Verificación de cuenta">
                <IfRender condicion={!error}
                    no={() => <Alert>{error}</Alert>}>
                    <IfRender condicion={reenviando}
                        no={() => <ResendEmailForm onSubmit={handleOnSubmit} reenviado={reenviado}/> }>
                        <Cargando text="Reenviado email de verificación" />
                    </IfRender>
                </IfRender>
            </LoginLayout>
        </LayoutPrincipal>
    );
}

function ResendEmailForm({onSubmit, reenviado}){
    const theme = useContext(TemaContext);
    const exitoString = reenviado ? 'Reenvio' : 'Registro';
    return (
        <>
            <p className="m-4">
                {exitoString} finalizado. Hemos enviado un email a su direccion de correo con un enlace para activar su cuenta.
            </p>
            <form onSubmit={onSubmit}>
                <ThemeProvider theme={theme}>
                    <Boton className="btn btn-primary w-100 mt-2">
                        Reenviar email de verificación
                    </Boton>
                </ThemeProvider>
            </form>
        </>
    );
}
