import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useCentrosAtencion(){
    const [centrosDeAtencion, setCentrosDeAtencion] = useState([]);
    const [cargando, setCargando] = useState();
    const [errorCentro, setErrorCentro] = useState();
    const handleResponse = response =>{
        setCentrosDeAtencion(response.data);
        setCargando(false);
    }

    useEffect(() =>  {
        setCargando(true);
        apiGet('api/turnos/centrosDeAtencion')
            .then(handleResponse)
            .catch(error => {
                setErrorCentro("Error inesperado.");
                setCargando(false);
            })
    },[]);


    return {cargando, centrosDeAtencion, errorCentro};

}
