import { apiPost } from "api/configApi";

export default function useReceptar({turnoInterno, setMensajeReceptar, setMostrar, turno, setExito}){

    const receptar = () => {
        const dosHorasEnMilisegundo = 7200000;
        const treintaMinutosEnMilisegundo = -1800000;

        var today = new Date();
        var fechaTurno = new Date(turno.fecha_sin_formato+ ' '+ turno.hora);
        var diffMs = (fechaTurno - today); // milliseconds between now & fecha turno
        if(diffMs <= dosHorasEnMilisegundo && diffMs >= treintaMinutosEnMilisegundo){
            apiPost(`api/turnos/receptar/${turnoInterno.id}`)
            .then( () =>{
                setMensajeReceptar('Presente marcado con exito: Aguarde a ser llamado por el Médico para ingresar a la sala.');
                setExito(true);
            })
            .catch( () => {
                setMensajeReceptar('Error al marcar el Presente');
                setExito(false);
                })
        }else{
            setExito(false);
            setMensajeReceptar('Fuera de rango: Usted puede marcar el presente a partir de 2hs antes del turno. Una vez marcado deberá aguardar en linea al médico.')
        }
        setMostrar(true)

    };

    return receptar;
}
