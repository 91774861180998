import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useMutuales(dni, idPersona){
    const [mutuales, setMutuales] = useState([]);
    const [cargandoMutuales, setCargandoMutuales] = useState();
    const [errorMutuales, setErrorMutuales] = useState();
    const handleResponse = response =>{
        setMutuales(response.data);
        setCargandoMutuales(false);
    }

    useEffect(() =>  {
        const existeIdPersona = idPersona !== -1;
        if(existeIdPersona){
            setCargandoMutuales(true);

            apiGet(`api/turnos/MutualesPorPersona/${dni}/${idPersona}`)
                .then(handleResponse)
                .catch(error => {
                    setErrorMutuales("Error inesperado.");
                    setCargandoMutuales(false);
                })
        }

    },[dni, idPersona]);
    return {cargandoMutuales, mutuales, errorMutuales};
}
