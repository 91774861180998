import React, { useContext } from 'react';
import LayoutConMenuLateral from 'layouts/LayoutConMenuLateral';
import { UserContext } from 'providers/UserProvider';
import CardPerfil from 'components/CardPerfil';
import CardPerfilLoader from 'components/CardPerfil/CardPerfilLoader';
import { Col, Row } from 'react-bootstrap';
import SectionWithTitle from 'components/SectionWithTitle';
import ModalGrupoFamiliar from 'pages/homeAdministracion/ModalGrupoFamiliar';
import { PacienteProvider } from 'providers/PacienteProvider';

export default function MisDatosPage({params}){
    const { user } = useContext(UserContext);
    const paciente = user.datos_personales;
    return (
        <LayoutConMenuLateral>
            <Row className="m-2 p-0">
                <Col md={4} sm={12} className="d-flex justify-content-center d-md-block">
                    <CardPerfilUsuario />
                </Col>
                <Col md={8} sm={12} className="mt-2 mt-md-0">
                    <SectionWithTitle className="mb-2" title="Grupo familiar" >
                        Aquí puede gestionar los integrantes de su grupo familiar
                        <PacienteProvider value={{paciente, user, params}}>
                            <ModalGrupoFamiliar
                                mostrar={params.dni}
                                tituloGrupos="Mi grupo familiar"
                                tituloIntegrados="Grupos que integro"
                            />
                        </PacienteProvider>
                    </SectionWithTitle>
                </Col>
            </Row>
        </LayoutConMenuLateral>
    );
}

function CardPerfilUsuario(){
    const { user } = useContext(UserContext);
    if(user){
        return <CardPerfil datos={user.datos_personales} showFoto/>
    }
    return <CardPerfilLoader />
}




