import React, { useState } from "react";
import SpanWithTooltip from "components/SpanWithTooltip";
import { Dropdown } from "react-bootstrap";
import useTraerTurnoInterno from "hooks/turnos/useTraerTurnoInterno";
import ModalPago from "components/pagos/ModalPago";
import ConfirmDialog from "components/ConfirmDialog";
import useAnularTurno from "hooks/turnos/useAnularTurno";
import useReceptar from "hooks/turnos/useReceptar";
import ModalReceptar from "components/turnos/ModalReceptar";
import ToggleSinFlecha from "styledComponents/ToggleSinFlecha.style";
import useTraerPrestacionCobrable from "hooks/pagos/useTraerPrestacionCobrable";
import IfRender from "components/IfRender";
import useTraerPreparacion from "hooks/turnos/useTraerPreparacion";
import PreparacionDialog from "components/PreparacionDialog";

const mapColumnas = {
    Fecha: "fecha",
    Hora: "hora",
    Prestador: "prestador",
    Servicio: "servicio",
    Centro: "centro",
};

const FilaTurno = ({
    col,
    turno,
    handleDelete,
    setMensajeAnular,
    handleOnErrorEliminar,
}) => {
    const { cargandoTurno, turnoInterno, errorTruno } = useTraerTurnoInterno(
        turno.id
    );
    const { cargandoPrestacion, prestacion, errorPrestacion } =
        useTraerPrestacionCobrable(turno.id_prestacion, turno.id_centro);

    const { cargandoPreparacion, preparacion } = useTraerPreparacion(
        turno.id_prestacion
    );
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showPreparacionDialog, setShowPreparacionDialog] = useState(false);
    const [mostrar, setMostrar] = useState(false);
    const [mensajeReceptar, setMensajeReceptar] = useState();
    const handleShow = () => setShowConfirmDialog(true);
    const handleCancelar = () => setShowConfirmDialog(false);
    const handleClosePreparacionDialog = () => {
        setShowPreparacionDialog(false);
    };
    const preguntaAccionEliminar = `¿Está seguro de anular el turno para el día ${turno.fecha} a las ${turno.hora}? Puede anularlo con una anticipación de hasta 4hs.`;
    const titulo = `Recepción de turno`;
    const [exito, setExito] = useState(false);
    const eliminar = useAnularTurno({
        turno,
        handleDelete,
        setMensajeAnular,
        handleOnErrorEliminar,
    });
    const receptar = useReceptar({
        turnoInterno,
        setMensajeReceptar,
        setMostrar,
        turno,
        setExito,
    });

    switch (col) {
        case "Acción":
            return (
                <>
                    <ModalReceptar
                        mostrar={mostrar}
                        setMostrar={setMostrar}
                        mensaje={mensajeReceptar}
                        exito={exito}
                        titulo={titulo}
                    />

                    <Dropdown drop="left">
                        <ToggleSinFlecha
                            className="py-0"
                            size="sm"
                            variant="primary"
                            id="acciones"
                        >
                            ...
                        </ToggleSinFlecha>

                        <Dropdown.Menu>
                            <ModalPago
                                turno={turno}
                                cargandoTurno={cargandoTurno}
                                turnoInterno={turnoInterno}
                                errorTruno={errorTruno}
                                cargandoPrestacion={cargandoPrestacion}
                                prestacion={prestacion}
                                errorPrestacion={errorPrestacion}
                            />
                            <IfRender
                                condicion={turnoInterno && turnoInterno.pagado}
                                no={() => (
                                    <Dropdown.Item onClick={handleShow}>
                                        Anular
                                    </Dropdown.Item>
                                )}
                            >
                                <Dropdown.Item disabled>Anular</Dropdown.Item>
                            </IfRender>
                            <IfRender
                                condicion={cargandoPreparacion}
                                no={() => (
                                    <IfRender
                                        condicion={
                                            preparacion.preparacion !== null &&
                                            preparacion.preparacion.length > 0
                                        }
                                    >
                                        <Dropdown.Item
                                            onClick={() =>
                                                setShowPreparacionDialog(true)
                                            }
                                        >
                                            Preparacion
                                        </Dropdown.Item>
                                    </IfRender>
                                )}
                            />
                            <IfRender condicion={prestacion}>
                                <IfRender
                                    condicion={
                                        turnoInterno &&
                                        turnoInterno.pagado &&
                                        turnoInterno.receptado
                                    }
                                    no={() => (
                                        <IfRender
                                            condicion={
                                                turnoInterno &&
                                                turnoInterno.pagado
                                            }
                                        >
                                            <Dropdown.Item onClick={receptar}>
                                                Marcar Presente
                                            </Dropdown.Item>
                                        </IfRender>
                                    )}
                                >
                                    <Dropdown.Item disabled>
                                        Marcar Presente
                                    </Dropdown.Item>
                                </IfRender>
                            </IfRender>
                        </Dropdown.Menu>
                    </Dropdown>

                    <ConfirmDialog
                        abrir={showConfirmDialog}
                        titulo="Anular Turno"
                        mensaje={preguntaAccionEliminar}
                        onAceptar={eliminar}
                        onCancelar={handleCancelar}
                        onHide={handleCancelar}
                    />
                    <PreparacionDialog
                        abrir={showPreparacionDialog}
                        titulo="Preparacion"
                        mensaje={preparacion && preparacion.preparacion}
                        onAceptar={handleClosePreparacionDialog}

                    />
                </>
            );
        default:
            return <SpanWithTooltip text={turno[mapColumnas[col]]} />;
    }
};

export default FilaTurno;
