import SectionWithTitle from 'components/SectionWithTitle';
import { PacienteContext } from 'providers/PacienteProvider';
import React, { useContext } from 'react';
import ModalGrupoFamiliar from './ModalGrupoFamiliar';
import ModalNuevoUsuario from './ModalNuevoUsuario';
import IfRender from 'components/IfRender';
import ActualizarEmailDesdeHp from './ActualizarEmailDesdeHP';
import BlanquearEmail from './BlanquearEmail';

export default function SeccionGestion({ activarCrearUsuario }){
    const { paciente, user } = useContext(PacienteContext);
    if(user){
        return (
            <SectionWithTitle title="Usuario registrado" className="my-2">
                <IfRender condicion={ user.email.toLowerCase() !== paciente.email.toLowerCase() }
                    no={() => 
                        <>
                            <p className="px-1">
                                <strong>E-mail:</strong> { user.email }
                            </p>
                            <ModalGrupoFamiliar
                            tituloGrupos="Grupo familiar"
                            tituloIntegrados="Grupos integrados"
                            />
                            <BlanquearEmail user={user}/>
                        </>
                    }>
                   <ActualizarEmailDesdeHp paciente={paciente} user={user}/>
                </IfRender>                
            </SectionWithTitle>
        );
    }
    if(activarCrearUsuario && paciente){
        return (
            <SectionWithTitle title="Usuario no registrado" className="my-2">
                <p className="px-1">
                    El D.N.I. ingresado no tiene un usuario registrado
                </p>
                <ModalNuevoUsuario />
            </SectionWithTitle>
        );
    }
    return ''
}
