import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useTraerResticcionEdad(dniMedico,idPersona){
    const [validacionEdad, setValidacionEdad] = useState([]);
    const [cargandoValidacionEdad, setCargandoValidacionEdad] = useState();
    const [errorValidacionEdad, setErrorValidacionEdad] = useState();

    const handleResponse = response =>{
        setValidacionEdad(response.data);
        setCargandoValidacionEdad(false);
    }

    useEffect(() =>  {
        //if(idPersona && idPersona !== 0){
         if(idPersona && idPersona > 0){
            setCargandoValidacionEdad(true);
            apiGet(`api/getRestriccionEdad/${dniMedico}/${idPersona}`)
            .then(handleResponse)
            .catch(error => {
                setErrorValidacionEdad("Error inesperdo");
                setCargandoValidacionEdad(false);
            });
        }
    },[dniMedico,idPersona]);

    return {cargandoValidacionEdad, validacionEdad, errorValidacionEdad};
}
