import React, { useContext, useEffect, useState } from 'react';
import LayoutConMenuLateral from 'layouts/LayoutConMenuLateral';
import Cargando from 'components/Cargando';
import { apiGet } from 'api/configApi';
import { UserContext } from 'providers/UserProvider';
import SeccionConAccesoHome from 'pages/home/SeccionConAccesoHome'
import SeccionBloqueada from 'pages/estudios/SeccionBloqueada'

export default function HomePage(){
    const {user} = useContext(UserContext)
    const [validando, setValidando] = useState(true);
    const [hasAccess, setHasAccess] = useState(false);

    useEffect(() => {
        user && apiGet('api/validarAcceso').then(response => {
            setHasAccess(response.data);
            setValidando(false);
        });
    }, [user]);

    if(validando){
        return (
            <LayoutConMenuLateral>
                <div className="bg-white m-4 p-2 shadow rounded">
                    <Cargando text="Cargando"/>
                </div>
            </LayoutConMenuLateral>
        );
    }

    if(hasAccess){
        return <SeccionConAccesoHome user={user}/>
    }
    return <SeccionBloqueada setHasAccess={setHasAccess}/>
}
