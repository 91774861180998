import React, {useContext} from 'react';
import { Alert } from 'react-bootstrap';
import Cargando from '../Cargando';
import IfRender from 'components/IfRender'
import Boton from 'styledComponents/Boton.style';
import { Modal } from 'react-bootstrap';
import { ThemeProvider } from 'styled-components';
import TemaContext from 'providers/Tema';
import Combo from 'components/Combo';


export default function Emergente({
    show,
    disabled,
    cargando,
    metodos,
    handleClose,
    handleMetodo,
    pagar,
    metodoDePago
}){
    const tema = useContext(TemaContext)
    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Seleccione el Método de Pago</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <IfRender
                    condicion={!cargando}
                    no={() => <Cargando text="Redirigiendo al sitio para efectuar el pago"/>}
                    >
                <>
                    <Alert variant="warning"> <p><b>IMPORTANTE: </b>
                        Informamos que una vez realizado el pago del turno el mismo no podrá ser cancelado o modificado.
                    </p> </Alert>
                    <Combo
                        name="metodoDePago"
                        opciones={metodos}
                        label="Metodos de pago"
                        opcionValue="nombre"
                        defaultValue={metodoDePago}
                        onChange={handleMetodo}
                    />
                    <ThemeProvider theme={tema}>
                        <Boton className="btn w-100 mt-2" onClick={pagar} disabled={disabled}>
                            Pagar
                        </Boton>
                    </ThemeProvider>
                </>
                </IfRender>
            </Modal.Body>
        </Modal>
    )}
