import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useMedicosPorServicio(servicio){
    const [medicos, setMedicos] = useState([]);
    const [cargandoMedicos, setCargandoMedicos] = useState();
    const [errorMedicos, setErrorMedicos] = useState();

    const handleResponse = response =>{
        response.data.unshift({dni:'0',nombre:'Sin',apellido:'Especificar', nombreApellido:'Sin Especificar',id:'0'});
        setMedicos(response.data);
        setCargandoMedicos(false);
    }

    useEffect(() =>  {
        setCargandoMedicos(true);
        apiGet(`api/turnos/MedicosPorServicio/${servicio}`)
            .then(handleResponse)
            .catch(error => {
                setErrorMedicos(error);
                setCargandoMedicos(false);
            })
    },[servicio]);

    return {cargandoMedicos, medicos, errorMedicos};
}
