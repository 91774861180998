import { apiGet } from "api/configApi";
import { useCallback, useEffect, useState } from "react";

export default function useValidarEdad({familiar}){
    const [pac, setPac] = useState([]);
    const [cargandoPaciente, setCargandoPaciente] = useState(false);
    const [reloadPaciente, setReloadPaciente] = useState(true);
    const [errorsPaciente, setErrorsPaciente] = useState();


    const handleResponsePaciente = response => {
        setPac(response.data);
        setCargandoPaciente(false);
    }

    const handleErrorsPaciente = error => setErrorsPaciente(error);

    const traerPaciente = useCallback(() => {
        setCargandoPaciente(true);
        setReloadPaciente(false);
        apiGet(`api/datosP/${familiar.dni_familiar}`)
            .then(handleResponsePaciente)
            .catch(handleErrorsPaciente);
    }, [familiar.dni_familiar]);

    useEffect(() => {
        reloadPaciente && traerPaciente();
    });

    return{
        pac,
        cargandoPaciente,
        errorsPaciente
    }
}
