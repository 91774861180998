import VerificationPage from 'pages/login/VerificationPage';
import { UserContext } from 'providers/UserProvider';
import React, { useContext } from 'react';
import url from 'utils/url';
import { Redirect } from 'wouter';
import CargandoPage from 'pages/CargandoPage';
import ConfirmPasswordPage from 'pages/login/ConfirmPasswordPage';
import { hasRoles } from 'providers/UserProvider';
import IfRender from './IfRender';

function AuthRoute({Component, rolesId, loginUrl, params = null}){
    const {user, iniciando, unauthenticated} = useContext(UserContext);

    if(iniciando && !unauthenticated) {
        return <CargandoPage cargandoText="Recuperando usuario" />;
    }

    if(user && user.es_provisorio === "1"){
        return <ConfirmPasswordPage />
    }

    if(user && rolesId && !hasRoles(user, rolesId)){
        return <Redirect to={url(user.default_url_home)} />;
    }

    if(user && user.email_verified_at){
        return <Component params={params}/>;
    }

    if(user && !user.email_verified_at){
        return <Redirect to={url('verificacion')}/>
    }

    return <Redirect to={url(loginUrl)} />
}

function GuestRoute({Component, params = null}){
    const {user,  iniciando} = useContext(UserContext);

    if(iniciando){
        return <CargandoPage cargandoText="Recuperando usuario" />;
    }

    return <IfRender condicion={!user}
                no={() => <Redirect to={url(user.default_url_home)} /> }>
                <Component params={params}/>
            </IfRender>
}

function UserNotVerifiedRoute(){
    const {user} = useContext(UserContext);
    if(user && user.email_verified_at){
        return <Redirect to={url(user.default_url_home)} />;
    }
    if(user && !user.email_verified_at){
        return <VerificationPage />;
    }
    if(!user){
        return <Redirect to={url()} />
    }
}

function UserIsProvisorio(){
    const {user} = useContext(UserContext);
    if(user && user.es_provisorio === "1"){
        return <ConfirmPasswordPage />
    }
    return <IfRender condicion={user}>
                <Redirect to={url(user.default_url_home)} />
           </IfRender>
}

export { AuthRoute,  GuestRoute, UserNotVerifiedRoute, UserIsProvisorio }
