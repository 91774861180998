import React from 'react';
import Combo from 'components/Combo';

export default function ComboMutuales({
    mutuales,
    cargandoMutuales,
    handleMutualChange,
    mutual
}){

    return (
        <Combo
            opciones={mutuales}
            label="Obra Social"
            opcionValue="nombre"
            defaultValue={mutual}
            cargando={cargandoMutuales}
            onChange={handleMutualChange}
        />
    );
}
