import { apiPost } from "api/configApi";

export default function useAnularTurno({turno, handleDelete, setMensajeAnular, handleOnErrorEliminar}){
    const turnoBackUp = turno;

    const eliminar = () => {
        handleDelete(turno.id, 'Turno anulado exitosamente')
        apiPost(`api/turnos/anularTurno/${turno.id}`)
            .then((response) => setMensajeAnular(response.data.mensaje))
            .catch(error => handleOnErrorEliminar(turnoBackUp, error.response.data.mensaje))
    };

    return eliminar;
}
