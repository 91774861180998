import TemaContext from 'providers/Tema';
import React, { useContext } from 'react';

export default function Alert({children}){
    const tema = useContext(TemaContext);
    const style = {backgroundColor: tema.secundario, color: 'white'};
    return (
        <div style={style} className="mb-0 w-100 text-center alert">
            {children}
        </div>
    );
}
