import React from 'react';
import GrupoFamiliarItem from './GrupoFamiliarItem';

export default function Familiar({familiar, onDelete, onError}){

    return (
        <GrupoFamiliarItem
            familiar={familiar}
            onDelete={onDelete}
            onError={onError}
            dniProp="dni_familiar"
            cargandoNombreLabel="Cargando nombre del familiar..."
            labelBtnEliminar="Eliminar"
            preguntaAccionEliminar="¿Desea eliminar a este familiar del grupo?"
        >

        </GrupoFamiliarItem>

    );
}


