import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useBuscarNombrePersona(dni){
    const [nombre, setNombre] = useState();
    const [cargandoNombre, setCargandoNombre] = useState(true);

    const handleResponseNombreFamiliar = response => {
        setNombre(response.data.nombre);
        setCargandoNombre(false);
    };

    useEffect(() => {
        apiGet(`api/nombrePersona/${dni}`)
            .then(handleResponseNombreFamiliar)
    }, [dni]);

    return { nombre, cargandoNombre }

}
