import React, { useContext } from 'react';
import SpanWithTooltip from 'components/SpanWithTooltip';
import TemaContext from 'providers/Tema';
import Boton from 'styledComponents/Boton.style';


const mapColumnas = {
    Paciente: 'nombre_completo',
    DNI: 'dni_paciente',
    Fecha: 'fecha_consulta'
};

const FilaProximoTurno = ({col, proximoTurno, setCopiado}) => {
    const tema = useContext(TemaContext);

    const handleClic = () => {
        setCopiado(false)
        navigator.clipboard.writeText(`https://portal.sanatorioboratti.com.ar/anestesistas/pacientes?dni=${proximoTurno.dni_paciente}`)
        .then(() => {
            setCopiado(true)
        })
        .catch(err => {
          console.log('Error al copiar el enlace.', err);
        });
    }

    switch(col){
        case 'Acción':
            return(
                <>
                    <Boton
                        className="btn"
                        onClick={handleClic}
                        theme={tema}
                    >
                        Copiar link formulario paciente
                    </Boton>

                </>
            );
        default:
            return <SpanWithTooltip text={proximoTurno[mapColumnas[col]]} />
    }
}

export default React.memo(
    FilaProximoTurno,
    (prevProps, nextProps) => prevProps.proximoTurno.id_turno === nextProps.proximoTurno.id_turno
);
