import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { Message } from "primereact/message";
import Cargando from "components/Cargando";
import SectionWithTitle from "components/SectionWithTitle";
import SeleccionarPersonaVisitaMedica from "./SeleccionarPersonaVisitasMedicas";
import useFamiliares from "hooks/turnos/useFamiliares";
import useProximosTurnos from "hooks/turnos/useProximosTurnos";
import AlertValidarFamiliar from "components/AlertValidarFamiliar";
import IfRender from "components/IfRender";
import useVisitasMedicas from "hooks/telemedicina/useVisitasMedicas";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import url from "utils/url";
import { useLocation } from "wouter";
import useMutuales from "hooks/useMutuales";
import useValidarAccesoServicios from "hooks/turnos/useValidarAccesoServicios";
import useRestriccionesMedicoEdad from "hooks/configuracion/RestriccionMedicoEdad/useRestriccionesMedicoEdad";
import useTraerRestriccionesMedicoOS from "hooks/configuracion/RestriccionMedicoObraSocial/useTraerRestriccionesMedicoOS";
import useTraerRestriccionesMedicoPrestacion from "hooks/configuracion/RestriccionMedicoPrestacion/useTraerRestriccionesMedicoPrestacion";
import useTraerRestriccionesMedicosTurnoOnline from "hooks/configuracion/RestriccionMedicoTurnoOnline/useTraerRestriccionesMedicosTurnoOnline";
import useTraerRestriccionesOs from "hooks/configuracion/RestricionInstitucionObraSocial/useTraerRestriccionesOs";

export default function AccesoValidado({ user, Layout }) {
    const d = new Date();
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    const [idServicio, setIdServicio] = useState(0);
    const [idMutual, setIdMutual] = useState(null);
    const [fechaDesde, setFechaDesde] = useState(`${ye - 1}-${mo}-${da}`);
    const [dni, setDni] = useState(user.dni);
    const [, setLocation] = useLocation();
    const [invalido, setInvalido] = useState(false);
    const [isConsalud, setIsConsalud] = useState(false);
    const { cargandoMutuales, mutuales, errorMutuales } = useMutuales(
        dni,
        user.datos_personales.id_persona
    );
    const { cargandoAccesoServicio, accesoServicio, errorAccesoServicio } =
        useValidarAccesoServicios(
            user.datos_personales.id_persona,
            idServicio,
            idMutual
        );
    const { visitas, cargando, error } = useVisitasMedicas(dni, fechaDesde);
    const {
        cargandoRestriccionesPorEdad,
        restriccionesPorEdad,
        errorRestriccionesPorEdad,
    } = useRestriccionesMedicoEdad();
    const {
        cargandoRestriccionesPorObraSocial,
        restriccionesPorObraSocial,
        errorRestriccionesPorObraSocial,
    } = useTraerRestriccionesMedicoOS();
    const {
        cargandoRestriccionesPrestaciones,
        restriccionesPrestaciones,
        errorRestriccionesPrestaciones,
    } = useTraerRestriccionesMedicoPrestacion();
    const {
        cargandoRestriccionesTurnos,
        restriccionesTurnos,
        errorRestriccionesTurnos,
    } = useTraerRestriccionesMedicosTurnoOnline();
    const {
        cargandoRestriccionesInstitucionMutual,
        restriccionesInstitucionMutual,
        errorRestriccionesInstitucionMutual,
    } = useTraerRestriccionesOs();

    if (!cargando) {
        visitas.map((visita) => {
            visita.fechaFormat = visita.Fecha.split(" ")[0]
                .split("-")
                .reverse()
                .join("-");
        });
    }

    const { cargandoFamiliares, familiares, errorFamiliares } = useFamiliares(
        user.id
    );

    const { cargandoTurnos, turnos, errorTurnos, tieneTurnoProximo } =
        useProximosTurnos(dni, null);

    const turnosPredefinidos = 3;

    const [filters, setFiltros] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const renderHeader = () => (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                    onInput={(e) =>
                        setFiltros({
                            global: {
                                value: e.target.value,
                                matchMode: FilterMatchMode.CONTAINS,
                            },
                        })
                    }
                    placeholder="Buscar"
                />
            </span>
            <small className="text-muted d-block">
                Dentro del rango de fecha establecido puede buscar por fecha
                específica, servicio, profesional o centro de atención.
            </small>
        </div>
    );

    const header = renderHeader();

    function redireccionar(datos) {
        setIsConsalud(
            mutuales.some((mutual) => {
                return (
                    parseInt(mutual.usaAppConsalud) === 1 &&
                    parseInt(mutual.id) === parseInt(datos.IdMutual)
                );
            })
        );
        setIdServicio(datos.IdServicio);
        setIdMutual(datos.IdMutual);
        setLocation(
            url(
                `seleccionarTurno/${user.datos_personales.id_persona}/${
                    datos.IdMutual
                }/${1}/${datos.IdServicio}/${datos.IdPrestacionAsignada}/${
                    datos.documento_profesional
                }/${isConsalud}/${accesoServicio.coseguro}`
            )
        );
    }
    
    const processRowData = (rowData) => {
        if (
            !restriccionesTurnos.some((restriccionTurno) => {
                return restriccionTurno.dni == rowData.documento_profesional;
            }) &&
            restriccionesInstitucionMutual.some(
                (restriccionInstitucionMutual) => {
                    return (
                        restriccionInstitucionMutual.IdInstitucion == 1 &&
                        restriccionInstitucionMutual.IdMutual ==
                            rowData.IdMutual
                    );
                }
            ) &&
            !restriccionesPorEdad.some((restriccionPorEdad) => {
                return (
                    restriccionPorEdad.dni == rowData.documento_profesional &&
                    restriccionPorEdad.edadMinima >
                        user.datos_personales.edad &&
                    restriccionPorEdad.edadMaxima < user.datos_personales.edad
                );
            }) &&
            !restriccionesPorObraSocial.some((restriccionPorObraSocial) => {
                return (
                    restriccionPorObraSocial.dniMedico ==
                        rowData.documento_profesional &&
                    restriccionPorObraSocial.idMutual == rowData.IdMutual
                );
            }) &&
            !restriccionesPrestaciones.some((restriccionPrestacion) => {
                return (
                    restriccionPrestacion.dniMedico ==
                        rowData.documento_profesional &&
                    restriccionPrestacion.IdPrestacion ==
                        rowData.IdPrestacionAsignada
                );
            }) &&
            !(user.datos_personales.edad >= 15 && rowData.IdServicio == 10) && // Si es pediatria, que sea menor de 15
            rowData.IdServicio == (1 || 10 || 11) && // Servicios que no requieren una derivacion previa
            !(turnos.length >= turnosPredefinidos)
        ) {
            return (
                <React.Fragment>
                    <Button
                        icon="pi pi-sync"
                        className="p-button-rounded p-button-primary mx-1"
                        onClick={() => redireccionar(rowData)}
                        
                    />
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-sync"
                    className="p-button-rounded p-button-primary mx-1"
                    disabled={true}
                />
            </React.Fragment>
        );
    };

    return (
        <Layout>
            <IfRender
                condicion={
                    error ||
                    errorFamiliares ||
                    errorRestriccionesPorEdad ||
                    errorRestriccionesPorObraSocial ||
                    errorRestriccionesPrestaciones ||
                    errorRestriccionesTurnos ||
                    errorRestriccionesInstitucionMutual
                }
                no={() => (
                    <div className="container-fluid">
                        <div className="mt-2 mb-2">
                            <SectionWithTitle title="Visitas médicas">
                                <SeleccionarPersonaVisitaMedica
                                    familiares={familiares}
                                    cargandoFamiliares={cargandoFamiliares}
                                    user={user}
                                    setDocumento={setDni}
                                    setInvalido={setInvalido}
                                    fechaInicio={fechaDesde}
                                    setFechaInicio={setFechaDesde}
                                    mostrarFecha={true}
                                />
                            </SectionWithTitle>
                        </div>
                        <IfRender
                            condicion={
                                !cargando &&
                                !cargandoAccesoServicio &&
                                !cargandoRestriccionesInstitucionMutual &&
                                !cargandoRestriccionesPorEdad &&
                                !cargandoRestriccionesPorObraSocial &&
                                !cargandoRestriccionesPrestaciones &&
                                !cargandoRestriccionesTurnos
                            }
                            no={() => <Cargando text="Cargando visitas" />}
                        >
                            <IfRender
                                IfRender
                                condicion={!invalido}
                                no={() => (
                                    <AlertValidarFamiliar
                                        familiar={invalido}
                                        accion={"ver sus visitas médicas"}
                                    />
                                )}
                            ><IfRender
                                    IfRender
                                    condicion={
                                        turnos.length >= turnosPredefinidos
                                    }
                                >
                                 <Message
                                        severity="warn"
                                        text="No puedes repetir ninguna atención, ya tienes el máximo de turnos pendientes."
                                    />
                                </IfRender>
                                <SectionWithTitle>
                                    <div className="px-2">
                                        <DataTable
                                            value={visitas}
                                            size={"small"}
                                            header={header}
                                            filters={filters}
                                            responsiveLayout="scroll"
                                            paginator
                                            rows={10}
                                            emptyMessage="No se encontraron registros."
                                        >
                                            <Column
                                                headerClassName="border-primary"
                                                bodyClassName="text-uppercase"
                                                field="fechaFormat"
                                                sortable
                                                header="Fecha"
                                            ></Column>
                                            <Column
                                                headerClassName="border-primary"
                                                bodyClassName="text-uppercase text-center"
                                                alignHeader="center"
                                                field="Servicio"
                                                sortable
                                                header="Servicio"
                                            ></Column>
                                            <Column
                                                headerClassName="border-primary"
                                                alignHeader="center"
                                                bodyClassName="text-uppercase text-center"
                                                field="Profesional"
                                                sortable
                                                header="Profesional"
                                            ></Column>
                                            <Column
                                                headerClassName="border-primary"
                                                alignHeader="center"
                                                bodyClassName="text-uppercase text-center"
                                                field="CentroAtencion_Nombre"
                                                sortable
                                                header="Centro de atención"
                                            ></Column>
                                            <Column
                                                body={processRowData}
                                                exportable={false}
                                                style={{
                                                    minWidth: "8rem",
                                                }}
                                                header="Repetir Atención"
                                                headerClassName="border-primary"
                                                alignHeader="center"
                                                align="center"
                                            ></Column>
                                        </DataTable>
                                    </div>
                                </SectionWithTitle>
                            </IfRender>
                        </IfRender>
                    </div>
                )}
            >
                <Alert variant="danger"> {error} </Alert>
            </IfRender>
        </Layout>
    );
}
