import { Toast } from 'react-bootstrap';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';

const toastStyle = {
    position: 'absolute',
    minHeight: '100px',
    bottom: '2vh',
    width: '16em',
    right: '2vh',
    zIndex: 9999
}

const MensajeToast = ({
    title,
    mensaje,
    onHide = () => {}
}) => {
    const [show, setShow] = useState(true);

    const handleonClose = () => {
        setShow(false);
        onHide();
    }

    return ReactDOM.createPortal(
        <Toast
            style={toastStyle}
            onClose={handleonClose}
            delay={5000}
            show={show}
            autohide
        >
            <Toast.Header>
                <strong className="mr-auto">
                    { title }
                </strong>
            </Toast.Header>
            <Toast.Body>
                { mensaje }
            </Toast.Body>
        </Toast>,
        document.getElementById('root')
    );
}

export default MensajeToast;
