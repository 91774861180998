import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const style = {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    display: 'block'
}


const SpanWithTooltip = ({
    placement = 'top',
    text
}) => (
    <OverlayTrigger
        placement={placement}
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip>{ text }</Tooltip>}
    >
        <span style={style}> { text } </span>
    </OverlayTrigger>
);

export default SpanWithTooltip;
