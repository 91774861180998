import React from 'react';
import { Card } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { cardStyle } from './CardPerfil.style';


export default function CardPerfilLoader(props){
    return (
        <Card style={cardStyle} className="m-2 shadow text-center">
            <ContentLoader
                speed={2}
                width={300}
                height={250}
                viewBox="0 0 400 300"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
                >
                <rect x="100" y="200" rx="3" ry="3" width="200" height="10" />
                <rect x="150" y="220" rx="3" ry="3" width="100" height="6" />
                <rect x="150" y="235" rx="3" ry="3" width="100" height="6" />
                <rect x="150" y="260" rx="3" ry="3" width="100" height="6" />
                <rect x="150" y="275" rx="3" ry="3" width="100" height="6" />
                <circle cx="200" cy="75" r="75" />
            </ContentLoader>
        </Card>
    );
}

