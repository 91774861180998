import IfRender from 'components/IfRender';
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';
import FormatBytes from 'utils/formatBytes';

const FileInput = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`;

const FileInputLabel = styled.label`
    color: white;
    background-color: #266296;
    display: inline-block;
    cursor: pointer;
    padding: 5px;
    &:hover{
        background-color: #62a6f0;
    }
`;

const File = ({
    label,
    id,
    name,
    warnings,
    fileLimit,
    sizeLimit = 2000000,
    mimeTypes = ['image/png', 'image/jpeg', 'image/jpg'],
    state,
    controller
}) => {

    const [error, setError] = useState();

    const addFileElement = event => {
        setError('');
        if (fileLimitCheck(event) && sizeLimitCheck(event) && mimeTypeCheck(event)){
            const files = event.target.files;
            const fileCollection = [...state.files, ...files];
            controller.handleOnChangeFiles(fileCollection);
        }
    }

    const fileLimitCheck = event => {
        const eventFiles = event.target.files;
        const fileTotal = eventFiles.length + state.files.length;
        if (fileTotal > fileLimit) {
            event.target.value = null;
            setError(`Solo se permite subir ${fileLimit} archivos`);
            return false;
        }
        return true;
    }

    const sizeLimitCheck = event => {
        let err = '';
        Array.from(event.target.files).forEach(
            file => {
                if (file.size > sizeLimit) {
                    err += `${file.name} excede el tamaño permitido (${FormatBytes(sizeLimit)} por archivo).`;
                }
            }
        );
        if (err !== '') {
            event.target.value = null;
            setError(err);
            return false;
        }
        return true;
    }

    const mimeTypeCheck = event => {
        let err = '';
        Array.from(event.target.files).forEach(
            file => {
                if(mimeTypes.every(type => file.type !== type)){
                    err += `${file.type} no es un formato válido.`;
                }
            }
        );
        if(err !== ''){
          event.target.value = null;
          setError(`El formato de archivo no es válido. Formatos válidos: ${mimeTypes.join(", ")}`);
          return false;
        }
        return true;
    }

    return (
        <Card>
            <Card.Body>
                <FileInputLabel className="" htmlFor={id}>
                    {label}
                </FileInputLabel>
                <FileInput id={id} type="file" name={name} onChange={addFileElement} multiple/>
                <IfRender condicion={error}>
                    <small className="d-block text-danger">{error}</small>
                </IfRender>
                <ul>
                    {Array.from(state.files).map((file, index) => (
                        <FileElement
                            fileName={file.name}
                            key={index}
                            state={state}
                            controller={controller}
                        />
                    ))}
                </ul>
            </Card.Body>
            <Card.Footer className="text-muted">
                {warnings.map((warning, index) => {
                    return <li key={index}>{warning}</li>
                })}
            </Card.Footer>
        </Card>
    );
}

const FileElement = ({fileName, state, controller}) => {
    const removeFileElement = () => {
        const filteredFileList = [...state.files].filter(file => file.name !== fileName);
        controller.handleOnChangeFiles(filteredFileList);
    };
    return (
        <li>
            {fileName}
            <button type="button" className="btn btn-danger btn-sm ml-1" onClick={removeFileElement}>
                eliminar
            </button>
        </li>
    );
}

export default File;
