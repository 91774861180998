import { apiGet } from "api/configApi";
import { UserContext } from "providers/UserProvider";
import { useContext, useEffect, useState } from "react";

export default function useOpcionesMenu(){
    const { user, addUser } = useContext(UserContext);
    const [cargandoOpciones, setCargandoOpciones] = useState(false);
    const opciones = user && user.opciones ? user.opciones : [];

    useEffect(() => {
        if(user && !user.opciones){
            setCargandoOpciones(true);
            apiGet('api/opciones', { id: user.id })
            .then(respuesta => {
                const modifiedUser = Object.assign(user);
                modifiedUser.opciones = respuesta.data.filter((data, index, j) => index === j.findIndex((o) => (o.id === data.id)));
                addUser(modifiedUser);
                setCargandoOpciones(false);
            });
        }
    }, [user, addUser]);

    return { cargandoOpciones, opciones };
}
