import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useTraerRestriccionesMedicoPrestacion(){
    const [restriccionesPrestaciones, setRestriccionesPrestaciones] = useState([]);
    const [cargandoRestriccionesPrestaciones, setCargandoRestriccionesPrestaciones] = useState();
    const [errorRestriccionesPrestaciones, setErrorRestriccionesPrestaciones] = useState();

    const handleResponse = response =>{
        setRestriccionesPrestaciones(response.data);
        setCargandoRestriccionesPrestaciones(false);
    }

    useEffect(() =>  {
            setCargandoRestriccionesPrestaciones(true);
            apiGet(`api/getRestriccionesMedicosPrestacion`)
                .then(handleResponse)
                .catch(errorRestriccionesPrestaciones => {
                    setErrorRestriccionesPrestaciones("Error inesperado.");
                    setCargandoRestriccionesPrestaciones(false);
                })

    },[]);

    return {cargandoRestriccionesPrestaciones, restriccionesPrestaciones, errorRestriccionesPrestaciones};
}
