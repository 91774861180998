import { apiPost, getCsrfCookie, reduceErrorMessages } from "api/configApi";
import { useCallback, useState } from "react";

export default function useReporteError(){
    const [dni, setDni] = useState('');
    const [telefono, setTelefono] = useState('');
    const [asunto, setAsunto] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [reportando, setReportando] = useState(null);
    const [errors, setErrors] = useState(null);
    const data = {dni, telefono, asunto, descripcion}
    const [noReportado, setNoReportado] = useState(true);

    const handleReport = () => apiPost('api/reportarError', data);

    const handleReportResponse = response =>  {
        setReportando(false);
        setNoReportado(false);
        setDni('');
        setTelefono('');
        setAsunto('');
        setDescripcion('');
    }

    const handleErros = error => {
        setReportando(false);
        setErrors(reduceErrorMessages(error));
    }

    const handleOnChangeDni = useCallback(evt => {
        setErrors(null);
        setDni(evt.target.value);
    },[setDni]);

    const handleOnChangeTelefono = useCallback(evt => {
        setErrors(null);
        setTelefono(evt.target.value);
    }, [setTelefono]);

    const handleOnChangeAsunto = useCallback(evt => {
        setErrors(null);
        setAsunto(evt.target.value);
    }, [setAsunto]);

    const handleOnChangeDescripcion = useCallback(evt => {
        setErrors(null);
        setDescripcion(evt.target.value);
    }, [setDescripcion]);

    const onSubmit = evt => {
        evt.preventDefault();
        setReportando(true);
        getCsrfCookie()
            .then(handleReport)
            .then(handleReportResponse)
            .catch(handleErros);
    }

    return {
        reportando,
        errors,
        state: {
            dni,
            telefono,
            asunto,
            descripcion
        },
        controller: {
            handleOnChangeDni,
            handleOnChangeTelefono,
            handleOnChangeAsunto,
            handleOnChangeDescripcion,
            onSubmit,
        },
        noReportado
    }
}
