import React from "react";
import { Card } from "primereact/card";

export default function CardItemVistas({estadisticaVistas}) {
    return (

                    <div className="card">
                        <Card title='Visitas a páginas'>
                            <div className="d-flex align-items-center justify-content-between">
                                <small>Visitas Médicas:</small>
                                <p className="m-0 h1 text-primary">{estadisticaVistas[0].visitasMedicas}</p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <small>Internaciones:</small>
                                <p className="m-0 h1 text-primary">{estadisticaVistas[0].internaciones}</p>
                            </div>
                        </Card>
                    </div>

    );
}