import React from 'react';
import FormGroup from 'components/FormGroup';
import { Section } from './styles';

const DatosPersonales = ({formularioCompletado, state, controller}) => (
    <>
        <Section>
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <FormGroup
                        label="DNI"
                        type="number"
                        required={true}
                        onChange={controller.handleOnChangeDni}
                        value={state.paciente_dni}
                        disabled={formularioCompletado}
                    />
                </div>
                <div className="col-sm-12 col-md-6">
                    <FormGroup
                        label="Nombre y Apellido"
                        type="text"
                        required={true}
                        readOnly={true}
                        value={state.nombre} />
                </div>
                <div className="col-sm-12 col-md-6">
                    <FormGroup
                        label="Mi Teléfono"
                        type="text"
                        required={true}
                        readOnly={false}
                        value={state.telefono}
                        onChange={controller.handleOnChangeTelefono} />
                </div>
                <div className="col-sm-12 col-md-6">
                    <FormGroup
                        label="Fecha de nacimiento"
                        type="date"
                        required={true}
                        readOnly={true}
                        value={state.fechaNacimiento} />
                </div>
                <div className="col-sm-12 col-md-6">
                    <FormGroup
                        label="Edad"
                        type="number"
                        name="edad"
                        readOnly={true}
                        required={true}
                        value={state.edad} />
                </div>
                <div className="col-sm-12 col-md-6">
                    <FormGroup
                        label="Fecha de consulta"
                        type="date"
                        required={true}
                        readOnly={true}
                        value={state.fechaDeConsulta} />
                </div>
                <div className="col-sm-12 col-md-6">
                    <FormGroup
                        label="Médico anestesísta"
                        type="text"
                        required={true}
                        readOnly={true}
                        value={state.anestesista} />
                </div>
            </div>
        </Section>
    </>
);

export default DatosPersonales;
