import React from 'react';
import { Alert } from 'react-bootstrap';

export default function AlertValidarFamiliar({familiar, accion}){
    return (
        <Alert className="ml-2 mr-2" variant="warning">
            { familiar.apellido + ', ' + familiar.nombre } tiene que aceptar la solicitud para que usted pueda {accion}.
        </Alert>
    );
}
