import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import Boton from 'styledComponents/Boton.style';
import TemaContext from 'providers/Tema';
import useImagenesDeFormulario from 'hooks/anestesistas/useImagenesDeFormulario'
import Carrusel from 'components/Carrusel'

export default function ModalCarruselAdjuntos({formulario, setShow, show}){
    const tema = useContext(TemaContext);
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    const {imagenes, cargando, error} = useImagenesDeFormulario(formulario.id, show)

    return (
        <>
            <Boton className="btn" onClick={handleShow} theme={tema} disabled={false}>
                Adjuntos
            </Boton>
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Imagenes Adjuntas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Carrusel
                        imagenes={imagenes}
                        path="path"
                        alt="alt"
                        nombre="nombre"
                        descripcion="descripcion"
                        cargandoImagenes={cargando}
                        errorImagenes={error}
                    />
                </Modal.Body>
            </Modal>
        </>
    )}
