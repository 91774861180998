import React from 'react';
import { ReactComponent as IcoCheck} from 'icons/check.svg';
import MensajeConIcon from './MensajeConIcon';

const MensajeExito = ({mensaje}) => (
    <MensajeConIcon
        mensaje={mensaje}
        Icon={IcoCheck}
    />
);

export default MensajeExito;

