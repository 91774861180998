import React from 'react';
import LayoutPrincipal from 'layouts/LayoutPrincipal';
import Cargando from 'components/Cargando';

export default function CargandoPage({cargandoText}){
    return (
        <LayoutPrincipal>
            <section className="bg-white m-4 p-4 rounded">
                <Cargando text={cargandoText}/>
            </section>
        </LayoutPrincipal>
    );
}
