import styled, { css } from 'styled-components';

export const SelectedLink = styled.a`
    transition: all 200ms ease;

    &:hover {
        text-decoration: none;
        box-shadow: inset 0px -5px 0px white !important;
    }

    ${props => props.selected && css`
        &{
            text-decoration: none;
            box-shadow: inset 0px -5px 0px white !important;
        }
    `}
`;
