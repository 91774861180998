import React from 'react';
import LayoutPrincipal from 'layouts/LayoutPrincipal';
import FormularioPreAnestesico from './FormularioPreAnestesico';

const FormularioPreAnestesicoPage = ({ params }) => (
    <LayoutPrincipal>
        <div className="container p-4">
            <FormularioPreAnestesico
                id_formulario={params && params.id_formulario}
            />
        </div>
    </LayoutPrincipal>
);

export default FormularioPreAnestesicoPage;
