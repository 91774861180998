import { Modal, Row, Col } from 'react-bootstrap';
import TemaContext from 'providers/Tema';
import React, { useContext, useState } from 'react';
import Boton from 'styledComponents/Boton.style';
import SectionWithTitle from 'components/SectionWithTitle';
import SeccionBuscarPaciente from './SeccionBuscarPaciente';
import ListaFamiliares from './ListaFamiliares';
import AgregarNuevoFamiliar from './AgregarNuevoFamiliar';
import useGestionFamiliares from 'hooks/gestionFamiliares/useGestionFamiliares';
import Familiar from './Familiar';
import Integrante from './Integrante';

export default function ModalGrupoFamiliar({
    mostrar = false,
    tituloGrupos,
    tituloIntegrados
}){
    const tema = useContext(TemaContext);
    const [show, setShow] = useState(mostrar);
    const {
        paciente,
        agregarFamiliar,
        handleOnSearchFamiliarBuscado,
        handleBuscandoFamiliar,
        familiarAgregado,
        familiarBuscado,
        cargandoFamiliar,
        errorAgregarFamiliar,
        reset,
        errorBuscarGrupo,
        errorBuscarGrupoIntegrado,
        cargandoGrupo,
        cargandoGrupoIntegrado,
        gruposFamiliares,
        gruposIntegrados,
        setGruposFamiliares,
        setGruposIntegrados
    } = useGestionFamiliares();

    const handleShow = () => setShow(true);
    const handleClose = () => {
        reset();
        setShow(false)
    };

    return (
        <>
        <Boton className="btn w-100 mt-2" onClick={handleShow} theme={tema}>
            Gestionar Grupo Familiar
        </Boton>
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Familiares de { paciente.nombre } { paciente.apellido }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col col={12}>
                        <SeccionBuscarPaciente
                            url="api/buscarPaciente"
                            seBusca="familiar"
                            onSearch={handleOnSearchFamiliarBuscado}
                            onBuscando={handleBuscandoFamiliar}
                            onCancelar={reset}
                        />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col col={12} md={6}>
                        <SectionWithTitle title={tituloGrupos}>
                            <ListaFamiliares
                                error={errorBuscarGrupo}
                                buscando={cargandoGrupo}
                                familiares={gruposFamiliares}
                                setGruposFamiliares={setGruposFamiliares}
                                ComponenteFamiliar={Familiar}
                                mensajeCargando="Cargando familiares"
                            />
                        </SectionWithTitle>
                        <SectionWithTitle className="my-2" title={tituloIntegrados}>
                            <ListaFamiliares
                                error={errorBuscarGrupoIntegrado}
                                buscando={cargandoGrupoIntegrado}
                                familiares={gruposIntegrados}
                                setGruposFamiliares={setGruposIntegrados}
                                ComponenteFamiliar={Integrante}
                                mensajeCargando="Cargando titulares"
                            />
                        </SectionWithTitle>
                    </Col>
                    <Col col={12} md={6}>
                        <AgregarNuevoFamiliar
                            familiar={familiarBuscado}
                            error={errorAgregarFamiliar}
                            cargando={cargandoFamiliar}
                            agregado={familiarAgregado}
                            onAgregar={agregarFamiliar}
                        />
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
        </>
    );
}

