import { getParamFromUrl } from "utils/url";

const UPDATE_FORMULARIO = 1;
const UPDATE_COMPLETOS = 2;
const CARGANDO_FORMULARIO = 3;
const ON_SUBMIT = 4;
const ERROR_SUBMIT = 5;
const SUCCESS_SUBMIT = 6;
const UPDATE_ESTUDIOS = 7;

const initialState = {
    secciones: [],
    completados: [],
    files: [],
    cargandoFormulario: false,
    pacienteDniCompletado: getParamFromUrl('dni'),
    formularioCompletado: null
}

const reducerFormulario = (state, action) => {
    switch(action.type){
        case UPDATE_FORMULARIO:
            return {
                ...state,
                secciones: action.payload.data.secciones,
                cargandoFormulario: false
            }
        case UPDATE_COMPLETOS:
            return {
                ...state,
                completados: action.payload.data.completados,
                pacienteDniCompletado: action.payload.data.paciente_dni,
                formularioCompletado: state.completados && state.completados.length > 0
            }
        case CARGANDO_FORMULARIO:
            return {
                ...state,
                cargandoFormulario: action.payload
            }
        case ON_SUBMIT:
            return {
                ...state,
                enviando: true,
                error: false
            }
        case ERROR_SUBMIT:
            return {
                ...state,
                error: action.payload.data,
                enviando: false
            }
        case SUCCESS_SUBMIT:
            return {
                ...state,
                enviando: false,
                mensajeFormularioEnviado: action.payload.data
            }
        case UPDATE_ESTUDIOS:
            return {
                ...state,
                files: action.payload
            }
        default:
            throw new Error();
    }
}

const updateFormulario = (dispatch, promesa) => dispatch({type: UPDATE_FORMULARIO, payload: promesa});

const updateCompletos = (dispatch, promesa) => dispatch({type: UPDATE_COMPLETOS, payload: promesa});

const cargandoFormulario = dispatch => dispatch({type: CARGANDO_FORMULARIO, payload: true});

const onSubmit = dispatch => dispatch({type: ON_SUBMIT});

const errorSubmit = (dispatch, error) => dispatch({type: ERROR_SUBMIT, payload: error});

const successSubmit = (dispatch, response) => dispatch({type: SUCCESS_SUBMIT, payload: response});

const updateEstudios = (dispatch, estudios) => dispatch({type: UPDATE_ESTUDIOS, payload: estudios});

export {
    updateFormulario,
    updateCompletos,
    cargandoFormulario,
    onSubmit,
    errorSubmit,
    successSubmit,
    updateEstudios,
    reducerFormulario,
    initialState
}
