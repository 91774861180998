import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function usePrestaciones(centro, servicio, medico){
    const [prestaciones, setPrestaciones] = useState([]);
    const [cargandoPrestaciones, setCargandoPrestaciones] = useState();
    const [errorPrestaciones, setErrorPrestaciones] = useState();

    const handleResponse = response =>{
        if(Array.isArray(response.data)){
            setPrestaciones(response.data);
        }

        setCargandoPrestaciones(false);
    }


    useEffect(() =>  {
        setCargandoPrestaciones(true);
        apiGet(`api/turnos/PrestacionesPorServicio/${centro}/${servicio}/${medico}`)
                .then(handleResponse)
                .catch(error => {
                    setErrorPrestaciones("Error inesperado.");
                    setCargandoPrestaciones(false);
                })
    },[centro, servicio, medico]);

    return {cargandoPrestaciones, prestaciones,errorPrestaciones};

}
