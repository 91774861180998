import { useEffect, useState } from "react";
import ValidadorTelefono from 'utils/ValidadorTelefono';

export default function useValidarTelefono(id){
    const [error, setError] = useState();
    useEffect(() => {
        if(id){
            new ValidadorTelefono({
                id,

                onInvalid: (inputTelefonoValue, validadorTelefono) => {
                    const mensaje = `El teléfono "${inputTelefonoValue}" es inválido. No use 0 ni 15. Ejemplo correcto: 3764123456.`;
                    setError(mensaje);
                },

                onReset: (inputTelefonoValue, validadorTelefono) => {
                    setError(null);
                }
            });
        }
    }, [id]);
    return { error };
}
