import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useRestriccionesMedicoEdad(){
    const [restriccionesPorEdad, setRestriccionesPorEdad] = useState([]);
    const [cargandoRestriccionesPorEdad, setCargandoRestriccionesPorEdad] = useState();
    const [errorRestriccionesPorEdad, setErrorRestriccionesPorEdad] = useState();

    const handleResponse = response =>{
        setRestriccionesPorEdad(response.data);
        setCargandoRestriccionesPorEdad(false);
    }

    useEffect(() =>  {
            setCargandoRestriccionesPorEdad(true);
            apiGet(`api/getRestriccionesMedicosEdades`)
                .then(handleResponse)
                .catch(errorRestriccionesPorEdad => {
                    setErrorRestriccionesPorEdad("Error inesperado.");
                    setCargandoRestriccionesPorEdad(false);
                })
    },[]);

    return {cargandoRestriccionesPorEdad, restriccionesPorEdad, errorRestriccionesPorEdad};
}
