import { apiPost, getCsrfCookie, reduceErrorMessages } from "api/configApi";
import {useCallback, useState } from "react";

export default function useRegistroMontoPrestacion(){
    const [medico, setMedico] = useState('');
    const [nombreMedico,setNombreMedico]=useState('');
    const [servicio, setServicio] = useState('');
    const [nombreServicio,setNombreServicio] = useState('');
    const [prestacion, setPrestacion] = useState('');
    const [nombrePrestacion,setNombrePrestacion] = useState('');
    const [monto, setMonto] = useState('');
    const [fecha, setFecha] = useState('');

    const [registrando,setRegistrando] = useState(null);
    const [errors,setErrors]= useState(null);
    const [noRegistrando, setNoRegistrando] = useState(true);

    const data = {medico,servicio,prestacion,monto,fecha,nombreMedico,nombreServicio,nombrePrestacion};


    const handleRegistro = () =>{
        console.log(data);
       apiPost('api/guardarMontoPrestacion',data);
    }

    const handleRegistroResponse = response =>{
        setRegistrando(false);
        setNoRegistrando(false);
        setMedico('');
        setServicio('');
        setPrestacion('');
        setMonto('');
        setFecha('');

        setTimeout(() => {
            window.location.reload(false);
        }, 100);

    }

    const handleErros = error => {
        setRegistrando(false);
        setErrors(reduceErrorMessages(error));
    }

    const handleOnChangeMedico = useCallback(evt =>{
        setErrors(null);
        let index = evt.nativeEvent.target.selectedIndex;
        let label = evt.nativeEvent.target[index].text;
        setMedico(evt.target.value);
        setNombreMedico(label);
    },[setMedico,setNombreMedico]);

    const handleOnChangeServicio = useCallback(evt =>{
        setErrors(null);
        let index = evt.nativeEvent.target.selectedIndex;
        let label = evt.nativeEvent.target[index].text;
        setServicio(evt.target.value);
        setNombreServicio(label);
    },[setServicio,setNombreServicio]);

    const handleOnChangePrestacion = useCallback(evt =>{
        setErrors(null);
        let index = evt.nativeEvent.target.selectedIndex;
        let label = evt.nativeEvent.target[index].text;
        setPrestacion(evt.target.value);
        setNombrePrestacion(label);
    },[setPrestacion,setNombrePrestacion]);

    const handleOnchangeMonto = useCallback(evt=>{
        setErrors(null);
        setMonto(evt.target.value);
    },[setMonto]);

    const handleOnChangeFecha = useCallback(evt=>{
        setErrors(null);
        setFecha(evt.target.value);
    },[setFecha]);


    const onSubmit = evt =>{
        evt.preventDefault();
        setRegistrando(true);
        getCsrfCookie()
                .then(handleRegistro)
                .then(handleRegistroResponse)
                .catch(handleErros);
    }

    return{
        registrando,
        errors,
        state:{
            medico,
            servicio,
            prestacion,
            monto,
            fecha
        },
        controller:{
            handleOnChangeMedico,
            handleOnChangeServicio,
            handleOnChangePrestacion,
            handleOnchangeMonto,
            handleOnChangeFecha,
            onSubmit
        },
        noRegistrando
    }
}
