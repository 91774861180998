import Combo from 'components/Combo';
import React from 'react';

export default function ComboMedicos({
    medicos,
    cargandoMedicos,
    handleMedicoChange,
    medico,
    paddingClass = 'px-2'
}){
    return (
        <Combo
            opciones={medicos}
            label="Seleccionar profesional"
            opcionValue="nombreApellido"
            defaultValue={medico}
            cargando={cargandoMedicos}
            onChange={handleMedicoChange}
            firstActive = {true}
            paddingClass= {paddingClass}
        />
    );
}
