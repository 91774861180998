import React, { useEffect, useRef } from 'react';
import LayoutConMenuLateral from 'layouts/LayoutConMenuLateral';

export default function SolicitarTurnoPage({params}){
    const form = useRef()
    const url = "https://sanatorioboratti.com.ar/turnosprueba/loginexterno.php"

    const style = {
        border: 0,
        overflow: 'hidden',
        height: '100%',
        width: '100%',
      };

    useEffect(() =>  {
        form.current.submit()
    },[]);

    return (
        <LayoutConMenuLateral>
            <form ref={form} action={url} target="my-iframe" method="post">
                <input type="hidden" name="ExtIdMutual" value={params.mutual}/>
                <input type="hidden" name="ExtIdCentroAtencion" value={params.centro}/>
                <input type="hidden" name="ExtIdServicio" value={params.servicio}/>
                <input type="hidden" name="ExtIdPrestacion" value={params.prestacion}/>
                <input type="hidden" name="ExtIdPersona" value={params.idPersona}/>
                <input type="hidden" name="ExtPagina" value="solicitarturno.php"/>
            </form>

            <iframe
                title="sanatorioBorati"
                id="sanatorioBorati"
                name="my-iframe"
                src={url}
                style={style}>
            </iframe>
        </LayoutConMenuLateral>
    );
}
