import FormGroup from "components/FormGroup";
import InputTelefono from "components/InputTelefono";
import MensajeExito from "components/MensajeExito";
import SectionWithTitle from "components/SectionWithTitle";
import SkeletonForm from "components/SkeletonForm";
import useReporteError from "hooks/useReporteError";
import LayoutConMenuLateral from "layouts/LayoutConMenuLateral";
import TemaContext from "providers/Tema";
import React, { useContext } from 'react';
import { ThemeProvider } from "styled-components";
import Boton from "styledComponents/Boton.style";

export default function ReporteErrorForm() {
    const theme = useContext(TemaContext);
    const {state,controller,errors,reportando,noReportado} = useReporteError();
    return (
       <LayoutConMenuLateral>
           <SectionWithTitle title={"Reportar un Problema"}>
                <SkeletonForm
                    invalid={errors}
                    validando={reportando}
                    handleSubmit={controller.onSubmit}
                    invalidMessage={errors}
                    validandoMessage ="Reportando"
                    render={() => {
                        if(noReportado){
                            return (<>
                                <FormGroup
                                    label="D.N.I."
                                    type="number"
                                    placeholder="Ingrese su dni"
                                    name="dni"
                                    required
                                    value={state.dni}
                                    onChange={controller.handleOnChangeDni}
                                    onBlur={controller.onBlurValidarProvisorio}
                                />
                                <InputTelefono
                                    onChange={controller.handleOnChangeTelefono}
                                    placeholder="Ingrese su teléfono"
                                    value={state.telefono}
                                    required={true}
                                    id="input-registro-telefono">
                                </InputTelefono>
                                <FormGroup
                                    label="Asunto"
                                    type="text"
                                    placeholder="Ingrese el Asunto"
                                    name="asunto"
                                    required
                                    value={state.asunto}
                                    onChange={controller.handleOnChangeAsunto}
                                    maxlength="50">
                                </FormGroup>
                                <FormGroup
                                    label="Descripción "
                                    type="text"
                                    placeholder="Ingrese la Descripción"
                                    name="asunto"
                                    required
                                    value={state.descripcion}
                                    onChange={controller.handleOnChangeDescripcion}>
                                </FormGroup>
                                <ThemeProvider theme={theme}>
                                    <Boton className="btn w-100 mt-2">
                                        Reportar
                                    </Boton>
                                </ThemeProvider>
                            </>);
                        }
                        return <MensajeExito mensaje="Reporte enviado exitosamente"/>;
                    }}
                />
           </SectionWithTitle>
       </LayoutConMenuLateral>
    );
}
