import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useValidarRestriccionMedicoMutual(obraSocial,medico,fecha){
    const [validacionMedicoRestriccionMutual, setValidacionMedico] = useState([]);
    const [cargandoValidacionMedicoRestriccionMutual, setCargandoValidacionMedico] = useState(false);
    const [errorValidacionMedicoRestriccionMutual, setErrorValidacionMedico] = useState();

    const handleResponse = response =>{
        setValidacionMedico(response.data);
        setCargandoValidacionMedico(false);
    }

    useEffect(() =>  {
        if(medico && medico !== 0){
            setCargandoValidacionMedico(true);
            apiGet(`api/ValidarRestriccionMedico/${obraSocial}/${medico}/${fecha}`)
                .then(handleResponse)
                .catch(error => {
                    setErrorValidacionMedico("Error inesperado.");
                    setCargandoValidacionMedico(false);
                })
        }
    },[obraSocial,medico]);
    
    return {cargandoValidacionMedicoRestriccionMutual, validacionMedicoRestriccionMutual, errorValidacionMedicoRestriccionMutual};
}
