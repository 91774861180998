import React, {useState} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { useHistorialEstadistica } from 'hooks/estadistica/useEstadistica';
import IfRender from "components/IfRender";
import Cargando from "components/Cargando";

export default function TablaHistorialEstadistica({fechaDesde, fechaHasta, accion}) {

    const { cargando, posts } = useHistorialEstadistica( fechaDesde, fechaHasta, accion);

    function formatearFecha(fecha){
        return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
    }

    const [filters, setFilters] = useState({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const renderHeader = () => {
        return (
            <>
                <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText 
                            onInput={(e) => setFilters({
                                global: {value:e.target.value, matchMode: FilterMatchMode.CONTAINS},
                            })}
                            placeholder='Buscar'
                        />
                </span>
            </>
        )
    }
    const header = renderHeader();

    return( 
        <IfRender condicion={!cargando}
        no={() => <Cargando text="Cargando historial.." />}
        >
            <div className="mx-2">
                <h4 className='text-muted'>Historial</h4>
                <small>Los siguientes resultados corresponden a : {formatearFecha(fechaDesde)} - {formatearFecha(fechaHasta)}</small>
            </div>
            <DataTable value={posts} paginator filters={filters} responsiveLayout="scroll" size="small"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Resultado {first} - {last} de {totalRecords}"
                rows={10} header={header}
                emptyMessage="No se encontraron registros.">
                <Column field="created_at" header="Fecha" sortable></Column>
                <Column field="paciente" header="Usuario" sortable></Column>
                <Column field="dni_usuario" header="DNI" sortable></Column>
            </DataTable>
        </IfRender>
);
}