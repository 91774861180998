import Combo from 'components/Combo';
import React from 'react';

export default function ComboPrestaciones({
    prestaciones,
    cargandoPrestaciones,
    handlePrestacionChange,
    prestacion
}){

    return (
        <Combo
            opciones={prestaciones}
            label="Prestación"
            opcionValue="nombre"
            defaultValue={prestacion}
            cargando={cargandoPrestaciones}
            onChange={handlePrestacionChange}
        />
    );
}
