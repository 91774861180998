import { apiPost } from "api/configApi";
import { useEffect, useState } from "react";

export default function useResetPassword( user ,onChange ){

    const [ errors, setErrors ] = useState();
    const [ cargando, setCargando ] = useState(false);
    const [ message, setMessage ] = useState();

    const data = {
        'email': user.email
    };

    const handleResponse = response => {
        setCargando(false);
        setMessage(response.data);
    }

    useEffect(() => {
        if ( onChange ) {
            setCargando(true);
            apiPost(`api/resetPasswordEmail`,data).then(handleResponse).catch(setErrors);
        }
    }, [onChange]);

    return { cargando, message , errors };

}