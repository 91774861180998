import React from 'react';
import { cardStyle, headerStyle, imgStyle } from './CardPerfil.style';
import { ReactComponent as IcoUser}  from 'icons/user.svg';
import { Card } from 'react-bootstrap';
import IfRender from 'components/IfRender';

export default function CardPerfil({datos, showFoto = false}){
    const nombre = `${datos.nombre} ${datos.apellido}`;
    return (
        <Card style={cardStyle} className="shadow text-center mb-2">
            <IfRender condicion={showFoto}>
                <Card.Header style={headerStyle}>
                    <IcoUser style={imgStyle} className="rounded-circle shadow" width="250"/>
                </Card.Header>
            </IfRender>
            <Card.Body>
                <Card.Title>{ nombre }</Card.Title>
            </Card.Body>
        </Card>
    );
}
