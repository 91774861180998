import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useTraerTurnoInterno(id_turno){
    const [turnoInterno, setTurno] = useState([]);
    const [cargandoTurno, setCargandoTurno] = useState();
    const [errorTruno, setErrorTurno] = useState();
    const handleResponse = response =>{
        setTurno(response.data);
        setCargandoTurno(false);
    }

    useEffect(() =>  {
        setCargandoTurno(true);

        apiGet(`api/turnos/traerTurno/${id_turno}`)
            .then(handleResponse)
            .catch(error => {
                setErrorTurno("Error inesperado.");
                setCargandoTurno(false);
            })
    },[id_turno]);


    return {cargandoTurno, turnoInterno, errorTruno};

}
