import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useTraerMontoPrestacion(medico,servicio,prestacion,dni,turno,mutual,isConsalud,coseguro){
    const [monto, setMonto] = useState([]);
    const [cargando, setCargando] = useState();
    const [error, setError] = useState();

    const handleResponse = response =>{
        setMonto(response.data);
        setCargando(false);
    }

    useEffect(() =>  {
        setCargando(true);
        apiGet(`api/getMontoPrestacion?medico=${medico}&servicio=${servicio}&prestacion=${prestacion}&turno=${turno}&obraSocial=${mutual}&isConsalud=${isConsalud}&coseguro=${coseguro}`)
            .then(handleResponse)
            .catch(error => {
                setError("Error inesperado.");
                setCargando(false);
            })
    },[medico,servicio,prestacion,dni,turno]);


    return {cargando, monto, error};
}
