import React from 'react';
import LayoutPrincipal from 'layouts/LayoutPrincipal';
import LoginLayout from 'layouts/LoginLayout';
import LoginForm from 'components/login/LoginForm';

export default function LoginHpPage() {
    return (
        <LayoutPrincipal>
            <LoginLayout title="Iniciar sesión">
                <LoginForm isInterno={true}/>
            </LoginLayout>
        </LayoutPrincipal>
    );
}
