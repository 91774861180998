import IfRender from 'components/IfRender';
import React from 'react';

const ImagenDni = ({imagen, referencia, safeLabel}) => (
    <div className="rounded mb-2 border d-flex justify-content-center align-items-center"
        style={{ height: '50vh' }}>
        <IfRender condicion={imagen} no={() => <span>{safeLabel}</span> }>
            <img ref={referencia} alt={`foto ${safeLabel}`}/>
        </IfRender>
    </div>
);

export default ImagenDni;
