import React, { useContext, useRef, useState } from "react";
import LayoutConMenuLateral from "layouts/LayoutConMenuLateral";
import SectionWithTitle from "components/SectionWithTitle";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import {Dialog} from 'primereact/dialog';
import useEliminarRestriccionMedicoPrestacion from "hooks/configuracion/RestriccionMedicoPrestacion/useEliminarRestriccionMedicoPrestacion";
import { Toolbar } from 'primereact/toolbar';
import { Modal, Row } from 'react-bootstrap';
import useTraerRestriccionesMedicoPrestacion from "hooks/configuracion/RestriccionMedicoPrestacion/useTraerRestriccionesMedicoPrestacion";
import RegistroRestriccionMedicoPrestacion from "components/configuracion/RegistroRestriccionMedicoPrestacion";
import IfRender from "components/IfRender";
import Alert from "components/Alert";

export default function PageConfiguracionMedicoPrestacion(){
    const {cargandoRestriccionesPrestaciones, restriccionesPrestaciones, errorRestriccionesPrestaciones} = useTraerRestriccionesMedicoPrestacion();
    const [restriccion,setRestriccion] = useState([]);
    const [deleteRestriccionDialog, setDeleteRestriccionDialog] = useState(false);
    const [mensajeAnular, setMensajeEliminar] = useState();
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false)
    };
    const handleOnErrorEliminar = (mensaje) => {
        setMensajeEliminar(mensaje);
    }
    const eliminar = useEliminarRestriccionMedicoPrestacion({restriccion,setMensajeEliminar,handleOnErrorEliminar});

    const columns = [
        {field:'dniMedico',header:'DNI'},
        {field:'nombre',header:'Nombre'},
        {field:'matricula',header:'Matrícula'},
        {field:'prestacion',header:'Prestación Restringida'},
        {field:'accion',header:'Accion'}];

    const [filters,setFilters]= useState({
        global:{value:null, matchMode: FilterMatchMode.CONTAINS},
    })


    const hideDeleteRestriccionDialog = () => {
        setDeleteRestriccionDialog(false);
    }

    const confirmDeleteRestriccion = (rest) => {
        setRestriccion(rest);
        setDeleteRestriccionDialog(true);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDeleteRestriccion(rowData)} />
            </React.Fragment>
        );
    }

    const dynamicColumns = columns.map((col,i) => {
            switch(col.header){
                case'Accion':{
                    return <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
                };
                default:return <Column key={col.field} field={col.field} header={col.header} sortable/>;
            }
        });


    const deleteRestriccionDialogFooter = (
            <React.Fragment>
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteRestriccionDialog} />
                <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={eliminar} />
            </React.Fragment>
        );

    const paginatorLeft = <Button type="button" className="p-button-text" />;
    const paginatorRight = <Button type="button" className="p-button-text" />;

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <InputText
                    placeholder="Buscar..."
                    onInput={(e)=>
                        setFilters({
                            global:{value: e.target.value,matchMode: FilterMatchMode.CONTAINS},
                        })
                    }
                    className="p-inputtext-sm"
                />
            </React.Fragment>
        )
    }
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo Registro" icon="pi pi-plus" className="p-button-info p-button-sm p-button-success" onClick={handleShow} />
            </React.Fragment>
        )
    }
    return(
        <LayoutConMenuLateral>
            <SectionWithTitle title="Configuracion de Prestaciones restringidas para Médicos">
                <IfRender condicion={!cargandoRestriccionesPrestaciones} no={()=>
                    <Alert>Espere un momento...</Alert>
                }>
                
                            <div className="card">
                                <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                                <DataTable value={restriccionesPrestaciones} paginator size="small"  sortMode="multiple" responsiveLayout="stack" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Mostrando {first} de {last}. Total: {totalRecords}" rows={10} rowsPerPageOptions={[10,20,50]} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} filters={filters} dataKey="id" emptyMessage="Sin resultados">
                                    {dynamicColumns}
                                </DataTable>
                            </div>
                            <div>
                                <Dialog visible={deleteRestriccionDialog} style={{ width: '450px' }} header="Eliminar Restriccion" modal footer={deleteRestriccionDialogFooter} onHide={hideDeleteRestriccionDialog}>
                                    <div className="confirmation-content">
                                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                                        {restriccion && <span>Estas seguro/a que quieres eliminar esta restriccion <b>{restriccion.nombre}</b>?</span>}
                                    </div>
                                </Dialog>
                                <Modal show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Registrar restriccion</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Row>
                                            <RegistroRestriccionMedicoPrestacion/>
                                        </Row>
                                    </Modal.Body>
                                </Modal>
                            </div>
                </IfRender>
            </SectionWithTitle>
        </LayoutConMenuLateral>
    );
}
