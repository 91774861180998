import React from 'react';
import Datalist from 'components/Datalist';

export default function DatalistMutuales({
    mutuales,
    cargandoMutuales,
    handleMutualChange,
    mutual
}){
    return (
        <Datalist
            opciones={mutuales}
            label="Obra Social"
            opcionValue="denominacion"
            defaultValue={mutual}
            cargando={cargandoMutuales}
            onChange={handleMutualChange}
        />
    );
}
