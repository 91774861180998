import React, { useContext } from 'react';
import Boton from 'styledComponents/Boton.style';
import { ThemeProvider } from 'styled-components';
import FormGroup from 'components/FormGroup';
import TemaContext from 'providers/Tema';
import SkeletonForm from 'components/SkeletonForm';
import useEditarRestriccionProfesionalTurnoOnline from 'hooks/configuracion/RestriccionMedicoTurnoOnline/useEditarRestriccionProfesionalTurnoOnline';
import 'primeicons/primeicons.css';
import Combo from 'components/Combo';


export default function ModalEditarRestriccionTurnoOnline({ restriccion }){
    const theme = useContext(TemaContext);
    const { errors, registrando, state, controller } = useEditarRestriccionProfesionalTurnoOnline({ restriccion });

    const atiende = () => {
        if(restriccion.edadMaxima == null && restriccion.edadMinima == null){
            return 'TODO';
        }else{
          return (parseInt(restriccion.edadMinima) == 14 ? 'ADULTO' : parseInt(restriccion.edadMaxima) == 14 ? 'PEDIATRICOS' : 'RANGO' );
        }
    }

    return (
        <SkeletonForm
            invalid={errors}
            validando={registrando}
            handleSubmit={controller.onSubmit}
            invalidMessage={errors}
            validandoMessage ="Registrando"
            render={() => (
            <>
                <p className='text-uppercase'>Profesional: {restriccion.nombre}</p>
                <p className='text-uppercase'>DNI: {restriccion.dni}</p>
                <p className='text-uppercase'>Matrícula: {restriccion.matricula}</p>
                <p className='text-uppercase'>Atiende: {atiende()}</p>
                <FormGroup
                    label="Teléfono a mostrar"
                    type="number"
                    placeholder="Ingrese el numero de teléfono"
                    name="telefono"
                    onChange={controller.handleOnChangeTelefono}
                    value={state.telefono}>
                </FormGroup>
                <ThemeProvider theme={theme}>
                    <Boton className="btn btn-primary w-100 mt-2">
                        Actualizar
                    </Boton>
                </ThemeProvider>
            </>
            )}
        />
    );
}