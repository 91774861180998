import LayoutPrincipal from 'layouts/LayoutPrincipal';
import React from 'react';

export default function ErrorRoute({mensaje}){
    return (
        <LayoutPrincipal>
            <div className="d-flex w-100 justify-content-center align-items-center h-100 text-white">
                <h1>{mensaje}</h1>
            </div>
        </LayoutPrincipal>
    );
}
