import { apiGet } from 'api/configApi';
import useLogout from 'hooks/useLogout';
import React, { useEffect, useReducer, useState } from 'react';
import { useCallback } from 'react';
import url from 'utils/url';
import { useLocation } from 'wouter';

const UserContext = React.createContext();
const { Provider, Consumer } = UserContext;

export default function UserProvider({children}){
    const setLocation = useLocation()[1];
    const [user, setUser] = useState();
    const [iniciando, setIniciando] = useState(true);
    const [unauthenticated, setUnauthenticated] = useState(false);
    const { logout } = useLogout();

    const addUser = useCallback(user => {
        setUser(user);
        setIniciando(false);
    }, [setUser]);

    const destoyUser = useCallback(() => {
        logout(() => {
            setUser(null);
        });
    }, [setUser, logout]);

    const handeResponseUser = useCallback(response => {
        addUser(response.data);
        setIniciando(false)
    }, [addUser, setIniciando]);

    const handleRequestErrors = useCallback(error => {
        switch(error.response.status){
            case 500:
                alert('Se a producido un error al recuperar su información. Vuelva a iniciar sesión');
                setLocation(url());
            break;
            case 401:
                console.log('401: usuario no autorizado');
                setUnauthenticated(true);
                setIniciando(false);
                dispatch();
            break;
            default:
                console.log(error);
        }
    }, [setLocation]);

    useEffect(() => {
        apiGet('api/user')
            .then(handeResponseUser)
            .catch(handleRequestErrors);
    }, [handeResponseUser, handleRequestErrors]);

    const [userAccessor, dispatch] = useReducer(() => ({
        user, addUser, logout: destoyUser, iniciando,
        unauthenticated
    }), {user: null, addUser: null, logout: null, iniciando, unauthenticated});

    useEffect(dispatch, [user]);

    return (
        <Provider value={userAccessor}>
            {children}
        </Provider>
    );
}

const hasRoles = (user, targetRoles = []) => {
    let hasRol = false;
    let rolesLen = targetRoles.length;
    let i = 0;
    const userRoles = user ? (user.roles || []) : [];
    while(!hasRol && i <= rolesLen){
        const rolActual = targetRoles[i];
        hasRol = userRoles.some(rol => rol.id === rolActual);
        i++;
    }
    return hasRol;
}

export { UserContext, Consumer as UserConsumer, hasRoles }

