import { apiPost } from "api/configApi";

export default function useAnularFormulario({formulario, handleDelete, setMensajeAnular, handleOnErrorEliminar}){
    const formularioBackUp = formulario;

    const eliminar = () => {
        handleDelete(formulario.id, 'Turno anulado exitosamente')
        apiPost(`api/formulario/anularFormulario/${formulario.id}`)
            .then((response) => setMensajeAnular(response.data.mensaje))
            .catch(error => handleOnErrorEliminar(formularioBackUp, error.response.data.mensaje))
    };

    return eliminar;
}
