import React, { useContext } from 'react';
import LayoutPrincipal from 'layouts/LayoutPrincipal';
import Cargando from 'components/Cargando';
import { Redirect } from 'wouter';
import url from 'utils/url';
import { UserContext } from 'providers/UserProvider';

export default function VerifiedPage(){
    const {user} = useContext(UserContext);
    if(user && user.email_verified_at){
        return <Redirect to={url(user.default_url_home)}/>;
    }
    return (
        <LayoutPrincipal>
            <section className="bg-white m-4 p-4 rounded">
                <Cargando text="Verificando cuenta"/>
            </section>
        </LayoutPrincipal>
    );
}
