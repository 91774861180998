import React from 'react';
import LayoutPrincipal from 'layouts/LayoutPrincipal';
import ResetPasswordForm from 'components/login/ResetPasswordForm';
import LoginLayout from 'layouts/LoginLayout';
import { getParamFromUrl } from 'utils/url';

export default function ResetPasswordPage({params}){
    return (
        <LayoutPrincipal>
            <LoginLayout
                title="Agregue una nueva contraseña"
            >
                Ingrese su nueva contraseña
                <ResetPasswordForm
                    token={params.token}
                    email={getParamFromUrl('email')}
                />
            </LoginLayout>
        </LayoutPrincipal>
    );
}
