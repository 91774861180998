import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useTraerPrestacionCobrable(id_prestacion, id_centro){
    const [prestacion, setPrestacion] = useState([]);
    const [cargandoPrestacion, setCargandoPrestacion] = useState(false);
    const [errorPrestacion, setErrorPrestacion] = useState(false);
    const handleResponse = response =>{
        setPrestacion(response.data);
        setCargandoPrestacion(false);
    }

    useEffect(() =>  {
        if(id_prestacion && id_centro){
            setCargandoPrestacion(true);
            apiGet(`api/pagos/TraerPrestacionCobrable/${id_prestacion}/${id_centro}`)
                .then(handleResponse)
                .catch(error => {
                    setErrorPrestacion("Error inesperado.");
                    setCargandoPrestacion(false);
                })
        }
    },[id_prestacion, id_centro]);


    return {cargandoPrestacion, prestacion, errorPrestacion};

}
