import React from 'react';

const IfRender = ({children, condicion, no = null}) => (
    <>
        {
            condicion ? children : no && no()
        }
    </>
);

export default IfRender;
