import React, { useCallback, useContext, useState } from 'react';
import Boton from 'styledComponents/Boton.style';
import { ThemeProvider } from 'styled-components';
import FormGroup from 'components/FormGroup';
import TemaContext from 'providers/Tema';
import SkeletonForm from 'components/SkeletonForm';
import ComboCentroDeAtencion from 'components/turnos/ComboCentroDeAtencion';
import DatalistMutuales from './DatalistMutuales';
import FormCheck from 'components/FormCheck';
import useRegistroMedicoPrestacion from 'hooks/configuracion/RestriccionMedicoPrestacion/useRegistroMedicoPrestacion';
import ComboServiciosPorCentro from 'components/turnos/ComboServiciosPorCentro';
import useServiciosPorCentro from 'hooks/turnos/useServiciosPorCentro';
import useMedicosPorServicio from 'hooks/turnos/useMedicos';
import ComboMedicos from 'components/turnos/ComboMedicos';
import ComboPrestaciones from 'components/turnos/ComboPrestaciones';
import usePrestaciones from 'hooks/turnos/usePrestaciones';

export default function RegistroRestriccionMedicoPrestacion() {
    const {errors, registrando, state, controller} = useRegistroMedicoPrestacion();
    const {cargandoServicios, serviciosPorCentro} = useServiciosPorCentro(1);
    const [servicio,setServicio] = useState();
    const {medicos,cargandoMedicos,errorMedicos} = useMedicosPorServicio(servicio);
    const {cargandoPrestaciones, prestaciones} = usePrestaciones(1,servicio,state.dni);
    const theme = useContext(TemaContext);

    const handleOnChangeServicio = useCallback(evt =>{
        setServicio(evt.target.value);
    },[setServicio]);

    return (
        <SkeletonForm
            invalid={errors}
            validando={registrando}
            handleSubmit={controller.onSubmit}
            invalidMessage={errors}
            validandoMessage ="Registrando"
            render={() => (
            <>
                <ComboServiciosPorCentro
                    serviciosPorCentro={serviciosPorCentro}
                    handleServicioChange={handleOnChangeServicio}
                    cargandoServicios={cargandoServicios}
                    servicio={servicio}
                />

                <ComboMedicos
                    medicos={medicos}
                    handleMedicoChange={controller.handleOnChangeMedico}
                    cargandoMedicos={cargandoMedicos}
                    medico={state.dni}
                />
                <ComboPrestaciones
                    prestaciones={prestaciones}
                    cargandoPrestaciones={cargandoPrestaciones}
                    handlePrestacionChange={controller.handleOnChangePrestacion}
                    prestacion={state.idPrestacion}
                />
                <ThemeProvider theme={theme}>
                    <Boton className="btn btn-primary w-100 mt-2">
                        Guardar
                    </Boton>
                </ThemeProvider>
            </>
            )}
        />
    );
}
