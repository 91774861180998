import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const TurnosExcedidosModal = ({ mostrarModal, handleClose }) => {
  return (
    <Modal show={mostrarModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>❌ Límite de turnos alcanzado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Se ha alcanzado el límite de turnos otorgados. No es posible
          solicitar más turnos en este momento, ya que el límite permitido es de
          3.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TurnosExcedidosModal;
