import React, { useContext, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import Boton from 'styledComponents/Boton.style';
import TemaContext from 'providers/Tema';
import useBuscarNombrePersona from 'hooks/useBuscarNombrePersona';
import useEliminarGrupo from 'hooks/gestionFamiliares/useEliminarGrupo';
import ConfirmDialog from 'components/ConfirmDialog';
import useValidarEdad from 'hooks/gestionFamiliares/useValidarEdad';
import IfRender from 'components/IfRender';

const GrupoFamiliarItem = ({
    children,
    familiar,
    onDelete,
    onError,
    dniProp,
    cargandoNombreLabel,
    labelBtnEliminar,
    preguntaAccionEliminar
}) => {
    const tema = useContext(TemaContext);

    const { nombre, cargandoNombre } = useBuscarNombrePersona(familiar[dniProp]);

    const eliminar = useEliminarGrupo({
        familiar,
        onDelete,
        onError
    });

    const {
        pac
    } = useValidarEdad({familiar})

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleCancelar = () => setShow(false);

    return (
        <div className="d-flex justify-content-lg-between p-2 m-2 border rounded">
            <div className="d-flex align-items-center">
                <span>{ cargandoNombre ? cargandoNombreLabel : nombre }</span>
            </div>
            <IfRender condicion={pac >= 18}>
            <div className="d-flex justify-content-end">
                {children}
                <ConfirmDialog
                    abrir={show}
                    titulo="Grupo familiar"
                    mensaje={preguntaAccionEliminar}
                    onAceptar={eliminar}
                    onCancelar={handleCancelar}
                    onHide={handleCancelar}
                />
                <ThemeProvider theme={tema}>
                    <Boton className="btn" secondary onClick={handleShow}>
                    { labelBtnEliminar }
                    </Boton>
                </ThemeProvider>
            </div>
           </IfRender>
           <IfRender condicion={pac < 18}>
            <div className="d-flex justify-content-end">
                <button className='btn btn-danger' data-toggle="tooltip" data-placement="top" title="Tienes que ser mayor de edad para abandonar el grupo familiar" disabled> { labelBtnEliminar }</button>
            </div>
           </IfRender>
        </div>
    );
}

export default GrupoFamiliarItem;

