import { apiGet } from "api/configApi";
import { useEffect, useState } from "react";

export default function useFamiliares(pacienteId){
    const [familiares, setFamiliares] = useState([]);
    const [cargandoFamiliares, setCargandoFamiliares] = useState();
    const [errorFamiliares, setErrorFamiliares] = useState();
    const handleResponse = response =>{
        setFamiliares(response.data);
        setCargandoFamiliares(false);
    }

    useEffect(() =>  {
        setCargandoFamiliares(true);
        if(pacienteId){
        apiGet(`api/familiaresConDatos/${pacienteId}`)
            .then(handleResponse)
            .catch(error => {
                setErrorFamiliares("Error inesperado.");
                setCargandoFamiliares(false);
            })
        }

    },[pacienteId]);


    return {cargandoFamiliares, familiares, errorFamiliares};

}
