import { apiPost } from "api/configApi";
import { useCallback } from "react";
import { useEffect, useState } from "react";

export default function useValidarUsuarioProvisorio({
    dni, contraseñaProvisoria, onValidate, onError
}){

    const [validando, setValidando] = useState(false);

    const handleRespuesta = useCallback(respuesta => {
        onValidate(respuesta.data)
        setValidando(false);
        eliminarParametosUrl();
    }, [onValidate, setValidando]);

    const handleErrors = useCallback(errors => {
        setValidando(false);
        eliminarParametosUrl();
        onError(errors.response.data);
    }, [setValidando, onError]);

    const validarUsuarioProvisorio = useCallback((_dni, _contraseñaProvisoria) => {
        apiPost('api/validarUsuarioProvisorio', {
            dni: _dni, contraseñaProvisoria: _contraseñaProvisoria
        }).then(handleRespuesta)
          .catch(handleErrors);
    }, [handleRespuesta, handleErrors]);

    useEffect(() => {
        if(dni && contraseñaProvisoria){
            setValidando(true);
            validarUsuarioProvisorio(dni, contraseñaProvisoria);
        }
        else{
            setValidando(false);
        }

    }, [dni, contraseñaProvisoria, validarUsuarioProvisorio]);

    return { validando, validarUsuarioProvisorio, setValidando};
}

function eliminarParametosUrl(){
    window.history.replaceState({}, document.title, "/login-provisorio");
}
