import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import Boton from 'styledComponents/Boton.style';
import { Modal } from 'react-bootstrap';
import TemaContext from 'providers/Tema';


const PreparacionDialog = ({
    titulo,
    mensaje,
    abrir,
    // onCancelar = () => {},
    onAceptar = () => {},
    onHide = () => {}
}) => {
    const tema = useContext(TemaContext);
    return (
        <Modal show={abrir}>
            <Modal.Header closeButton onHide={onHide}>
                {titulo}
            </Modal.Header>
            <Modal.Body>
                {mensaje}
            </Modal.Body>
            <Modal.Footer>
                {/* <ThemeProvider theme={tema}>
                    <Boton className="btn" secondary outline onClick={onCancelar}>
                        Cancelar
                    </Boton>
                </ThemeProvider> */}
                <ThemeProvider theme={tema}>
                    <Boton className="btn" onClick={onAceptar}>
                        Aceptar
                    </Boton>
                </ThemeProvider>
            </Modal.Footer>
        </Modal>
    );
}
export default PreparacionDialog;
