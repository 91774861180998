import Cargando from 'components/Cargando';
import FormGroup from 'components/FormGroup';
import InputTelefono from 'components/InputTelefono';
import SkeletonForm from 'components/SkeletonForm';
import useLoginProvisorio from 'hooks/useLoginProvisorio';
import TemaContext from 'providers/Tema';
import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import Boton from 'styledComponents/Boton.style';

export default function LoginProvisorioForm({dniInicial, contraseñaProvisoriaInicial}) {
    const theme = useContext(TemaContext);
    const { state, controller, errors, registrando, validando} = useLoginProvisorio({
        dniInicial, contraseñaProvisoriaInicial
    });

    if(validando){
        return <Cargando text="Validando usuario provisorio"/>;
    }

    return (
        <SkeletonForm
            invalid={errors}
            validando={registrando}
            handleSubmit={controller.onSubmit}
            invalidMessage={errors}
            validandoMessage ="Registrando"
            render={() => (
            <>
                <FormGroup
                    label="D.N.I."
                    type="number"
                    placeholder="Ingrese su dni"
                    name="dni"
                    required
                    value={state.dni}
                    onChange={controller.handleOnChangeDni}
                    onBlur={controller.onBlurValidarProvisorio}
                />

                <FormGroup
                    label="Código provisorio"
                    type="number"
                    placeholder="Ingrese su código provisorio"
                    name="contraseña_provisoria"
                    required
                    value={state.contraseñaProvisoria}
                    onChange={controller.handleOnChangeContraseñaProvisoria}
                    onBlur={controller.onBlurValidarProvisorio}
                />

                <FormGroup
                    label="Email"
                    type="email"
                    placeholder="Ingrese su email"
                    name="email"
                    required
                    onChange={controller.handleOnChangeEmail}
                    value={state.email}>
                </FormGroup>
                <InputTelefono
                    onChange={controller.handleOnChangeTelefono}
                    placeholder="Ingrese su teléfono"
                    value={state.telefono}
                    required={true}
                    id="input-registro-telefono">
                </InputTelefono>
                <FormGroup
                    label="Contraseña definitiva"
                    type="password"
                    placeholder="Ingrese su contraseña definitiva"
                    name="password"
                    required
                    onChange={controller.handleOnChangeContraseña}>
                </FormGroup>
                <FormGroup
                    label="Repetir contraseña definitiva"
                    type="password"
                    placeholder="Vuelva a ingresar su contraseña definitiva"
                    name="password_confirmation"
                    required
                    onChange={controller.handleOnChangeRepetirContraseña}>
                </FormGroup>
                <ThemeProvider theme={theme}>
                    <Boton className="btn w-100 mt-2">
                        Registrar
                    </Boton>
                </ThemeProvider>
            </>
            )}
        />
    );
}
